import React, { useState, useRef } from "react";

// import { inp, lbl } from "../styles/pages/employee.module.scss";
import axios from "../../service/api";
import Status from "../../utils/Stage";
import DataSubmittingPop from "./DataSubmitPopup";
// import * as styles from "../styles/pages/employee.module.scss";

const PopupEdit = (props) => {
    
  console.log('Check Popup Edit.......', props)
  const [isProcessing, setIsProcessing] = useState(false);
  const [indexOfEditElement, setIndexOfEditElement] = useState(0);
  const [checkedValue, setCheckedValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentRow, setCurrentRow] = useState(0);
  const valueInInputText = useRef(null);

  const setRowNext = (updatedData, updatedDataArr, callback) => {
    if (props.checkedRows.length - 1 !== currentRow) {
      setCheckedValue("");
      setIsProcessing(false);
      if (valueInInputText.current != null) {
        valueInInputText.current.value = "";
      }
      setCurrentRow(currentRow + 1);
    } else {
      props.setCheckedRows(updatedData);
      props.setData(updatedDataArr);
      // props.onCancel();
      props.setPopupState(false);
      setCurrentRow(0);

      // if (typeof callback === "function") {
      //   callback(updatedData);
      // }
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    // let dataCopy = [...props.data];
    let checkedRowsCopy = [];
    // setIsProcessing(true);
  
      (async () => {
        const newDataItem = {};
        props.inputs.forEach((inp) => {
          
          if (inp !== "Filestatus") {
            if (inp !== "_id")
              newDataItem[inp] = e.target[`inp${inp.toLowerCase()}`]?.value;
            else newDataItem["id"] = props.checkedRows[0]["_id"];
              // newDataItem["type"] = props.checkedRows[0]["Pubcode"];
          }
        });
         // Add the 'type' parameter to newDataItem
         newDataItem['type'] = props.checkedRows[0]['Pubcode'];
        try {
          const response = await axios.put(props.editUrl, newDataItem);
          console.log(newDataItem);
          if(props.editUrl.includes("/editFile")){
            const updatedDataFiles = [...props.data];
            const updatedCheckedRows = response.data.updatedFile;
            const updatedCheckedRowsArray = [updatedCheckedRows];
            const dataIndex = updatedDataFiles.findIndex((item) => item._id === updatedCheckedRows._id);
            if(dataIndex !== -1){
              updatedDataFiles[dataIndex] = updatedCheckedRows;
            }

          // if (typeof window !== `undefined`) {
          //   window.location.reload();
          setRowNext(updatedCheckedRowsArray, updatedDataFiles);
        } else {
            props.onConfirmed();
            props.handleClose();
        }
        } catch (err) {
          setErrorMessage(err.response.data.data.type);
          // props.setError();
          console.log(err);
          // setIsProcessing(false);
        }
      })();
  };

  return isProcessing ? (
    <DataSubmittingPop />
  ) : (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 z-20" onClick={()=>{
      
         
         props.setPopupState(false);
      }}></div>
      <form
        onSubmit={submitForm}
        className="w-4/5 fixed space-y-4 px-8 py-10 h-c-6 overflow-x-auto overflow-y-auto grid grid-cols-2 gap-x-12 bg-[#f8f1f2] top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-30 rounded-lg max-h-screen"
      >
        <h2 className="text-center col-span-full">
          Edit the following details:
        </h2>
        {props.inputs.map((input, i) => {
          if (input === "Filestatus") {
            return;
          }
          if (input === "Status") {
            return (
              <div key={i}>
                <label
                  htmlFor={`inp-${input}`}
                  className="block font-semibold mb-2"
                >
                  Enter {input}
                </label>

                <select
                  id="status"
                  className="shadow-lg rounded-xl p-4 block w-full"
                  defaultValue={
                    !props.isForAddNew ? props.checkedRows[0]?.[input] : ""
                  }
                >
                  {Status.statusArray.map((opt, i) => (
                    <option key={i + "-inner"} value={opt}>
                      {opt.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
            );
          }
          return (
            input !== "_id" &&
            input !== "Production" &&
            input !== "QA" &&
            input !== "Final" && (
              <div key={i}>
                <label
                  htmlFor={`inp-${input}`}
                  className="block font-semibold mb-2"
                >
                  Enter {input}
                  {!props.isForAddNew  && input !== "fileType" && input !== "Publicatie" && input !== "Source" && input !== "Remark" && ( 
                  <span className="text-red-500 text-medium">*</span>
                )}
                </label>
                {input === "Complexity" ? (
                    <select
                      id={`inp${input.toLowerCase()}`}
                      className="shadow-lg rounded-xl p-4 block w-full"
                      defaultValue={""}
                    >
                      {["Simple", "Medium", "Complex"].map((complexity, index) => (
                        <option key={index} value={complexity}>
                          {complexity}
                        </option>
                      ))}
                    </select>
                    ) : input === "Priority" ? (
                      <select
                        id={`inp${input.toLowerCase()}`}
                        className="shadow-lg rounded-xl p-4 block w-full"
                        defaultValue={""}
                      >
                        {["High", "Medium", "Low"].map((priority, index) => (
                          <option key={index} value={priority}>
                            {priority}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                      key={Date.now()}
                      required
                      type="text"
                      id={`inp${input.toLowerCase()}`}
                      placeholder={`Enter ${input}`}
                      className="shadow-lg rounded-xl p-4 block w-full"
                      defaultValue={
                        !props.isForAddNew ? props.checkedRows[0]?.[input] : ""
                      }
                      {...(input === "fileType" || input === "Publicatie" || input === "Source" || input === "Remark"
                      ? { required: false }
                      : { required: true })}
                    />
        )}
               
              </div>
            )
          );
        })}

        <div className="col-span-full flex justify-center items-center space-x-12 text-center ">
          <button className="bg-blue-500 mb-10 mt-5 justify-self-center py-2 px-5 text-white">
            Save Changes
          </button>
          <button
            className="bg-red-500 mb-10 mt-5 justify-self-center py-2 px-5 text-white"
            onClick={(e) => {
              e.preventDefault();
              props.setPopupState(false);
            }}
          >
            CANCEL
          </button>
        </div>
      </form>
    </>
  );
};

export default PopupEdit;
