import React, { useEffect, useState, useRef } from "react";
import Button from "../Button/button";
import axios from "../../service/api";
import DataSubmittingPop from "./DataSubmitPopup";

const MultiSelectionPopup = React.memo((props) => {
  console.log('check multiple selection popup......', props)
  const [users, setUsers] = useState([]);
  const [inputValue, setInpputValue] = useState("");
  const [isVisible, setVisibility] = useState(false);
  const [stages, setStages] = useState([]);
  const [isError, setErrorStatus] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isProductionDisabled, setProductionDisabled] = useState(false);
  const [isQADisabled, setQADisabled] = useState(false);
  const [isFinalDisabled, setFinalDisabled] = useState(false);
  const [isDataProcessed, setDataProcessed] = useState(false);
  const isFirstTime = useRef(true);

  const getUsersFromLocalStorage = () => {
    const usersFromLocalStorage = localStorage.getItem("userDetails");
    if (usersFromLocalStorage) {
      setUsers(JSON.parse(usersFromLocalStorage));
    }
  };

  const storeUsersInLocalStorage = (data) => {
    localStorage.setItem("userDetails", JSON.stringify(data));
  };

  const getData = async () => {
    try {
      // const usersFromLocalStorage = localStorage.getItem("userDetails");

      // if (usersFromLocalStorage) {
      //   // User details found in local storage, set them in state
      //   setUsers(JSON.parse(usersFromLocalStorage));
      // } else {
      // User details not found in local storage, make API call
      var user = await axios.post("/allocation/getUserAllocation", {
        role: ["Production", "Production InCharge", "Logistics"],
        isAllocation: true,
      });
      user = user.data.user;

      if (user?.length > 0) {
        // Store user details in local storage
        storeUsersInLocalStorage(user);
        setUsers(user);
      }
    } catch (error) {
      props.setError();
    }
  };

  const sendData = async () => {
    if (stages.length < 1) {
      setErrorStatus(true);
    } else {
      setProcessing(true);
      // let time = 1000;
      // stages.forEach((value) => {
      // setTimeout(async () => {
      try {
        const updatedDataFiles = [...props.data];
        for (const value of stages) {
          const pubCode = props.checkedRows
            .map((val) => val.Pubcode)
            .toString();
          const response = await axios.post("/allocation/simpleAllocation", {
            type: pubCode,
            ids: props.checkedRows.map((val) => val._id),
            member: users.find((val) => val.email === inputValue).email,
            stage: value,
            pageNumber: props.checkedRows.map((val) => val["Number of pages"]),
          });

          // const updatedCheckedRows = response.data.responseFiles;
          // const updatedCheckedRowsArray = [updatedCheckedRows];
          // const dataIndex = updatedDataFiles.findIndex(
          //   (item) => item._id === updatedCheckedRows._id
          // );

          // if (dataIndex !== -1) {
          //   updatedDataFiles[dataIndex] = updatedCheckedRows;
          // }
          const updatedCheckedRowsArray = response.data.responseFiles;

          for (const updatedCheckedRows of updatedCheckedRowsArray) {
            const dataIndex = updatedDataFiles.findIndex(
              (item) => item._id === updatedCheckedRows._id
            );
    
            if (dataIndex !== -1) {
              updatedDataFiles[dataIndex] = updatedCheckedRows;
            }
          }

          props.setCheckedRows(updatedCheckedRowsArray);
          props.setData(updatedDataFiles);
        }
        props.closePopUp();
        // props.setCheckedRows([]);  //Commented this line because default checkbox not applied....
        setProcessing(false);
        // }
   
      } catch (error) {
        props.setError();
        // setProcessing(false);
      }
    }
  };
  useEffect(() => {
    if (isFirstTime.current) {
      localStorage.removeItem(localStorage.getItem("userDetails"));
      const storedOptions = getUsersFromLocalStorage();
      if (storedOptions) {
        setUsers(storedOptions);
      } else {
        getData();
      }
      isFirstTime.current = false;
    }
  }, []);

  const isCheckedQA = props.checkedRows
    .some((row) => row.Allocation.some((al) => al.stage === "QA Assign"))
    .toString();

  const isCheckedProduction = props.checkedRows
    .some((row) =>
      row.Allocation.some((al) => al.stage === "Production Assign")
    )
    .toString();

  const isCheckedFinal = props.checkedRows
    .some((row) => row.Allocation.some((al) => al.stage === "Final Assign"))
    .toString();

  useEffect(() => {
    setProductionDisabled(isCheckedProduction === "true");
    setQADisabled(isCheckedQA === "true");
    setFinalDisabled(isCheckedFinal === "true");
  }, [isCheckedProduction, isCheckedQA, isCheckedFinal]);

  return isProcessing ? (
    <DataSubmittingPop />
  ) : (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-20 z-10"
        onClick={() => {
          props.closePopUp();
          //  props.setPopupState(false);
        }}
      ></div>
      <div className="fixed inset-0 bg-black bg-opacity-20 z-50">
        <div className="w-c-6 fixed space-y-4 px-8 py-10 h-46 flex flex-col justify-center items-center bg-white top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-30 rounded-lg">
          <div className="grid grid-cols-4 h-32 overflow-scroll gap-x-14 gap-y-7">
            {props.checkedRows.map((val, index) => (
              <p key={index} className="break-all">
                <span className="text-blue-700 font-bold text-xl">
                  {index + 1}.
                </span>{" "}
                {val.filename}
              </p>
            ))}
          </div>
          <div className="w-full px-10">
            <div className="flex items-center mt-2 ">
              <input
                type="text"
                onChange={(e) => {
                  if (e.target.value === "") setVisibility(false);
                  else setVisibility(true);
                  setInpputValue(e.target.value);
                }}
                value={inputValue}
                placeholder="Enter employee email"
                className="w-full pr-10 pl-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
              />
              <svg
                className="w-4 h-4 fill-current text-gray-500 -ml-8 z-10 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="black"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
              </svg>
            </div>
            {isVisible ? (
              <div className="w-full shadow-2xl relative pr-10 py-10  pl-4 rounded-lg ">
                <div className="w-full h-40 overflow-scroll py-2 px-5 top-0 left-0 absolute bg-white z-20">
                  {users
                    .filter((val) =>
                      val.email.toLowerCase().includes(inputValue.toLowerCase())
                    )
                    .map((val, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setVisibility(false);
                          setInpputValue(val.email);
                        }}
                        className="w-full my-2 cursor-pointer border-2 py-2 px-5 hover:bg-gray-300 rounded-md top-0 left-0 bg-white z-20"
                      >
                        {val.fullName}
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="flex">
            <div className="mr-5">
              <input
                type="checkbox"
                onChange={(e) => {
                  const tempStages = [...stages];
                  if (e.target.checked) {
                    setErrorStatus(false);
                    tempStages.push("Production Assign");
                  } else {
                    tempStages.splice(
                      tempStages.indexOf("Production Assign"),
                      1
                    );
                  }
                  setStages([...tempStages]);
                }}
                name="check"
                id="GFG"
                className="mr-2"
                disabled={isProductionDisabled}
              />{" "}
              Production
            </div>
            <div className="mr-5">
              <input
                type="checkbox"
                onChange={(e) => {
                  const tempStages = [...stages];
                  if (e.target.checked) {
                    setErrorStatus(false);
                    tempStages.push("QA Assign");
                  } else {
                    tempStages.splice(tempStages.indexOf("QA Assign"), 1);
                  }
                  setStages([...tempStages]);
                }}
                name="check"
                id="GFG"
                className="mr-2"
                disabled={isQADisabled}
              />{" "}
              QA/Final
            </div>

            {/* <div className="mr-5">
              <input
                type="checkbox"
                onChange={(e) => {
                  const tempStages = [...stages];
                  if (e.target.checked) {
                    setErrorStatus(false);
                    tempStages.push("Final Assign");
                  } else {
                    tempStages.splice(tempStages.indexOf("Final Assign"), 1);
                  }
                  setStages([...tempStages]);
                }}
                name="check"
                id="GFG"
                className="mr-2"
                disabled={isFinalDisabled}
              />{" "}
              Finalize
            </div> */}
          </div>
          {isError ? (
            <div className="w-full flex items-center justify-center">
              <span className="text-red-600">Please select a option</span>
            </div>
          ) : (
            <></>
          )}
          <div className="w-full flex justify-center items-center">
            <Button className="bg-blue-500 mx-2" onClick={() => sendData()}>
              Done
            </Button>
            <Button
              onClick={() => props.closePopUp()}
              className="bg-red-500 mx-2"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
});

export default MultiSelectionPopup;
