import React, { useEffect, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import axios from "../../service/api";
import { getRole } from "../../service/auth";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get("/notification/getAllNotification");
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchNotifications();
  }, []);

  // const handleClearNotifications = () => {
  //   setNotifications([]);
  // };

  return (
    <Layout
      isStakeholders={getRole() === "Stakeholders"}
      isAdmin={getRole() === "Admin"}
      isLogistics={getRole() === "Logistics"}
      isTL={getRole() === "Production InCharge"}
      isProd={getRole() === "Production"}
    >
      <div className="container mx-auto p-4">
        {/* <h1 className="text-2xl font-bold mb-4">Notifications</h1> */}
        {/* <div className="flex justify-between items-center mb-6">
          <button
            onClick={handleClearNotifications}
            className="text-sm top-0 left-0 bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
          >
            Clear
          </button>
        </div> */}

        {notifications.length === 0 ? (
          <div className="w-full mt-5 font-semibold bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
            No Notification Available
          </div>
        ) : (
          <div>
            <ul className="list-none mt-6 text-lg font-mono">
              {notifications.map((notification) => (
                <li
                  key={notification.id}
                  className="border p-4 mb-4 rounded-lg bg-slate-300 flex justify-between items-center"
                >
                  {notification.body}
                  <div>
                    <Link
                     key={`link-${notification.id}`} 
                      to="/profile"
                      className="text-blue-500 underline hover:no-underline"
                    >
                      Click here to view
                    </Link>
                  </div>
                  <div className="text-gray-600">
                    {formatDistanceToNow(new Date(notification.createdAt), {
                      addSuffix: true,
                    })}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Notification;
