import React from "react";

import { Navigate } from "react-router-dom";
import ProgressBar from "../pages/Dashboard/ProgressBar";
import { isLoggedIn, isBrowser } from "../service/auth";

const ProtectRoute = (props) => {
  if (!isLoggedIn()) {
    if (isBrowser()) {
      if (typeof window !== `undefined`) Navigate("/");
      return <ProgressBar />;
    }
    return <ProgressBar />;
  }

  return props.children;
};

export default ProtectRoute;
