import React from "react";

const Button = (props) => (
  <button
    disabled={props.disabled || false}
    onClick={props.onClick}
    className={`rounded-lg uppercase px-5 py-1 text-white ${
      props.className || ""
    } ${props.disabled ? 'opacity-50' : ''} `}
  >
    {props.children}
  </button>
);

export default Button;
