import axios from "axios";

export const isBrowser = () => typeof window !== "undefined";

export const getUser = () => {
  if (typeof window !== `undefined`) {
    return isBrowser() && window?.sessionStorage.getItem("userData")
      ? JSON.parse(window?.sessionStorage.getItem("userData"))
      : {};
  }
};
export const setUser = (user) => {
  if (typeof window !== `undefined`) {
    window.sessionStorage.setItem("userData", JSON.stringify(user));
  }
};
export const handleLogin = (token, role, userName) => {
  try {
    setUser({
      userName: userName,
      token: token,
      role: role,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const isLoggedIn = () => {
  const user = getUser();

  return !!user?.userName;
};

export const getToken = () => {
  const user = getUser();

  return user?.token;
};

export const getRole = () => {
  const user = getUser();

  return user?.role;
};

export const logout = (callback) => {

  if (typeof window !== `undefined`) {
    setUser({});
    window.sessionStorage.clear();
  }
  callback();
};

export const isAuthenticated = () => {
  return !!sessionStorage.getItem("userData");
};

// export const saveUser = (userData) => {
//   if (typeof window !== `undefined`) {
//     window.sessionStorage.setItem("userData", JSON.stringify(userData));
//   }
// };

