import React, { createContext, useContext, useEffect, useState } from 'react';

const NotificationContext = createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

export function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);
  const showPushNotification = (title, options) => {
    if (Notification.permission === "granted") {
      navigator.serviceWorker.ready
        .then((registration) => {
          return registration.showNotification(title, options);
        })
        .catch((error) => {
          console.error("Error showing notification:", error);
        });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            navigator.serviceWorker.ready
              .then((registration) => {
                return registration.showNotification(title, options);
              })
              .catch((error) => {
                console.error("Error showing notification:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error requesting notification permission:", error);
        });
    }
  };


  return (
    <NotificationContext.Provider value={{ notifications, setNotifications, showPushNotification }}>
      {children}
    </NotificationContext.Provider>
  );
}


export default NotificationProvider;
