import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "../../service/api";
import Sync from "../../assets/sync.svg";
import {
  getToken,
  getUser,
  getRole,
  logout,
  isLoggedIn,
} from "../../service/auth";
import moment from "moment-timezone";
import athena_logo from "../../images/athena-logo.png";
import ProtectedRoute from "../../utils/protectRoute";
import AttendancePopup from "../Popup/ErrorPopup";
import ConfirmPopup from "../Popup/ConfirmPopup";
import { FiLogOut, FiLogIn } from "react-icons/fi";
import { TbHandFinger } from "react-icons/tb";
import punchInIcon from "../../images/icon.png";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { MdOutlineNotificationsNone } from "react-icons/md";
import io from "socket.io-client";
// import ConfirmPopup from "../Popup/ConfirmPopup";
// import Dashboard from "./Dashboard";

const Header = () => {
  const [attendanceRecording, setAttendanceRecording] = useState(false);
  const [attendanceType, setAttendanceType] = useState("");
  const [showToolsDropdown, setShowToolsDropdown] = useState(false);
  const [punchInTime, setPunchInTime] = useState(null);
  const [istDate, setISTDate] = useState(null);
  const [punchOutTime, setPunchOutTime] = useState(null);
  const [hasContinueToWork, setHasContinueToWork] = useState(false);
  const [isContinueToWorkSave, setIsContinueToWorkSave] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false); 
  const [showAlreadyPunchInPopup, setShowAlreadyPunchInPopup] = useState(false);
  const [isPunchInPopupShown, setIsPunchInPopupShown] = useState(false); 
  const [userFullName, setUserFullName] = useState(""); 
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [dataFetched, setDataFetched] = useState(false); 
  const [showPunchOutPopup, setShowPunchOutPopup] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [socket, setSocket] = useState(null);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const isLoggedInRef = useRef(isLoggedIn());
  const mountedRef = useRef(true);

  const checkLogistics = getRole() === "Logistics";
  const checkTeamLead = getRole() === "Production InCharge";
  const checkProd = getRole() === "Production";

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoggedIn()) {
          const userData = getUser();
          if (userData && userData.userName) {
            setUserFullName(userData.userName);
          }
  
          const attendanceResponse = await axios.get("/attendance/getAttendance");
  
          if (attendanceResponse.data.newCandidateLogin) {
            setShowPopup(true);
          }
  
          setAttendanceType(attendanceResponse.data.type);
          setPunchInTime(attendanceResponse.data.PunchInTime);
          setPunchOutTime(attendanceResponse.data.PunchOutTime);
          setIsPunchInPopupShown(attendanceResponse.data.isFirstTimeLogin);
          setIsContinueToWorkSave(attendanceResponse.data.continueToWork);
  
          setDataFetched(true); // Mark data as fetched
  
          const punchInDateFormatted = moment(
            attendanceResponse.data.PunchInTime,
            "DD MMM YYYY hh:mm A"
          ).format("DD MMM YYYY");
  
          // Check if PunchInDate is before two days from currentDate
          const isPunchInDateBeforeTwoDays = moment(
            punchInDateFormatted,
            "DD MMM YYYY"
          ).isBefore(moment().subtract(1, "days"), "day");
  
          if (
            isPunchInDateBeforeTwoDays &&
            attendanceResponse.data.continueToWork &&
            attendanceResponse.data.PunchStatus
          ) {
            setShowPopup(true);
            setIsPunchInPopupShown(true);
            setIsContinueToWorkSave(false);
          }
  
          const response = await axios.get("/api/getISTTime");
          const istDateTime = response.data.utc_datetime;
          const istDateFormatted = moment(istDateTime)
            .format("YYYY-MM-DD");
          setISTDate(istDateFormatted);
  
          // Fetch profile picture after getting attendance data
          const profileName = userData.profilePic.split("/")[3];
          const profilePicResponse = await axios.get(
            `/user/profile-pic/${profileName}`,
            {
              responseType: "arraybuffer",
            }
          );
  
          const arrayBufferView = new Uint8Array(profilePicResponse.data);
          const blob = new Blob([arrayBufferView], { type: "image/*" });
          const dataUrl = URL.createObjectURL(blob);
          setProfilePicUrl(dataUrl);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchNotificationCount = async () => {
      try {
        if(isLoggedIn()){
        const response = await axios.get("/notification/unreadNotificationCount");
        const count = response.data.unreadNotificationsCount;
          if (mountedRef.current) {
            setNotificationCount(count);
          }
        }
      } catch (error) {
        console.error("Error fetching notification count:", error);
      }
    };

    fetchNotificationCount();

    return () => {
      mountedRef.current = false;
    };
  }, []);

  // useEffect(() => {
  //   const socketInstance = io.connect(axios.defaults.baseURL);
  //   setSocket(socketInstance);

  //   return () => {
  //     if (socketInstance) {
  //       socketInstance.disconnect();
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   if (socket) {
  //     socket.on("newNotification", (response) => {
  //       if (response.userId === getUser()?.id && response.isRead === false) {
  //         // Increment the notification count
  //         setNotificationCount((prevCount) => prevCount + 1);
  //       }
  //     });
  //   }

  //   return () => {
  //     if (socket) {
  //       socket.off("newNotification");
  //     }
  //   };
  // }, [socket]);
  

  // useEffect(() => {
  //   const fetchISTTime = async () => {
  //     try {
  //       const response = await axios.get("/api/getISTTime");
  //       const istDateTime = response.data.utc_datetime;
  //       const istDateFormatted = moment(istDateTime)
  //         .format("YYYY-MM-DD");
  //       setISTDate(istDateFormatted);
  //     } catch (error) {
  //       console.error("Error fetching IST time:", error);
  //     }
  //   };

  //   fetchISTTime();
  // }, []);

  const shouldShowContinuePopup = () => {
    setShowPopup(false);
    const continuePopupStatus = sessionStorage.getItem("continuePopupStatus");
    return continuePopupStatus !== "no";
  };

  useEffect(() => {
    const checkTimeAtMidnightIST = () => {
      if (istDate && punchInTime) {
        const punchInDate = moment(punchInTime)
          .format("YYYY-MM-DD");
        const punchInDateTime = moment(punchInTime);
        const currentTimeIST = moment();
        // const lastPunchOutTime = moment(istDate + " 11:59 PM");
        const midnightISTStart = moment(istDate + " 11:59 PM");

        // Check if it's past midnight IST and the user hasn't confirmed
        if (
          currentTimeIST.isSameOrAfter(midnightISTStart) &&
          currentTimeIST.isBefore(
            midnightISTStart.clone().add(15, "minutes")
          ) && // Check within a 1-minute window
          !isContinueToWorkSave &&
          shouldShowContinuePopup()
        ) {
          setHasContinueToWork(true); // Display the punch-in confirmation popup
        } else if (punchInDate !== istDate) {
          setShowPopup(true);
        } else {
          setShowPopup(false);
        }
      }
    };

    // Check the time every minute (you can adjust the interval as needed)
    const interval = setInterval(checkTimeAtMidnightIST, 60000); // 60000 milliseconds = 1 minute

    // Run the check immediately when the component mounts
    checkTimeAtMidnightIST();
    return () => clearInterval(interval);
  }, [istDate, punchInTime, isContinueToWorkSave]);

  const handleAttendanceStatus = async (status) => {
    try {
      setAttendanceRecording(true);
      if (status === "IN") {
        updateAttendanceData(status, new Date(), null);
      } else {
        setShowPunchOutPopup(true);
        // updateAttendanceData(status, punchInTime, new Date());
        // if (status === "OUT") {
        //   setShowPunchOutPopup(true); // Display the punch out confirmation popup
        // }
      }
    } catch (error) {
      console.error(error);
    }
    setAttendanceRecording(false);
    setIsDropdownOpen(false);
  };

  const handlePunchOutConfirmed = async () => {
    try {
      await updateAttendanceData("OUT", punchInTime, new Date());
      // await axios.post(
      //   `/attendance/hasContinueToWork?continueCheck=no`
      // );
      setTimeout(() => {
        setShowPunchOutPopup(false);
        handleLogout();
      }, 2000); // 2000 milliseconds = 2 seconds
    } catch (error) {
      console.error(error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const updateAttendanceData = async (type) => {
    try {
      setAttendanceRecording(true);
      const response = await axios.post("/attendance/saveAttendance", {
        type,
      });
      setAttendanceType(type);

      if (type === "IN") {
        setPunchInTime(response.data.data.PunchInTime);
        setPunchOutTime(null);
      } else {
        setPunchOutTime(response.data.data.PunchOutTime);
        // setShowPunchOutPopup(true);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 409 &&
        error.response.data.message === "Already punched in for the day"
      ) {
        setShowAlreadyPunchInPopup(true); // Show the error popup
      } else {
        console.error(error);
      }
    } finally {
      setAttendanceRecording(false);
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowToolsDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleContinueToWork = (continueCheck) => {
    // Update the UI based on the user's selection
    if (continueCheck === "yes") {
      setShowPopup(false); // Close the popup
      setHasContinueToWork(false); // Set hasContinueToWork to false
      sessionStorage.setItem("continuePopupStatus", "yes");
    } else if (continueCheck === "no") {
      setShowPopup(true); // Show the popup
      setHasContinueToWork(false); // Set hasContinueToWork to false
      sessionStorage.setItem("continuePopupStatus", "no");
    }
  };

  const handleLogout = () => {
    logout(() => {
      navigate("/", { replace: true });
    });
  };

  const toggleToolsDropdown = () => {
    setShowToolsDropdown((prevState) => !prevState);
  };

  const handleOpenLinkFixedLayout = () => {
    const link = `https://fixedlayout.patelgtech.com/login?token=${getToken()}&user=${isLoggedIn()}&role=${getRole()}`;

    // Copy link to clipboard
    navigator.clipboard
      .writeText(link)
      .then(() => {
        window.open(link);
      })
      .catch((error) => {
        console.error("Failed to copy link to clipboard:", error);
        window.open(link);
      });
  };

  const handleXCPPNL = () => {
    window.open("https://xcpp.nl/");
  };

  const handleRepubClick = () => {
    // if (authenticated) {
    window.open("https://patel.xcpp.nl/");
    // } else {
    //   console.log("Cannot open the link");
    // }
  };

  const handlePTeamRepubClick = () => {
    // if (authenticated) {
    window.open("https://pteam.xcpp.nl/patel/");
    // } else {
    //   console.log("Cannot open the link");
    // }
  };

  const handleAccessibilityClick = () => {
    // if (authenticated) {
    window.open("https://accessibility.patelinfo.com/");
    // } else {
    //   console.log("Cannot open the link");
    // }
  };

  return (
    <ProtectedRoute>
      <header className="bg-[#0e0e0e] text-black py-3 px-6 flex items-center">
        {/* <span>Patel Information Technology</span> */}
        <Link to="/dashboard">
          <img src={athena_logo} className="absolute top-0 left-0 w-25 h-20" />
        </Link>
        {/* <div className="flex justify-start"> */}
        {(checkLogistics || checkTeamLead || checkProd) && (
          <div className="ml-44 relative group">
            {/* Tools Dropdown Button */}
            <button
              className="relative p-2 bg-gray-100 rounded-lg text-gray-700 hover:bg-rose-200 focus:outline-none flex items-center"
              onClick={toggleToolsDropdown}
            >
              {/* Tools */}
              <span className="mr-2 text-green-500">Tools</span>
              {showToolsDropdown ? (
                <RiArrowDropUpLine className="text-2xl" />
              ) : (
                <RiArrowDropDownLine className="text-2xl" />
              )}
            </button>

            {/* Tools Dropdown */}
            {showToolsDropdown && (
              <div
                ref={dropdownRef}
                className="absolute top-0 right-0 mt-8 w-40 bg-white shadow-lg rounded-lg divide-y divide-gray-200 z-20"
              >
                {(checkLogistics || checkTeamLead) && (
                  <button
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none"
                    onClick={handleXCPPNL}
                  >
                    Xcpp nl
                  </button>
                )}
                <button
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none"
                  onClick={handleRepubClick}
                >
                  Patel Repub
                </button>
                {/* <button
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none"
                      onClick={() =>
                        window.open(
                          `https://fixedlayout.patelinfo.com/login?token=${getToken()}&user=${isLoggedIn()}&role=${getRole()}`
                        )
                      }
                    >
                      Fixed Layout
                    </button> */}
                {/* Add more tools as needed */}
                <button
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none"
                  onClick={handlePTeamRepubClick}
                >
                  Pteam Repub
                </button>
                <button
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none"
                  onClick={handleAccessibilityClick}
                >
                  Patel Accessibility
                </button>
                {getUser()?.email === "logistics@patelinfo.com" && (
                  <button
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none"
                    // onClick={() =>
                    //   window.open(
                    //     `https://fixedlayout.patelinfo.com/login?token=${getToken()}&user=${isLoggedIn()}&role=${getRole()}`
                    //   )
                    // }
                    onClick={handleOpenLinkFixedLayout}
                  >
                    Fixed Layout
                  </button>
                )}
              </div>
            )}
          </div>
        )}
        {/* </div> */}
        <div className="ml-auto flex items-center" ref={dropdownRef}>
          <div className="relative">
            <div className="flex items-center">
              <span className="text-gray-300 font-bold mr-2">
                Last Punch In at {moment(punchInTime).format("DD MMM YYYY hh:mm A")}
              </span>
              {/* <div className="flex items-center flex-col"> */}
              {dataFetched && profilePicUrl && (
                <img
                  src={profilePicUrl}
                  className="w-10 h-10 rounded-full object-cover border-2 border-white mr-2"
                  alt="Profile"
                />
              )}
              <span className="text-gray-300 font-bold">{userFullName}</span>
              <button
                className={`relative flex items-center ml-2 text-gray-300 hover:text-white`}
                onClick={() => {
                  navigate("/notification");
                  setNotificationCount(0)
                }}
              >
                <MdOutlineNotificationsNone className="w-7 h-7" />
              {notificationCount > 0 && (
              <span className="absolute top-0 right-0 -mt-2 -mr-2 text-sm bg-red-500 text-white rounded-full px-2">
                {notificationCount}
              </span>
            )}
              </button>
              <button
                className={`flex items-center ${
                  attendanceType === "IN"
                    ? "text-blue-500 hover:text-blue-700"
                    : "text-red-500 hover:text-red-500"
                }`}
                onClick={() =>
                  handleAttendanceStatus(attendanceType === "IN" ? "OUT" : "IN")
                }
              >
                {attendanceType === "IN" ? (
                  <>
                    {/* <img
              src={punchInIcon}
              className="w-10 h-10 object-cover ml-4"
              alt="Punch Out"
            /> */}

                    <span className="text-lg font-bold ml-2">Punch Out</span>
                    <ExitToAppIcon className="w-6 h-6 mr-2 text-blue-500 transform rotate-180" />
                  </>
                ) : (
                  <>
                    <ExitToAppIcon className="w-6 h-6 ml-2 text-red-400 transform rotate-0" />
                    <span className="text-lg font-bold mr-2">Punch In</span>
                  </>
                )}
              </button>

              {showPunchOutPopup && (
                <ConfirmPopup
                  punchOutToday={true}
                  message="punch out for today's date"
                  openPopup={showPunchOutPopup}
                  handleClose={() => setShowPunchOutPopup(false)}
                  onConfirmed={handlePunchOutConfirmed}
                />
              )}
            </div>
          </div>
        </div>
        {showPopup && !isContinueToWorkSave && isPunchInPopupShown ? (
          <AttendancePopup
            isPunchIn={true}
            buttonText="Punch IN"
            action={() => handleAttendanceStatus("IN")}
            message="Please PunchIn for today's date and start working."
          />
        ) : (
          <></>
        )}
        {showAlreadyPunchInPopup ? (
          <AttendancePopup
            // isPunchIn={true}
            isShowAction={true}
            buttonText="OK"
            // action={() => cancelPopup("IN")}
            action={() => {
              setShowAlreadyPunchInPopup(false);
            }}
            message="You have already punch-In for today date."
          />
        ) : (
          <></>
        )}

        {hasContinueToWork && (
          <ConfirmPopup
            continueToWork={true}
            message="Do you want to continue working?"
            openPopup={hasContinueToWork}
            handleClose={() => handleContinueToWork("no")}
            onConfirmed={async () => {
              // Make an API call to set the continueToWork status when user clicks "Yes"
              await axios.post(
                `/attendance/hasContinueToWork?continueCheck=yes`
              );

              // Update the UI based on the user's selection
              handleContinueToWork("yes");
            }}
          />
        )}
      </header>
    </ProtectedRoute>
  );
};

export default Header;
