import React, { useEffect, useState, useRef } from "react";

import Button from "../../Button/button";
import Popup from "../../Popup/Popup";
import PopupFilStatusCheck from "../../Popup/FileStatusPopup";
import DataSubmittingPop from "../../Popup/DataSubmitPopup";
import MultipleSelectionPopUp from "../../Popup/MultipleSelectionPopup";
import BulkSelectionPopUp from "../../Popup/AllocationPopup/BulkAllocationPopup";
import TextPopup from "../../Popup/TextPopup";
import PopupEdit from "../../Popup/PopupEdit";
import ErrorPoup from "../../Popup/ErrorPopup";
import EditAllocationPopUp from "../../Popup/AllocationPopup/EditAllocationPopup";
import { getRole } from "../../../service/auth";
import axios from "../../../service/api";

const RepubManager = React.memo((props) => {
  const [popupState, setPopupState] = useState(false);
  const [checkPopupStatus, setCheckPopupStatus] = useState(false);
  const [loadPopupState, setLoadPopup] = useState(0);
  const [isEditAllocation, setEditAllocation] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isError, setError] = useState(false);
  const [isEditPopUpState, setEditPopupState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageAllocation, setErrorMessageAllocation] = useState("");
  const [isTextBoxOpen, setIsTextBoxOpen] = useState(false);
  const [openNewFilePopup, setOpenNewFilePopup] = useState(false);

  const [isAddNew, setIsAddNew] = useState(true);

  const selectedInputRef = useRef(null);
  const searchInputRef = useRef(null);

  const handleApply = () => {
    if (props.checkedRows.length === 0) {
      setErrorMessageAllocation("Please select a file");

      //Set a timer to clear the error message after 5 sec....
      setTimeout(() => {
        setErrorMessageAllocation("");
      }, 3000);
    } else {
      setErrorMessageAllocation("");
      setLoadPopup(selectedInputRef.current.value);
    }
  };

  const JSX = [];
  console.log(isError);
  if (isError) {
    JSX.push(
      <ErrorPoup
        isShowAction={true}
        message={errorMessage}
        action={() => {
          setError(false);
        }}
      />
    );
  }

  if (isEditPopUpState) {
    JSX.push(
      <PopupEdit
      inputs={
        props.addUrl.includes("/user")
          ? props.inputs
          : (() => {
              const tempArray = [...props.inputs];
              tempArray.splice(tempArray.indexOf("Download"), 1);
              tempArray.splice(tempArray.indexOf("Allocation"), 1);

              return tempArray;
            })()
      }
        // inputs={props.inputs}
        data={props.data}
        setData={props.setData}
        setError={() => {
          setError(true);
          setPopupState(false);
          setEditPopupState(false);
        }}
        popupState={popupState}
        addUrl={props.addUrl}
        editUrl={props.editUrl}
        setPopupState={(state) => {
          setEditPopupState(state);
        }}
        setMessage={(message) => {
          setErrorMessage(message);
        }}
        checkedRows={props.checkedRows}
        isForAddNew={isAddNew}
        setCheckedRows={(arr) => {
          props.setCheckedRows(arr);
        }}
      />
    );
  }

  if (isProcessing) {
    JSX.push(<DataSubmittingPop />);
  }

  if (loadPopupState === "1") {
    JSX.push(
      <MultipleSelectionPopUp
        closePopUp={() => closePopUp()}
        setError={() => {
          setError(true);
          setPopupState(false);
          setEditPopupState(false);
          closePopUp();
        }}
        setCheckedRows={(arr) => {
          props.setCheckedRows(arr);
        }}
        data={props.data}
        setData={props.setData}
        checkedRows={props.checkedRows}
      />
    );
  } else if (loadPopupState === "2") {
    JSX.push(
      <BulkSelectionPopUp
        closePopUp={() => closePopUp()}
        setError={(message) => {
          setError(true);
          setPopupState(false);
          setEditPopupState(false);
          setErrorMessage(message);
          closePopUp();
        }}
        setCheckedRows={(arr) => {
          props.setCheckedRows(arr);
        }}
        data={props.data}
        setData={props.setData}
        checkedRows={props.checkedRows}
      />
    );
  } else if (loadPopupState === "3") {
    if (props.checkedRows.length) {
      setEditAllocation(true);
    }
    setLoadPopup(0);
  } else if (loadPopupState === "4") {
    setIsAddNew(false);

    if (props.checkedRows.length) {
      setPopupState(true);
    }
    setLoadPopup(0);
  } else if (loadPopupState === "5") {
    if (props.checkedRows.length) {
      setCheckPopupStatus(true);
    }
    setLoadPopup(0);
  }

  const closeTextPopup = () => {
    setIsTextBoxOpen(false);
  };

  const closePopUp = () => {
    setLoadPopup(0);
  };

  if (isEditAllocation) {
    JSX.push(
      <EditAllocationPopUp
        data={props.data}
        setData={props.setData}
        row={props.checkedRows}
        setCheckedRows={(arr) => {
          props.setCheckedRows(arr);
        }}
        onCancel={() => {
          console.log("hello");
          setEditAllocation(false);
        }}
      />
    );
  }

  isTextBoxOpen
    ? JSX.push(
        <TextPopup
          closePopUp={() => closeTextPopup()}
          onCancel={() => {
            setPopupState(false);
          }}
        />
      )
    : JSX.push(<></>);

  popupState
    ? JSX.push(
        <Popup
          inputs={
            props.addUrl.includes("/user")
              ? props.inputs
              : (() => {
                  const tempArray = [...props.inputs];
                  tempArray.splice(tempArray.indexOf("Download"), 1);
                  tempArray.splice(tempArray.indexOf("Allocation"), 1);

                  return tempArray;
                })()
          }
          data={props.data}
          setData={props.setData}
          popupState={popupState}
          addUrl={props.addUrl}
          setError={() => {
            setError(true);
            setPopupState(false);
            setEditPopupState(false);
          }}
          editUrl={props.editUrl}
          setPopupState={(state) => {
            setPopupState(state);
          }}
          checkedRows={props.checkedRows}
          isForAddNew={isAddNew}
          setCheckedRows={(arr) => {
            props.setCheckedRows(arr);
          }}
          onCancel={() => {
            setPopupState(false);
          }}
        />
      )
    : JSX.push(<></>);

  checkPopupStatus
    ? JSX.push(
        <PopupFilStatusCheck
          data={props.data}
          // setData={props.setData}
          row={props.checkedRows}
          setCheckedRows={(arr) => {
            props.setCheckedRows(arr);
          }}
          onCancel={() => {
            console.log("hello");
            setCheckPopupStatus(false);
          }}
        />
      )
    : JSX.push(<></>);

  const handleClickOnAddNew = () => {
    setIsAddNew(true);
    setPopupState(true);
  };

  const handleClickOnEditBtn = (e) => {
    e.preventDefault();

    setIsAddNew(false);

    if (props.checkedRows.length) {
      setEditPopupState(true);
    }
  };

  //Production Download code from google drive api.....
  // const handleClickDow = async () => {
  //   try {
  //     for (const row of props.checkedRows) {
  //       const fileId = row["_id"];
  //       const pubCode = row["Pubcode"];
  //       const driveResponse = await axios.get(
  //         `/files/getTemporaryLink/${fileId}`, {
  //         params: { type: pubCode} ,
  //         responseType: 'json'
  //         });

  //       const downloadLink = driveResponse.data.downloadLink;
  //       const fileName = driveResponse.data.fileName;

  //       // Create an anchor tag
  //       const link = document.createElement('a');

  //       // Set the href attribute to the download link
  //       link.href = downloadLink;

  //       // Set the download attribute to the desired filename
  //       link.download = fileName;

  //       // Append the anchor tag to the document body
  //       document.body.appendChild(link);

  //       // Trigger a click on the anchor tag to initiate the download
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //     props.setCheckedRows([])
  //   } catch (error) {
  //     // alert("This file already downloaded. Check your download path in PC")
  //     console.log("Error occurred while initiating file download:", error);
  //     // props.disabledCheckbox();
  //     // props.applyDisableCheck();
  //   }
  // };

  const handleUploadNewFile = () => {
    setOpenNewFilePopup(true);
  };

  const handleAddFile = () => {
    setIsTextBoxOpen(true);
  };

  const epubClients = [
    { key: 1, label: "Reichelt" },
    { key: 2, label: "DataForm" },
    { key: 3, label: "Ando" },
    { key: 4, label: "DE" },
    { key: 5, label: "ELS" },
    { key: 6, label: "Lib" },
    { key: 7, label: "Rob" },
    { key: 8, label: "G-Epub" },
  ];

  openNewFilePopup
    ? JSX.push(
        <Popup
          inputs={
            props.addUrl.includes("/user")
              ? props.inputs
              : (() => {
                  const tempArray = [...props.inputs];
                  tempArray.splice(tempArray.indexOf("Release date"), 1);
                  tempArray.splice(tempArray.indexOf("Received client"), 1);
                  tempArray.splice(tempArray.indexOf("Status"), 1);
                  tempArray.splice(tempArray.indexOf("type"), 1);
                  tempArray.splice(tempArray.indexOf("Pubcode"), 1);
                  tempArray.splice(tempArray.indexOf("Issue"), 1);
                  tempArray.splice(tempArray.indexOf("Remark"), 1);
                  // tempArray.splice(tempArray.indexOf("Source"), 1);
                  // tempArray.splice(tempArray.indexOf("Download"), 1);
                  tempArray.splice(tempArray.indexOf("Allocation"), 1);

                  return tempArray;
                })()
          }
          client={epubClients}
          // data={props.data}
          setData={props.setData}
          popupState={popupState}
          addUrl={props.addUrl}
          editUrl={props.editUrl}
          setError={() => {
            setError(true);
            setOpenNewFilePopup(false);
            // setEditPopupState(false);
          }}
          setPopupState={(state) => {
            setOpenNewFilePopup(state);
          }}
          isForAddNew={isAddNew}
          onCancel={() => {
            setOpenNewFilePopup(false);
          }}
        ></Popup>
      )
    : JSX.push(<></>);

  //Production Download from the dropbox...
  const handleClickDow = async () => {
    try {
      for (const row of props.checkedRows) {
        const id = row["_id"];
        const pubCode = row["Pubcode"];
        const dropboxTemporaryLinkDownloadResponse = await axios.post(
          `/files/getTemporaryLink/${id}`,
          { type: pubCode }
        );

        const dropboxURL =
          dropboxTemporaryLinkDownloadResponse.data.downloadLink;
        const filename =
          dropboxTemporaryLinkDownloadResponse.data.updatedFile.filename;

        const link = document.createElement("a");
        link.href = dropboxURL;
        link.download = filename;
        link.click();
        // props.refreshList();
      }
      // props.setCheckedRows([]);
      // props.defaultChecked();
    } catch (error) {
      // alert("This file already downloaded. Check your download path in PC")
      console.log("Error occurred while initiating file download:", error);
      // props.disabledCheckbox();
      // props.applyDisableCheck();
    }
  };

  if (props.requiredManager === 1)
    JSX.push(
      <>
        <select className="border-2 border-blue-500 rounded-md outline-none uppercase text-sm px-4 py-2 mr-8">
          <option>simple Allocation</option>
          <option>Advanced Allocation</option>
        </select>
        <Button
          onClick={handleApply}
          // onClick={() =>
          //   props.checkedRows.length === 0 ? (() => {})() : setLoadPopup(true)
          // }
          className="border-2 border-blue-500 capitalize bg-blue-500 w-32"
        >
          apply
        </Button>
      </>
    );

  if (props.requiredManager === 2) {
    JSX.push(
      <div className="flex items-center">
        <span>Search:</span>
        <input
          onChange={props.onChange}
          className="p-2 mx-4 border-2 border-blue-500 rounded-lg"
        />
        <select
          value={props.selectedFilter.toUpperCase()}
          className="self-stretch mr-auto border-2 border-blue-500 rounded-md outline-none uppercase text-sm"
          onChange={(e) => {
            props.setSelectedFilter(e.target.value.toUpperCase());
            props.setData([...props.data]);
          }}
        >
          <option value="ALL">ALL</option>
          {props.actions?.split("|").map((opt, i) => (
            <option key={i} value={opt.toUpperCase()}>
              {opt.toUpperCase()}
            </option>
          ))}
        </select>
        <Button className="bg-blue-500 mr-8" onClick={handleClickOnEditBtn}>
          edit
        </Button>
        <Button className="bg-blue-500" onClick={handleClickOnAddNew}>
          add new
        </Button>
      </div>
    );
  }

  if (props.requiredManager === 3)
    JSX.push(
      <div className="flex items-center mt-12">
        <span>Search:</span>
        &nbsp;
        <form
          className={`${
            props.searchParams
              ? ``
              : `mr-auto ${props.actions ? "mx-4" : "ml-4 mr-auto"}`
          }`}
          onSubmit={(e) => {
            e.preventDefault();
            props.onSearch(searchInputRef.current.value);
          }}
        >
          <input
            ref={searchInputRef}
            className={`p-2 border-2 border-blue-500 rounded-lg`}
          />
          <button
            type="submit"
            className={`rounded-lg uppercase px-5 py-1 text-white bg-blue-500 ml-5`}
          >
            Search
          </button>
        </form>
        {props.searchParams ? (
          <Button
            className={`bg-red-500 mr-auto ${
              props.actions ? "mx-4" : "ml-4 mr-auto"
            }`}
            onClick={() => {
              props.onClearSearch();
            }}
          >
            Reset Search
          </Button>
        ) : (
          <></>
        )}
        {/* { 
                    props.actions &&
                    <select value={props.selectedFilter.toUpperCase()} className={`self-stretch mr-auto ${dropdown}`} onChange={e => {
                        props.setSelectedFilter(e.target.value.toUpperCase());
                        props.setData([...props.data]);
                    }}>
                        <option value="ALL">ALL</option>
                        {
                            props.actions?.split('|').map((opt, i) => <option key={i} value={opt.toUpperCase()}>{opt.toUpperCase()}</option>)
                        }
                    </select>

                } */}
        <div className="space-x-8">
          <Button className="bg-blue-500" onClick={handleClickOnAddNew}>
            add new
          </Button>
          <Button className="bg-blue-500" onClick={handleClickOnEditBtn}>
            edit
          </Button>
          <Button
            className="bg-red-500"
            onClick={() => {
              props.checkedRows.length === 0
                ? (() => {})()
                : (() => {
                    // await axios(props.deleteUrl)
                    setProcessing(true);
                    props.checkedRows.forEach((val, ind) => {
                      (async () => {
                        try {
                          if (props.deleteUrl.includes("user"))
                            await axios.delete(props.deleteUrl, {
                              data: {
                                id: val._id,
                                name: val.fullName,
                              },
                            });
                          else
                            await axios.delete(props.deleteUrl, {
                              data: {
                                id: val._id,
                              },
                            });
                          if (typeof window !== `undefined`) {
                            window.location.reload();
                          }

                          if (props.checkedRows.length - 1 === ind) {
                            // props.refreshList();
                            setProcessing(false);
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      })();
                    });
                  })();
            }}
          >
            delete
          </Button>
        </div>
      </div>
    );

  if (props.requiredManager === 4)
    JSX.push(
      <div className="flex items-center mt-12">
        &nbsp;
        <div className="mr-auto mx-4">
          <input
            ref={searchInputRef}
            className={`p-2 border-2 border-blue-500 rounded-lg w-72 pl-2`}
            placeholder="Search for files"
            value={props.searchText}
            onChange={(e) => {
              e.preventDefault();
              props.onSearch(e.target.value);
            }}
          />
        </div>
        {props.title === "EPUB Files" && (
          <div className="flex flex-wrap items-center">
            <Button
              className="bg-blue-500 ml-2 mr-2 px-4 py-2 text-white rounded-lg"
              onClick={handleUploadNewFile}
            >
              Manual Upload
            </Button>
            {props.searchParams ? (
              <div
                className={`bg-red-500 ml-2 ${
                  props.actions ? "mr-2" : "mr-auto"
                } my-2 rounded-lg`}
              ></div>
            ) : null}
          </div>
        )}
        {props.title === "EPUB Files" && (
          <div className="flex flex-wrap items-center mr-4">
            <div className="relative">
              <select
                id="epubClients"
                className="block appearance-none bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:ring focus:ring-indigo-400"
                onChange={(e) => props.handleClientChange(e.target.value)}
              >
                <option value="" disabled selected>
                  Choose Client
                </option>
                {epubClients.map((client) => (
                  <option key={client.id} value={client.label}>
                    {client.label}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
        {props.title === "Files" && (
          <div className="flex flex-wrap items-center">
            <Button
              className="bg-green-500 ml-2 mr-2 px-4 py-2 text-white rounded-lg"
              onClick={handleAddFile}
            >
              Add File
            </Button>
            {props.searchParams ? (
              <div
                className={`bg-red-500 ml-2 ${
                  props.actions ? "mr-2" : "mr-auto"
                } my-2 rounded-lg`}
              ></div>
            ) : null}
          </div>
        )}
        <div className="border-r-2 border-l-2 border-black px-5 mr-5">
          <select
            ref={selectedInputRef}
            className="border-2 border-blue-500 rounded-md outline-none uppercase text-sm px-4 py-1 mr-5"
          >
            <option value="1">simple Allocation</option>
            <option value="2">advanced Allocation</option>
            <option value="3">edit Allocation</option>
            <option value="5">check Status</option>
          </select>
          <Button onClick={handleApply} className="bg-blue-500">
            apply
          </Button>
          {errorMessageAllocation && (
            <p className="text-red-500">{errorMessageAllocation}</p>
          )}
        </div>
        {getRole() === "Logistics" ? (
          <div className="space-x-8 md:space-x-8">
            <Button
              className="bg-blue-500 px-4 py-1 h-8"
              onClick={handleClickOnEditBtn}
            >
              edit file
            </Button>
            <Button
              onClick={() => {
                props.checkedRows.length === 0
                  ? (() => {})()
                  : (async () => {
                      setProcessing(true);
                      await axios.delete("/files/deleteFiles", {
                        data: {
                          type: props.checkedRows.map((v) => v.Pubcode),
                          ids: props.checkedRows.map((v) => v._id),
                          filenames: props.checkedRows.map((v) => v.filename),
                        },
                      });
                      if (typeof window !== `undefined`) {
                        window.location.reload();
                      }
                      // props.refreshList();
                      setProcessing(false);
                    })();
              }}
              className="bg-red-500 px-2 py-1 h-8"
            >
              delete
            </Button>
          </div>
        ) : getRole() === "Production InCharge" ? (
          <div className="space-x-4 md:space-x-8">
            <Button
              className="bg-blue-500 px-4 py-1 h-8"
              onClick={handleClickOnEditBtn}
            >
              edit file
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    );

  if (props.requiredManager === 5) {
    JSX.push(
      <div className="flex items-center">
        <span>Search:</span>
        <input
          onChange={props.onChange}
          className="p-1 mx-4 border-2 border-blue-500 rounded-lg"
        />
      </div>
    );
  }

  if (props.requiredManager === 6) {
    JSX.push(
      <div className="flex items-center mt-8">
        &nbsp;
        <div
          className="mr-auto mx-4"
          // className={`${
          //   props.searchParams
          //     ? ``
          //     : `mr-auto ${props.actions ? "mx-4" : "ml-4 mr-auto"}`
          // }`}
        >
          <input
            ref={searchInputRef}
            className={`p-2 border-2 border-blue-500 rounded-lg w-72 pl-2`}
            placeholder="Search for files"
            value={props.searchText}
            onChange={(e) => {
              e.preventDefault();
              props.onSearch(e.target.value);
            }}
          />
        </div>
        {props.searchParams ? (
          <div
            className={`bg-red-500 mr-auto ${
              props.actions ? "mx-4" : "ml-4 mr-auto"
            }`}
            // onClick={() => {
            //   props.onClearSearch();
            // }}
          ></div>
        ) : (
          <></>
        )}
        <div className="space-x-8 ml-auto">
          <Button
            className={`bg-green-400 ${
              props.checkedRows.length === 0
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-green-500"
            }`}
            onClick={handleClickDow}
            disabled={props.checkedRows.length === 0}
          >
            Download
          </Button>
          {/* <Button className="bg-blue-500" onClick={handleClickOnEditBtn}>
            Upload
          </Button> */}
        </div>
      </div>
    );
  }

  return JSX;
});

export default RepubManager;
