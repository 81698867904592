import React, { useState, useEffect, useRef } from "react";
import Status from "../../../utils/Stage";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import Button from "../../Button/button";
import axios from "../../../service/api";
import ErrorPopup from "../ErrorPopup";
import DataSubmittingPop from "../DataSubmitPopup";

const animatedComponents = makeAnimated();

const EditAllocationPopup = React.memo((props) => {
  console.log('CHECK EDIT POPUP ALLOCATION.', props)
  const [users, setUsers] = useState([]);
  const [isError, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentRow, setCurrentRow] = useState(0);
  const [allocationArray, setAllocationArray] = useState([
    ...props.row[currentRow].Allocation,
  ]);

  const storeUsersMapInLocalStorage = (data) => {
    localStorage.setItem("userDetailsMap", JSON.stringify(data));
  };

  const editPopupRef = useRef(null);
  const setRowNext = (updatedData, updatedDataArr) => {
    if (props.row.length - 1 !== currentRow) {
      setIsProcessing(false);
      setCurrentRow(currentRow + 1);
      setAllocationArray([...props.row[currentRow + 1].Allocation]);
    } else {


      props.setCheckedRows(updatedData)
      props.setData(updatedDataArr);

      props.onCancel();
      setCurrentRow(0);
    }
  };
  const changeAllocationApi = async () => {
    try {
      console.log(
        JSON.stringify({
          id: props.row[currentRow]._id,
          Allocation: allocationArray,
        })
      );
      const updatedDataFiles = [...props.data];
      const response = await axios.post("/allocation/editAllocation", {
        id: props.row[currentRow]._id,
        type: props.row[currentRow].Pubcode,
        Allocation: allocationArray,
      });
      const updatedCheckedRows = response.data.file;
      const updatedCheckedRowsArray = [updatedCheckedRows];
      const dataIndex = updatedDataFiles.findIndex((item) => item._id === updatedCheckedRows._id);
      if(dataIndex !== -1){
        updatedDataFiles[dataIndex] = updatedCheckedRows;
      }
      setRowNext(updatedCheckedRowsArray, updatedDataFiles);
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("Unable to submit data to server");
      }
    }
  };
  const getData = async () => {
    try {
      const userDetailsCheck = localStorage.getItem("userDetailsMap");
      if (userDetailsCheck) {
        setUsers(JSON.parse(userDetailsCheck));
      } else {
        var user = await axios.post("/allocation/getUserAllocation", {
          role: ["Production", "Production InCharge", "Logistics"],
          isAllocation: true,
        });
        user = user.data.user;
        if (user?.length > 0) {
          user = user.map((value) => {
            return { value: value.email, label: value.email };
          });
          storeUsersMapInLocalStorage(user);
          setUsers(user);
        }
      }
    } catch (error) {
      // props.setError();
      console.error(error)
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleOutsideClick = (e) => {
    if (editPopupRef.current && !editPopupRef.current.contains(e.target)) {
      // props.setPopupState(false);
      props.onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);


  if (isError) {
    return (
      <ErrorPopup
        message={isError}
        isShowAction={true}
        action={() => {
          props.onCancel();
        }}
      />
    );
  }
  return users.length == 0 || isProcessing ? (
    <DataSubmittingPop />
  ) : (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-20 z-20"
        // onClick={() => {
        //   props.setPopupState(false);
        // }}
      ></div>
      <div className="w-4/5 fixed  px-8 py-10 h-c-6 overflow-x-auto bg-blue-50 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-30 rounded-lg" 
      ref={editPopupRef}>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-2xl font-bold mb-2">{props.row[currentRow].filename}</h1>
      </div>
        <div className="grid grid-cols-2 gap-4 max-h-96 overflow-y-auto">
          {allocationArray.map((v, index) => {
            console.log(v.member);
            console.log(
              users.filter((value) => {
                return v.member === value.value;
              })[0]
            );
            console.log(allocationArray);
            console.log(v.stage)
            return (
              <div className="shadow-inner bg-white m-5 rounded-lg p-4">
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-semibold">Allocation #{index + 1}</h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    onClick={() => {
                      allocationArray.splice(index, 1);
                      setAllocationArray([...allocationArray]);
                    }}
                    className="cursor-pointer"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#fc3d03"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                  </svg>
                </div>
                <div className="flex mt-10">
                  <p className="w-48">Member</p>{" "}
                  <Select
                    className="w-full"
                    defaultValue={(() =>
                      users.filter(
                        (value) => v.member === value.value
                      )[0])()}
                    closeMenuOnSelect={false}
                    onChange={(e) => {
                      //     console.log(e);
                      const tempArray = [...allocationArray];
                      tempArray[index].member = e.value;
                      setAllocationArray([...tempArray]);
                      // setSelectedUser(e);
                    }}
                    components={animatedComponents}
                    isMulti={false}
                    options={users}
                  />
                </div>
                <div className="flex mt-10">
                  <p className="w-48">Page</p>{" "}
                  <input
                    value={v.pages}
                    onChange={(e) => {
                      const tempArray = [...allocationArray];
                      tempArray[index].pages = e.target.value;
                      setAllocationArray([...tempArray]);
                    }}
                    placeholder="Enter Page"
                    className="shadow-lg rounded-lg p-4 w-full"
                    type="text"
                  ></input>
                </div>
                {/* <div className="flex mt-10">
                  <p className="w-48">Start</p>{" "}
                  <select
                    value={v.start}
                    className="border-2 border-blue-500 rounded-md
                    outline-none
                    uppercase text-sm"
                    onChange={(e) => {
                      const tempArray = [...allocationArray];
                      tempArray[index].start = e.target.value;
                      setAllocationArray([...tempArray]);
                    }}
                  >
                    {(() => {
                      const tempArray = [true, false];
                      return tempArray.map((opt, i) => (
                        <option key={i + "-inner"} value={opt.toString()}>
                          {opt.toString().toUpperCase()}
                        </option>
                      ));
                    })()}
                  </select>
                </div> */}
                {/* <div className="flex mt-10">
                  <p className="w-48">Complete</p>{" "}
                  <select
                    value={v.complete}
                    className="border-2 border-blue-500 rounded-md
                    outline-none
                    uppercase text-sm"
                    onChange={(e) => {
                      const tempArray = [...allocationArray];
                      tempArray[index].complete = e.target.value;
                      setAllocationArray([...tempArray]);
                    }}
                  >
                    {(() => {
                      const tempArray = [true, false];
                      return tempArray.map((opt, i) => (
                        <option key={i + "-inner"} value={opt.toString()}>
                          {opt.toString().toUpperCase()}
                        </option>
                      ));
                    })()}
                  </select>
                </div> */}
                <div className="flex mt-10">
                  <p className="w-48">Stage</p>{" "}
                  <select
                    value={v.stage}
                    className="border-2 border-blue-500 rounded-md
                    outline-none
                    uppercase text-sm"
                    onChange={(e) => {
                      const tempArray = [...allocationArray];
                      tempArray[index].stage = e.target.value;
                      setAllocationArray([...tempArray]);
                    }}
                  >
                    {(() => {
                      const tempArray = [...Status.statusStageArray];
                      console.log(tempArray)
                      // tempArray.splice(0, 1);
                      return tempArray.map((opt, i) => (
                        <option key={i + "-inner"} value={opt}>
                          {opt.toUpperCase()}
                        </option>
                      ));
                    })()}
                  </select>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-8 flex justify-center items-center">
          <Button
            className="bg-blue-500 mx-2"
            onClick={() => {
              changeAllocationApi();
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              props.onCancel();
            }}
            className="bg-red-500 mx-2"
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
});

export default EditAllocationPopup;
