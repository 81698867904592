import React, { useState } from "react";
import Button from "../Button/button";
import axios from "../../service/api";

const TextPopup = (props) => {
  const [emailText, setEmailText] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleInputChange = (e) => {
    setEmailText(e.target.value);
  };

  const handleSubmitData = async () => {
    try {
      const response = await axios.post("/files/saveFiles", {
        emailText: emailText,
      });

      console.log("Server Response:", response.data);
      setShowConfirmation(true);
      //   props.closePopUp();
      setTimeout(() => {
        props.closePopUp();
      }, 2000);
    } catch (error) {
      setShowError(true);
      //   props.setError();
      console.error("Error submitting data:", error);
    }
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleErrorClose = () => {
    setShowError(false);
  };

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-20 z-10"
        onClick={() => props.closePopUp()}
      ></div>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="w-96 bg-white rounded-lg shadow-md p-8">
          <h2 className="text-2xl font-bold mb-4">Paste Email Text</h2>
          <textarea
            rows="10"
            // cols="50"
            placeholder="Paste input email text here..."
            className="w-full p-4 border border-gray-300 rounded-md mb-4 focus:outline-none focus:border-blue-500"
            value={emailText}
            onChange={handleInputChange}
          />
          <div className="flex justify-end">
            <Button
              className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
              onClick={handleSubmitData}
            >
              Submit
            </Button>
            <Button
              onClick={() => props.closePopUp()}
              className="bg-red-500 text-white px-4 py-2 rounded-md"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="w-64 bg-white rounded-lg shadow-md p-4 text-center">
            <p className="text-green-500 text-lg font-semibold mb-2">
              File Added Successfully!
            </p>
            <Button
              onClick={handleConfirmationClose}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Close
            </Button>
          </div>
        </div>
      )}
      {showError && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="w-64 bg-white rounded-lg shadow-md p-4 text-center">
            <p className="text-red-500 text-lg font-semibold mb-2">
              Duplicate filename found, skipping save.
            </p>
            <Button
              onClick={handleErrorClose}
              className="bg-red-500 text-white px-4 py-2 rounded-md"
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default TextPopup;
