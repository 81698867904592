import React, { useState, useEffect, useRef } from "react";

import Layout from "../../Layout/Layout";
import DataSet from "../AllocationList/RepubData";
import DataSubmittingPop from "../../Popup/DataSubmitPopup";
import axios from "../../../service/api";
import { useNavigate } from "react-router-dom";
import Button from "../../Button/button";
import Status from "../../../utils/Stage";
import ProtectRoute from "../../../utils/protectRoute";
import { getToken, getRole, logout, getUser } from "../../../service/auth";
import { useNotification } from "../../Context/NotificationContext";
import ErrorPoup from "../../Popup/ErrorPopup";
import io from "socket.io-client";
import { debounce } from "lodash";
import { RingLoader } from "react-spinners";

const AdminPanel = React.memo(() => {
  const navigate = useNavigate();
  const { showPushNotification } = useNotification();
  const [showPopUp, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [totalItem, setTotalItem] = useState();
  const [showError, setShowError] = useState();
  const [searchParams, setSearchParams] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [datas, setData] = useState();
  const [allCount, setAllCount] = useState();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState();
  const [perPage, setPerPage] = useState(10);
  const [pageType, setPageType] = useState("All");
  const isFirstTime = useRef(true);
  const [options, setOptions] = useState();
  const [showLoading, setShowLoading] = useState(true);
  const userId = getUser().id;

  const optionsStorageKey = "allocationFilesOptions";

  const getStoredOptions = () => {
    const storedOptions = localStorage.getItem(optionsStorageKey);
    if (storedOptions) {
      return JSON.parse(storedOptions);
    }
    return null;
  };

  const setOptionsInStorage = (options) => {
    localStorage.setItem(optionsStorageKey, JSON.stringify(options));
  };

  const debouncedCallApi = useRef(
    debounce((currentPage, type, search) => {
      callApi(currentPage, type, search);
    }, 1000)
  ).current;

  useEffect(() => {
    const socket = io.connect(
      axios.defaults.baseURL
      // transports: ['websockets']
    );
    socket.on("newMail", (response) => {
      console.log(response)
      debouncedCallApi(page, "All"); 
      // setData((prevFileList) => [response, ...prevFileList]);
      showPushNotification(
        "New Repub File Received", {
        body: `${response.filename} received for waiting allocation`,
        icon: "/athena-icon.png",
      });
    });

    socket.on("fileDeliveredNotify", (response) => {
      debouncedCallApi(page, "All"); // Call the API to fetch the latest data
      showPushNotification("Repub File Delivered",{
        body: `${response.filename} has been delivered`,
          icon: '/athena-icon.png'
        });
      })

    // if (!datas) {
    //   debouncedCallApi(page, "All");
    //   console.log("hi");
    // }
    if (isFirstTime.current) {
      localStorage.removeItem(optionsStorageKey);
      const storedOptions = getStoredOptions();
      if (storedOptions) {
        setOptions(storedOptions);
      } else {
        callAPIForOptions();
      }
      debouncedCallApi(page, "All");
      isFirstTime.current = false;
    }
    return () => {
      socket.disconnect(); // Disconnect the socket when the component unmounts
    };
  }, [isFirstTime]);

  const callApi = async (currentPage, type, search = "") => {
    try {
      setShowProgress(true);
      // setShowLoading(true)
      searchParams !== "" ? setShowLoading(true) : setTimeout(() => {
        setShowLoading(false); // Show the loading message
      }, 3000);
      const res = await axios.post("/files/allocationFiles", {
        is_allocation: true,
        page: currentPage,
        perPage: perPage,
        filterString: type,
        searchParams: search,
      });
      setPage(currentPage);
      setPageType(type);
      setSearchParams(search);
      // setData();
      setData(res.data.files);
      // setTimeout(() => {
      //   // setIsDataAvailable(/* Check if data is available */);
      //   setShowLoading(false);
      // }, 3000);
      setShowLoading(false); // Hide the loading message
      if (res.data.files.length === 0 && search) {
        setShowError(true);
      }
      setTotalItem(res.data.totalItems);
      setAllCount(res.data.allCount);

      let tempString = [];
      for (let key in res.data.counts) {
        if (res.data.counts.hasOwnProperty(key)) {
          let val = res.data.counts[key];

          if (val > 0) {
            tempString.push(`${key}(${val})`);
          }
        }
      }
      console.log(tempString.join("|"));
      setStatus(tempString.join("|"));

      // callAPIForOptions(res.data.files);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          logout(() =>
            typeof window !== `undefined` ? navigate("/") : (() => {})()
          );
        } else {
          setMessage(err.response.data.message);
          setShowProgress(false);
          setShowPopup(true);
        }
      } else {
        console.log("hello");
        setMessage("Unable to reach to server");
        setShowProgress(false);
        setShowPopup(true);
      }
    }
  };
  const callAPIForOptions = async (dataTemp) => {
    try {
      const res = await axios.post("/options/getOptions", {
        type: "allocation",
      });
      setShowProgress(false);
      // setOptions();
      setOptions(res.data.options);
      setOptionsInStorage(res.data.options)
    } catch (err) {
      setShowProgress(false);
      if (err.response) {
        if (err.response.status === 401) {
          setShowPopup(true);
          setMessage(err.response.data.message);
        }
        if (err.response.status === 404) {
          setOptions([2, 4, 5, 6, 7, 8, 26, 34, 36]);
          const temp = [...Object.keys(dataTemp[0])];
          setOptions([
            temp.indexOf("Received client"),
            temp.indexOf("Amazon"),
            temp.indexOf("Project"),
            temp.indexOf("Status"),
            temp.indexOf("Category"),
          ]);
          setOptionsInStorage([
            temp.indexOf("Received client"),
            temp.indexOf("Amazon"),
            temp.indexOf("Project"),
            temp.indexOf("Status"),
            temp.indexOf("Category"),
          ]); // Store default options in local storage
        } else {
          setShowPopup(true);
          setMessage(err.response.data.message);
        }
      } else {
        setShowPopup(true);
        console.log("hello");
        setMessage("Unable to reach to server");
      }
    }
  };
  if (!getRole()) {
    logout(() =>
      typeof window !== `undefined` ? navigate("/") : (() => {})()
    );

    return <></>;
  } else if (getRole() === "Production") {
    logout(() =>
      typeof window !== `undefined` ? navigate("/production") : (() => {})()
    );

    return <></>;
  }

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    debouncedCallApi(1, pageType, searchText);
  };

  return (
    <ProtectRoute>
      <Layout
        isStakeholders={getRole() === "Stakeholders"}
        isLogistics={getRole() === "Logistics"}
        isTL={getRole() === "Production InCharge"}
      >
        {showLoading ? (
          <div className="w-full mt-5 bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
            <RingLoader color="#000" size={50} />
            <span className="ml-2">Loading data...</span>
          </div>
        ) : datas ? (
         
            <DataSet
              data={datas}
              title="Files"
              needPaddedRows={true}
              currentPage={page}
              actions={status}
              changePage={(number) => {
                callApi(number, pageType);
              }}
              currentPageType={pageType}
              setData={setData}
              allCount={allCount}
              onTypeChange={(type) => callApi(1, type)}
              onSearch={handleSearch}
              searchText={searchText}
              perPage={perPage}
              requiredManager={4}
              readOnlyStatus={false}
              searchParams={searchParams}
              totalItem={totalItem}
              addUrl="/files/f"
              editUrl="/files/editFile"
              refreshList={() => callApi(1, "All")}
              // cols={[...Object.keys(datas[0])]}
              cols={datas.length ? [...Object.keys(datas[0])]: []}
              visibleColumnIndices={options}
              inputs={datas.length ? Object.keys(datas[0]) : []}
              unsortableFields={["Download"]}
            />
          )  : (
          <div className="w-full mt-5 bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
              {/* Error: Unable to fetch data */}
              Please wait to reload the data.
            </div>
        )}
      </Layout>
      {showPopUp ? <ErrorPoup message={message} /> : <></>}
      {/* {showProgress ? <DataSubmittingPop /> : <></>} */}
    </ProtectRoute>
  );
});

export default AdminPanel;
