import React from "react";
import Nav from "./LayoutNav";
// import NFCard from "./notificationcard";

const Layout = (props) => {
  return (
    <>
      <main
        className="grid min-h-screen"
        style={{ gridTemplateColumns: "13rem 1fr"}}
      >
        <Nav
            isStakeholders={props.isStakeholders}
            isAdmin={props.isAdmin}
            isLogistics={props.isLogistics}
            isTL={props.isTL}
            isProd={props.isProd}
          // admin={props.admin}
          // isProd={props.isProd}
          // users={props.users}
          // ftp={props.ftp}
          // pub={props.pub}
          // dash={props.dash}
        />
        <div className="bg-[#F2F6FF] overflow-hidden">{props.children}</div>
      </main>
      </>
  );
};

export default Layout;
