import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout/Layout";
import DataSet from "../../components/Allocation/AllocationList/RepubData";
import axios from "../../service/api";
import { logout } from "../../service/auth";
import ProtectRoute from "../../utils/protectRoute";
import ErrorPoup from "../../components/Popup/ErrorPopup";
import { useNavigate } from "react-router-dom";
import { getRole, getUser } from "../../service/auth";
import { useNotification } from "../../components/Context/NotificationContext";
import { debounce } from "lodash";
import { RingLoader } from "react-spinners";
import io from "socket.io-client";

const Production = React.memo(() => {
  const navigate = useNavigate();
  const { setNotifications, showPushNotification } = useNotification();
  const [datas, setData] = useState([]);
  const [totalItem, setTotalItem] = useState();
  const [allCount, setAllCount] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [status, setStatus] = useState();
  const [showPopUp, setShowPopup] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [message, setMessage] = useState("");
  const [pageType, setPageType] = useState("");
  const [options, setOptions] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchParams, setSearchParams] = useState("");
  const [defaultStage, setDefaultStage] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [typeChange, setTypeChange] = useState([]);
  const isFirstTime = useRef(true);

  const optionsStorageKey = "productionFilesOptions";

  const getStoredOptions = () => {
    const storedOptions = localStorage.getItem(optionsStorageKey);
    if (storedOptions) {
      return JSON.parse(storedOptions);
    }
    return null;
  };

  const setOptionsInStorage = (options) => {
    localStorage.setItem(optionsStorageKey, JSON.stringify(options));
  };

  const debouncedCallApi = useRef(
    debounce((currentPage, type, search) => {
      callApi(currentPage, type, search);
    }, 1000)
  ).current;

  useEffect(() => {
    const socket = io.connect(axios.defaults.baseURL);

    socket.on("newSimpleAllocation", (response) => {
      if (
        response.Allocation.some(
          (allocation) => allocation.member === getUser()?.email
        )
      ) {
        const fileName = response.filename || "N/A";
        const priority =
          response.Priority === "True" ? "High Priority" : "Low Priority";
        const turnaroundTime = response["TAT"] || "N/A";

        // const title = `New File Received - ${fileName}`;
        // const body = `Priority: ${priority}, TAT: ${turnaroundTime}`;
        const allocationArray = response.Allocation;
        let redirectStage = allocationArray[allocationArray.length - 1].stage;
        if (redirectStage === "Production Assign") {
          redirectStage = "Production";
        } else if (redirectStage === "QA Assign") {
          redirectStage = "QA";
        } else if (redirectStage === "Final Assign") {
          redirectStage = "Final";
        }

        debouncedCallApi(page, redirectStage); // Call the API to fetch the latest data
        showPushNotification(`New Repub File Received - ${fileName}`, {
          body: `Priority: ${priority}, TAT: ${turnaroundTime}`,
          icon: "/athena-icon.png",
          // url: response.result.url,
        });
      }
    });

    socket.on("newBulkAllocation", (response) => {
      if (
        response.Allocation.some(
          (allocation) => allocation.member === getUser()?.email
        )
      ) {
        const fileName = response.filename || "N/A";
        const priority =
          response.Priority === "True" ? "High Priority" : "Low Priority";
        const turnaroundTime = response["TAT"] || "N/A";

        const allocationArray = response.Allocation;
        let redirectStage = allocationArray[allocationArray.length - 1].stage;
        if (redirectStage === "Production Assign") {
          redirectStage = "Production";
        } else if (redirectStage === "QA Assign") {
          redirectStage = "QA";
        } else if (redirectStage === "Final Assign") {
          redirectStage = "Final";
        }

        // const title = `New File Received - ${fileName}`;
        // const body = `Priority: ${priority}, TAT: ${turnaroundTime}`;

        debouncedCallApi(page, redirectStage); // Call the API to fetch the latest data
        showPushNotification(`New Repub File Received - ${fileName}`, {
          body: `Priority: ${priority}, TAT: ${turnaroundTime}`,
          icon: "/athena-icon.png",
          // url: response.result.url,
        });
      }
      // const defaultValue = determineDefaultStage(response.countsArray);
      // setDefaultStage(defaultValue);
    });

    socket.on("newEditedAllocation", (response) => {
      if (
        response.Allocation.some(
          (allocation) => allocation.member === getUser()?.email
        )
      ) {
        const allocationArray = response.Allocation;
        let redirectStage = allocationArray[allocationArray.length - 1].stage;
        if (redirectStage === "Production Assign") {
          redirectStage = "Production";
        } else if (redirectStage === "QA Assign") {
          redirectStage = "QA";
        } else if (redirectStage === "Final Assign") {
          redirectStage = "Final";
        }

        // const title = `New File Received - ${fileName}`;
        // const body = `Priority: ${priority}, TAT: ${turnaroundTime}`;

        debouncedCallApi(page, redirectStage); // Call the API to fetch the latest data
      }
    });
    return () => {
      socket.disconnect(); // Disconnect the socket when the component unmounts
    };
  }, []);

  useEffect(() => {
    if (defaultStage) {
      debouncedCallApi(page, defaultStage);
    } else {
      // If defaultStage is not set, you can choose a fallback or default value here
      // Example: setDefaultStage("Production");
      setDefaultStage("Production");
    }
  }, [defaultStage]);

  useEffect(() => {
    if (isFirstTime.current) {
      localStorage.removeItem(optionsStorageKey);
      const storedOptions = getStoredOptions();
      if (storedOptions) {
        setOptions(storedOptions);
      } else {
        callAPIForOptions();
      }
      // callAPIForOptions();
      debouncedCallApi(page, defaultStage);
      isFirstTime.current = false;
    }
  }, [isFirstTime]);

  // if (isFirstTime.current) {
  //   const storedOptions = getStoredOptions();
  //   if (storedOptions) {
  //     setOptions(storedOptions);
  //   } else {
  //     callAPIForOptions();
  //   }
  //   debouncedCallApi(page, defaultStage);
  //   isFirstTime.current = false;
  // }

  // debouncedCallApi(page, "All");

  //   return () => {
  //     socket.disconnect(); // Disconnect the socket when the component unmounts
  //   };
  // }, [isFirstTime]);

  // const determineDefaultStage = (countsArray) => {
  //   let defaultStage = "Production"; // Set a default fallback stage
  //   let maxCount = 0;

  //   countsArray.forEach((entry) => {
  //     for (let key in entry) {
  //       if (entry.hasOwnProperty(key)) {
  //         const val = entry[key];
  //         if (val > maxCount) {
  //           maxCount = val;
  //           defaultStage = key; // Update the default stage
  //         }
  //       }
  //     }
  //   });

  //   return defaultStage;
  // };

  const callApi = async (currentPage, type, search = "") => {
    setShowProgress(true);
    // setShowLoading(true);
    searchParams !== ""
      ? setShowLoading(true)
      : setTimeout(() => {
          setShowLoading(false); // Show the loading message
        }, 3000);
    axios
      .post("/files/allocationFiles", {
        is_allocation: false,
        page: currentPage,
        perPage: perPage,
        filterString: type,
        searchParams: search,
      })
      .then((res) => {
        setPage(currentPage);
        setPageType(type);
        setSearchParams(search);
        setData(res.data.files);
        // setTimeout(() => {
        //   // setIsDataAvailable(/* Check if data is available */);
        //   setShowLoading(false);
        // }, 2000);
        setShowLoading(false);
        setTotalItem(res.data.totalItems);
        setAllCount(res.data.allCount);
        setDefaultStage(res.data.defaultStage);

        let tempString = [];
        for (let i = 0; i < res.data.countsArray.length; i++) {
          const entry = res.data.countsArray[i];

          for (let key in entry) {
            if (entry.hasOwnProperty(key)) {
              let val = entry[key];

              // if (val > 0) {
              if (key === "Production") {
                tempString.push(`Production(${val})`);
              } else if (key === "QA") {
                tempString.push(`QA(${val})`);
              } else if (key === "Final") {
                tempString.push(`Final(${val})`);
              }
              // }
            }
          }
        }
        const statusString = tempString.join("|");
        console.log(statusString);
        setStatus(statusString);

        // callAPIForOptions(res.data.files);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            logout(() =>
              typeof window !== `undefined` ? navigate("/") : (() => {})()
            );
          } else {
            setMessage(err.response.data.message);
            setShowProgress(false);
            setShowPopup(true);
          }
        } else {
          console.log("hello");
          setMessage("Unable to reach to server");
          setShowProgress(false);
          setShowPopup(true);
        }
      });
  };

  const callAPIForOptions = async (dataTemp) => {
    try {
      const res = await axios.post("/options/getOptions", {
        type: "production",
      });
      setShowProgress(false);
      // setOptions();
      setOptions(res.data.options);
      setOptionsInStorage(res.data.options);
      // if (res.data.options) {
      //   if (res.data.options.length > 0) {
      //   setOptions(res.data.options);
      //   } else {
      //     const temp = [...Object.keys(dataTemp[0])]
      //       setOptions([temp.indexOf("Received client"), temp.indexOf("Amazon"), temp.indexOf("Project"), temp.indexOf("Status"), temp.indexOf("Category")]);
      //   }
      // }
    } catch (err) {
      setShowProgress(false);
      if (err.response) {
        if (err.response.status === 401) {
          logout(() =>
            typeof window !== `undefined` ? navigate("/") : (() => {})()
          );
          setShowPopup(true);
          setMessage(err.response.data.message);
        }
        if (err.response.status === 404) {
          setOptions([2, 4, 5, 6, 7, 8, 26, 34, 37]);
          const temp = [...Object.keys(dataTemp[0])];
          setOptions([
            temp.indexOf("Received client"),
            temp.indexOf("Amazon"),
            temp.indexOf("Project"),
            temp.indexOf("Status"),
            temp.indexOf("Category"),
          ]);
          setOptionsInStorage([
            temp.indexOf("Received client"),
            temp.indexOf("Amazon"),
            temp.indexOf("Project"),
            temp.indexOf("Status"),
            temp.indexOf("Category"),
          ]); // Store default options in local storage
          //alert("hrllo")
        } else {
          setShowPopup(true);
          setMessage(err.response.data.message);
        }
      } else {
        setShowPopup(true);
        console.log("hello");
        setMessage("Unable to reach to server");
      }
    }
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    debouncedCallApi(1, pageType, searchText);
  };

  return (
    <ProtectRoute>
      <Layout
        isTL={getRole() === "Production InCharge"}
        isProd={getRole() === "Production"}
        isLogistics={getRole() === "Logistics"}
      >
        {showLoading ? (
          <div className="w-full mt-5 bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
            <RingLoader color="#000" size={50} />
            <span className="ml-2">Loading data...</span>
          </div>
        ) : datas !== undefined ? (
          datas.length ? (
            <DataSet
              readOnlyStatus={true}
              data={datas}
              // pageType={onTypeChange}
              currentPage={page}
              perPage={perPage}
              actions={status}
              changePage={(number) => {
                callApi(number, pageType, "");
              }}
              currentPageType={pageType}
              setData={setData}
              onSearch={handleSearch}
              searchText={searchText}
              searchParams={searchParams}
              title="Allocated Files"
              allCount={allCount}
              onTypeChange={(type) => callApi(1, type, "")}
              requiredManager={6}
              cols={[...Object.keys(datas[0])]}
              totalItem={totalItem}
              actionCenter={1}
              needPaddedRows={true}
              visibleColumnIndices={options}
            />
          ) : (
            <div className="w-full mt-5 font-semibold bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
              No Repub data found
            </div>
          )
        ) : (
          <div className="w-full mt-5 bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
            Please wait to reload the repub data
          </div>
        )}
      </Layout>
      {showPopUp ? <ErrorPoup message={message} /> : <></>}
    </ProtectRoute>
  );
});

export default Production;
