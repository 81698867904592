export default class UserRole {
    static userRoleArray = [
      "Stakeholders",
      "Admin",
      "Logistics",
      "Production InCharge",
      // "supervisor",
      "Production",
    ];
    static userReportingArray = [
      "Akshatt K Pandey",
      // "Shishpiya",
      "Amar Chand Gupta"
    ]
  }
  