import React, { useState, useEffect } from "react";
import axios from "../../service/api";
import Layout from "../../components/Layout/Layout";
import Button from "../../components/Button/button";
import Select from "react-select";
import { getRole } from "../../service/auth";
import { useNavigate } from "react-router-dom";
import "./LoadingSpinner.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function AddEmployee() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState({
    userData: {
      empId: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      fatherName: "",
      motherName: "",
      previousExperience: "",
      presentAddress: "",
      presentAddressPin: "",
      city: "",
      sameAsPresentAddress: false,
      phoneNumber: "",
      emergencyNumber: "",
      reportingManager: "",
      role: "",
      dob: "",
      gender: "",
      bloodGroup: "",
      aadharNumber: "",
      panNumber: "",
      uanNumber: "",
      esicNumber: "",
      joiningDate: "",
      bankName: "",
      accountNumber: "",
      ifscCode: "",
      maritalStatus: "",
      spouseName: "",
      highestQualification: "",
      degree: "",
      university: "",
      yearOfPassout: "",
      totalExperience: "",
      permanentAddress: "",
      permanentAddressPin: "",
      permanentCity: "",
    },
    profilePic: null,
    aadharPic: null,
    panPic: null,
    bloodGroupPic: null,
    highestQualificationPic: null,
    passBookPic: null,
    cancelledChequePic: null,
  });
  
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "previousExperience" || id === "totalExperience") {
      setEmployeeDetails((prevData) => ({
        ...prevData,
        userData: {
          ...prevData.userData,
          [id]: parseFloat(value) || 0,
        },
      }));
    } else {
      setEmployeeDetails((prevData) => ({
        ...prevData,
        userData: {
          ...prevData.userData,
          [id]: value,
        },
      }));
    }
    setErrorMessage(null);
  };

  console.log('Initial State:', employeeDetails);



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      userData: {
        ...prevDetails.userData,
        sameAsPresentAddress: checked,
        permanentAddress: checked
          ? prevDetails.userData.presentAddress
          : prevDetails.userData.permanentAddress,
        permanentAddressPin: checked
          ? prevDetails.userData.presentAddressPin
          : prevDetails.userData.permanentAddressPin,
        permanentCity: checked
          ? prevDetails.userData.city
          : prevDetails.userData.permanentCity,
      },
    }));
  };

  const handleProfilePicChange = (e) => {
    const selectedFile = e.target.files[0];
    setEmployeeDetails((prevData) => ({
      ...prevData,
      profilePic: selectedFile,
    }));
    setErrorMessage(null);
  };

  const handleAadharPicChange = (e) => {
    const selectedFile = e.target.files[0];
    setEmployeeDetails((prevData) => ({
      ...prevData,
      aadharPic: selectedFile,
    }));
    setErrorMessage(null);
  };

  const handlePanCardPicChange = (e) => {
    const selectedFile = e.target.files[0];
    setEmployeeDetails((prevData) => ({ ...prevData, panPic: selectedFile }));
    setErrorMessage(null);
  };

  const handleBloodGroupPicChange = (e) => {
    const selectedFile = e.target.files[0];
    setEmployeeDetails((prevData) => ({
      ...prevData,
      bloodGroupPic: selectedFile,
    }));
    setErrorMessage(null);
  };

  const handleQualificationPicChange = (e) => {
    const selectedFile = e.target.files[0];
    setEmployeeDetails((prevData) => ({
      ...prevData,
      highestQualificationPic: selectedFile,
    }));
    setErrorMessage(null);
  };

  const handlePassBookPicChange = (e) => {
    const selectedFile = e.target.files[0];
    setEmployeeDetails((prevData) => ({
      ...prevData,
      passBookPic: selectedFile,
    }));
    setErrorMessage(null);
  };

  const handleChequePicChange = (e) => {
    const selectedFile = e.target.files[0];
    setEmployeeDetails((prevData) => ({
      ...prevData,
      cancelledChequePic: selectedFile,
    }));
    setErrorMessage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("profilePic", employeeDetails.profilePic);
      formData.append("aadharPic", employeeDetails.aadharPic);
      formData.append("panPic", employeeDetails.panPic);
      formData.append("bloodGroupPic", employeeDetails.bloodGroupPic);
      formData.append(
        "highestQualificationPic",
        employeeDetails.highestQualificationPic
      );
      formData.append("passBookPic", employeeDetails.passBookPic);
      formData.append("cancelledChequePic", employeeDetails.cancelledChequePic);
      formData.append("userData", JSON.stringify(employeeDetails.userData));
      const response = await axios.post(
        "/user/register",
        formData
        // { headers: {'Content-Type': 'multipart/form-data'} }
      );
      setEmployeeDetails(response.data);
      if (response.data.code === "OK") {
        setShowSuccessModal(true);
        // navigate("/manageEmployees"); // Redirect to employee list page after successful submission
      } else {
        setError(response.data); // Set error if needed
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.message
      ) {
        setError(error.response?.data);
        setErrorMessage(error.response.data.data.message);
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false); // Set loading state to false when request completes (success or error)
    }
  };


  const handleModalCloseAndRedirect = () => {
    setShowSuccessModal(false);
    navigate("/manageEmployees"); // Redirect to manageEmployees
  };

  const checkSameAsPresentAddress = employeeDetails.userData
    ? employeeDetails.userData.sameAsPresentAddress
    : employeeDetails.sameAsPresentAddress;

  return (
    <Layout isAdmin={getRole() === "Admin" || getRole() === "Stakeholders"}>
      <div className="w-full mt-0 bg-white shadow-2xl h-14 font-bold flex justify-center items-center">
        Add Employee Details
      </div>
      <div className="mt-4 ml-8">
        <form
          onSubmit={handleSubmit}
          disabled={isLoading}
          class="w-full max-w-6xl"
        >
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="empId"
              >
                Employee Id
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="empId"
                type="text"
                value={employeeDetails.empId}
                onChange={handleChange}
                placeholder="Enter employee Id"
                autoComplete="off" 
              />
              {errorMessage && error.data.type === "Wrong empId" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="firstName"
              >
                First Name
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="firstName"
                type="text"
                value={employeeDetails.firstName}
                onChange={handleChange}
                placeholder="Enter first name"
              />
              {errorMessage && error.data.type === "Wrong firstName" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="lastName"
              >
                Last Name
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none =block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="lastName"
                type="text"
                value={employeeDetails.lastName}
                onChange={handleChange}
                placeholder="Enter last name"
              />
              {errorMessage && error.data.type === "Wrong lastName" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="email"
              >
                Email Id
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="email"
                type="email"
                value={employeeDetails.email}
                onChange={handleChange}
                placeholder="Enter email Id"
              />
              {errorMessage && error.data.type === "Wrong email" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="password"
              >
                Password
                <span className="text-red-500 text-medium">*</span>
              </label>
              <div className="relative">
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={employeeDetails.password}
                  onChange={handleChange}
                  placeholder="Enter Athena Password"
                  autoComplete="off"
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </div>
              </div>
              {errorMessage && error.data.type === "Wrong password" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>

            <div class="w-full md:w-2/6 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="presentAddress"
              >
                Present Address
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="presentAddress"
                type="address"
                value={employeeDetails.presentAddress}
                onChange={handleChange}
                placeholder="Enter Address"
              />
              {errorMessage && error.data.type === "Wrong presentAddress" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="city"
              >
                City
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="city"
                type="city"
                value={employeeDetails.city}
                onChange={handleChange}
                placeholder="Enter City"
              />
              {/* {errorMessage && error.data.type === "Wrong city" && (
                <div className="error-message">{errorMessage}</div>
              )} */}
            </div>
            <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="presentAddressPin"
              >
                Pincode
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="presentAddressPin"
                type="pin"
                value={employeeDetails.presentAddressPin}
                onChange={handleChange}
                placeholder="Enter Pin"
              />
              {errorMessage &&
                error.data.type === "Wrong presentAddressPin" && (
                  <div className="error-message">{errorMessage}</div>
                )}
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <div className="flex items-center">
                <input
                  className="mr-2 mb-2 ml-2 leading-tight"
                  id="sameAsPresentAddress"
                  type="checkbox"
                  checked={employeeDetails.sameAsPresentAddress}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="sameAsPresentAddress"
                >
                  Same as Present Address
                </label>
              </div>
            </div>
            {!checkSameAsPresentAddress && (
              <>
                <div className="w-full md:w-2/6 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="permanentAddress"
                  >
                    Permanent Address
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="permanentAddress"
                    type="address"
                    value={employeeDetails.permanentAddress}
                    onChange={handleChange}
                    placeholder="Enter Permanent Address"
                  />
                  {errorMessage &&
                    error.data.type === "Wrong permanentAddress" && (
                      <div className="error-message">{errorMessage}</div>
                    )}
                </div>
                <div className="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="permanentAddressPin"
                  >
                    Permanent Zip
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="permanentAddressPin"
                    type="pin"
                    value={employeeDetails.permanentAddressPin}
                    onChange={handleChange}
                    placeholder="Enter Permanent Pin"
                  />
                  {errorMessage &&
                    error.data.type === "Wrong permanentAddressPin" && (
                      <div className="error-message">{errorMessage}</div>
                    )}
                </div>
                <div className="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="permanentCity"
                  >
                    Permanent City
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="permanentCity"
                    type="city"
                    value={employeeDetails.permanentCity}
                    onChange={handleChange}
                    placeholder="Enter Permanent City"
                  />
                  {/* {errorMessage &&
                    error.data.type === "Wrong permanentCity" && (
                      <div className="error-message">{errorMessage}</div>
                    )} */}
                </div>
              </>
            )}
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="phoneNumber"
              >
                Phone Number
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="phoneNumber"
                type="text"
                value={employeeDetails.phoneNumber}
                onChange={handleChange}
                placeholder="Enter phone number"
              />
              {errorMessage && error.data.type === "Wrong phoneNumber" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="emergencyNumber"
              >
                Emergency Number
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="emergencyNumber"
                type="text"
                value={employeeDetails.emergencyNumber}
                onChange={handleChange}
                placeholder="Enter phone number"
              />
              {errorMessage && error.data.type === "Wrong emergencyNumber" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="dob"
              >
                Date of Birth
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="dob"
                type="date"
                value={employeeDetails.dob}
                onChange={handleChange}
                // placeholder="Enter phone number"
              />
              {errorMessage && error.data.type === "Wrong dob" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
              <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="gender"
              >
                Gender
                <span className="text-red-500 text-medium">*</span>
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="gender"
                  value={employeeDetails.gender}
                  onChange={handleChange}
                >
                  <option>Select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                  {/* <option>Others</option> */}
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {errorMessage && error.data.type === "Wrong gender" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
               <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="fatherName"
              >
                Father Name
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="fatherName"
                type="text"
                value={employeeDetails.fatherName}
                onChange={handleChange}
                placeholder="Enter father name"
              />
              {errorMessage && error.data.type === "Wrong fatherName" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="motherName"
              >
                Mother Name
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="motherName"
                type="text"
                value={employeeDetails.motherName}
                onChange={handleChange}
                placeholder="Enter mother name"
              />
              {errorMessage && error.data.type === "Wrong motherName" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="maritalStatus"
              >
                Marital Status
                <span className="text-red-500 text-medium">*</span>
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="maritalStatus"
                  value={employeeDetails.maritalStatus}
                  onChange={handleChange}
                >
                  <option>Select Marital Status</option>
                  <option>Single</option>
                  <option>Married</option>
                  <option>Divorced</option>
                  <option>Seperated</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {errorMessage && error.data.type === "Wrong maritalStatus" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="spouseName"
              >
                Spouse Name
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="spouseName"
                type="text"
                required={false}
                value={employeeDetails.spouseName}
                onChange={handleChange}
                placeholder="Enter spouse name"
              />
            </div>
            {/* Add Blood Group */}
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0 mt-2">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="bloodGroup"
              >
                Blood Group
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bloodGroup"
                type="text"
                value={employeeDetails.bloodGroup}
                onChange={handleChange}
                placeholder="Enter blood group"
              />
              {errorMessage && error.data.type === "Wrong bloodGroup" && (
                <div className="error-message">{errorMessage}</div>
              )}
              <label
                htmlFor="blood_group"
                className="flex items-center space-x-2 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <span className="text-gray-600">
                  {employeeDetails.bloodGroupPic
                    ? `${employeeDetails.bloodGroupPic.name}`
                    : ""}
                </span>
              </label>
              <input
                className="hidden"
                type="file"
                name="bloodGroupPic"
                // accept="image/*"
                accept=".pdf, .docx, .png, .jpeg, .jpg"
                id="blood_group"
                onChange={handleBloodGroupPicChange}
              />
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="role"
              >
                role
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="role"
                  value={employeeDetails.role}
                  onChange={handleChange}
                >
                  <option>Select Role</option>
                  <option>Stakeholders</option>
                  <option>Admin</option>
                  <option>Logistics</option>
                  <option>Production InCharge</option>
                  <option>Production</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {errorMessage && error.data.type === "Wrong role" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div> 
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="reportingManager"
              >
                Reporting Manager
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="reportingManager"
                  value={employeeDetails.reportingManager}
                  onChange={handleChange}
                >
                  <option>Select Reporting Manager</option>
                  <option>Akshatt K Pandey</option>
                  <option>Amar Chand Gupta</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {errorMessage && error.data.type === "Wrong reportingManager" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div> 
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="joiningDate"
              >
                Joining Date
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="joiningDate"
                type="date"
                value={employeeDetails.joiningDate}
                onChange={handleChange}
                // placeholder="Enter blood group"
              />
              {errorMessage && error.data.type === "Wrong joiningDate" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="previousExperience"
              >
                Previous Experience
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="previousExperience"
                type="text"
                value={employeeDetails.previousExperience}
                onChange={handleChange}
                placeholder="Enter Previous Experience"
              />
              {errorMessage &&
                error.data.type === "Wrong previousExperience" && (
                  <div className="error-message">{errorMessage}</div>
                )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="totalExperience"
              >
                Total Experience
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="totalExperience"
                type="text"
                value={employeeDetails.totalExperience}
                onChange={handleChange}
                placeholder="Enter total experience"
              />
              {errorMessage && error.data.type === "Wrong totalExperience" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="highestQualification"
              >
                Highest Qualification
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="highestQualification"
                type="text"
                value={employeeDetails.highestQualification}
                onChange={handleChange}
                placeholder="Enter Qualification"
              />
              {errorMessage &&
                error.data.type === "Wrong highestQualification" && (
                  <div className="error-message">{errorMessage}</div>
                )}
              <label
                htmlFor="qualification_pic"
                className="flex items-center space-x-2 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <span className="text-gray-600">
                  {employeeDetails.highestQualificationPic
                    ? `${employeeDetails.highestQualificationPic.name}`
                    : ""}
                </span>
              </label>
              <input
                className="hidden"
                type="file"
                name="highestQualificationPic"
                // accept="image/*"
                accept=".pdf, .docx, .png, .jpeg, .jpg"
                id="qualification_pic"
                onChange={handleQualificationPicChange}
              />
              {/* <input
                class="appearance-none block w-full text-gray-700 border rounded leading-tight"
                type="file"
                name="highestQualificationPic"
                accept="image/*"
                onChange={handleQualificationPicChange}
              /> */}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="yearOfPassout"
              >
                Year of passout
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="yearOfPassout"
                type="text"
                value={employeeDetails.yop}
                onChange={handleChange}
                placeholder="Enter passout year"
              />
              {errorMessage && error.data.type === "Wrong yearOfPassout" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="degree"
              >
                Degree
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="degree"
                type="text"
                value={employeeDetails.degree}
                onChange={handleChange}
                placeholder="Enter degree"
              />
              {/* {errorMessage && error.data.type === "Wrong degree" && (
                <div className="error-message">{errorMessage}</div>
              )} */}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="university"
              >
                University
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="university"
                type="text"
                value={employeeDetails.university}
                onChange={handleChange}
                placeholder="Enter university"
              />
              {errorMessage && error.data.type === "Wrong university" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="bankName"
              >
                Bank Name
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bankName"
                type="text"
                value={employeeDetails.bankName}
                onChange={handleChange}
                placeholder="Enter bank name"
              />
              {errorMessage && error.data.type === "Wrong bankName" && (
                <div className="error-message">{errorMessage}</div>
              )}
              <label
                htmlFor="bank_pic"
                className="flex items-center space-x-2 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <span className="text-gray-600">
                  {employeeDetails.passBookPic
                    ? `${employeeDetails.passBookPic.name}`
                    : ""}
                </span>
              </label>
              <input
                className="hidden"
                type="file"
                name="passBookPic"
                // accept="image/*"
                accept=".pdf, .docx, .png, .jpeg, .jpg"
                id="bank_pic"
                onChange={handlePassBookPicChange}
              />
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="accountNumber"
              >
                Bank Account Number
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="accountNumber"
                type="text"
                value={employeeDetails.accountNumber}
                onChange={handleChange}
                placeholder="Enter bank account"
              />
              {errorMessage && error.data.type === "Wrong accountNumber" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
             <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="ifscCode"
              >
                Bank Ifsc Code
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="ifscCode"
                type="text"
                value={employeeDetails.ifscCode}
                onChange={handleChange}
                placeholder="Enter ifsc number"
              />
              {errorMessage && error.data.type === "Wrong ifscCode" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="aadharNumber"
              >
                Aadhar Number
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="aadharNumber"
                type="text"
                value={employeeDetails.aadharNumber}
                onChange={handleChange}
                placeholder="Enter aadhar number"
              />
              {errorMessage && error.data.type === "Wrong aadharNumber" && (
                <div className="error-message">{errorMessage}</div>
              )}
              <label
                htmlFor="aadhar_pic"
                className="flex items-center space-x-2 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <span className="text-gray-600">
                  {employeeDetails.aadharPic
                    ? `${employeeDetails.aadharPic.name}`
                    : ""}
                </span>
              </label>
              <input
                className="hidden"
                type="file"
                name="aadharPic"
                // accept="image/*"
                accept=".pdf, .docx, .png, .jpeg, .jpg"
                id="aadhar_pic"
                onChange={handleAadharPicChange}
              />
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="panNumber"
              >
                Pan Number
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="panNumber"
                type="text"
                value={employeeDetails.panNumber}
                onChange={handleChange}
                placeholder="Enter pan number"
              />
              {errorMessage && error.data.type === "Wrong panNumber" && (
                <div className="error-message">{errorMessage}</div>
              )}
              <label
                htmlFor="pan_pic"
                className="flex items-center space-x-2 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <span className="text-gray-600">
                  {employeeDetails.panPic
                    ? `${employeeDetails.panPic.name}`
                    : ""}
                </span>
              </label>
              <input
                className="hidden"
                type="file"
                name="panPic"
                // accept="image/*"
                accept=".pdf, .docx, .png, .jpeg, .jpg"
                id="pan_pic"
                onChange={handlePanCardPicChange}
              />
            </div> 
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="uanNumber"
              >
                Uan Number
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="uanNumber"
                type="text"
                value={employeeDetails.uanNumber}
                onChange={handleChange}
                placeholder="Enter uan number"
              />
              {/* {errorMessage && error.data.type === "Wrong uanNumber" && (
                <div className="error-message">{errorMessage}</div>
              )} */}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="esicNumber"
              >
                Esic Number
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="esicNumber"
                type="text"
                value={employeeDetails.esicNumber}
                onChange={handleChange}
                placeholder="Enter esic number"
              />
              {errorMessage && error.data.type === "Wrong esicNumber" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0 mt-2">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="profilePic"
              >
                Profile Picture
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                // id="profilePic"
                // filename={profilePic}
                name="profilePic"
                // value={employeeDetails.profilePic}
                type="file"
                accept="image/*"
                onChange={handleProfilePicChange}
              />
              {errorMessage &&
                error.data.type === "Missing Profile Picture" && (
                  <div className="error-message">{errorMessage}</div>
                )}
            </div>
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0 mt-2">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="cancelledChequePic"
              >
                Cancelled Cheque Photo
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                // id="profilePic"
                // filename={profilePic}
                name="cancelledChequePic"
                // value={employeeDetails.profilePic}
                type="file"
                // accept="image/*"
                accept=".pdf, .docx, .png, .jpeg, .jpg"
                onChange={handleChequePicChange}
              />
            </div>
          </div>
          <Button type="submit" className="mt-1 mb-4 bg-blue-500">
            {isLoading ? "Creating..." : "Create"}{" "}
          </Button>
          {isLoading && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="spinner"></div>
            </div>
          )}
        </form>
        {showSuccessModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 backdrop-blur-md">
            <div className="absolute bg-white p-5 border w-96 shadow-lg rounded-md">
              <div className="mt-3 text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-blue-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Successful!
                </h3>
                <div className="mt-2 px-7 py-3">
                  <p className="text-sm text-gray-500">
                    Employee has been successfully Created!
                  </p>
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    id="ok-btn"
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                    // onClick={() => setShowSuccessModal(false)} // Close the modal
                    onClick={handleModalCloseAndRedirect}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default AddEmployee;
