import React, { useEffect, useState, useRef } from "react";

// import { inp, lbl } from "../styles/pages/employee.module.scss";
import axios from "../../service/api";
import UserRole from "../../utils/User";
import Status from "../../utils/Stage";
import { getRole } from "../../service/auth";
import DataSubmittingPop from "./DataSubmitPopup";
// import * as styles from "../styles/pages/employee.module.scss";

const Popup = React.memo((props) => {
  const [checkedValue, setCheckedValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentRow, setCurrentRow] = useState(0);
  const valueInInputText = useRef(null);

  const setRowNext = (updatedData, updatedDataArr, callback) => {
    if (props.checkedRows.length - 1 !== currentRow) {
      setCheckedValue("");
      setIsProcessing(false);
      if (valueInInputText.current != null) {
        valueInInputText.current.value = "";
      }
      setCurrentRow(currentRow + 1);
    } else {
      props.setCheckedRows(updatedData);
      props.setData(updatedDataArr);
      props.onCancel();
      setCurrentRow(0);

      // if (typeof callback === "function") {
      //   callback(updatedData);
      // }
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    // let dataCopy = [...props.data];
    let checkedRowsCopy = [];
    // setIsProcessing(true);
    if (props.isForAddNew) {
      (async () => {
        const newDataItem = {};
        props.inputs.forEach((inp) => {
          if (inp !== "Filestatus") {
            if (inp !== "_id")
              newDataItem[inp] = e.target[`inp${inp.toLowerCase()}`]?.value;
          }
        });
        // if (props.addUrl.includes("/user")) {
        //   newDataItem["password"] = e.target["inppassword"].value;
        // }
        try {
          await axios.post(props.addUrl, newDataItem);
          console.log(newDataItem);
          // props.onCancel();
          if (typeof window !== `undefined`) {
            window.location.reload();
          }
        } catch (err) {
          props.setError();
          console.log(err);
          // setIsProcessing(false);
        }
      })();

      // dataCopy.push(newDataItem);
    } else {
      (async () => {
        const newDataItem = {};
        props.inputs.forEach((inp) => {
          
          if (inp !== "Filestatus") {
            if (inp !== "_id")
              newDataItem[inp] = e.target[`inp${inp.toLowerCase()}`]?.value;
            else newDataItem["id"] = props.checkedRows[0]["_id"];
              // newDataItem["type"] = props.checkedRows[0]["Pubcode"];
          }
        });
        if (props.addUrl.includes("/user")) {
          newDataItem["empId"] = e.target["inp-empId"].value;
          // newDataItem["email"] = e.target["inp-email"].value;
          newDataItem["firstName"] = e.target["inp-firstName"].value;
          newDataItem["lastName"] = e.target["inp-lastName"].value;
          newDataItem["dob"] = e.target["inp-dob"].value;
          newDataItem["joiningDate"] = e.target["inp-joiningDate"].value;
          newDataItem["presentAddress"] = e.target["inp-address"].value;
          newDataItem["city"] = e.target["inp-city"].value;
          newDataItem["presentAddressPin"] = e.target["inp-pin"].value;
          newDataItem["bankName"] = e.target["inp-bankName"].value;
          newDataItem["accountNumber"] = e.target["inp-accountNo"].value;
          newDataItem["ifscCode"] = e.target["inp-ifsc"].value;
          newDataItem["esicNumber"] = e.target["inp-esicNo"].value;
          newDataItem["uanNumber"] = e.target["inp-uanNo"].value;
          newDataItem["maritalStatus"] = e.target["inp-marital"].value;
          newDataItem["spouseName"] = e.target["inp-spouse"].value;
          newDataItem["bloodGroup"] = e.target["inp-bloodGroup"].value;
          newDataItem["degree"] = e.target["inp-degree"].value;
          newDataItem["university"] = e.target["inp-university"].value;
          newDataItem["phoneNumber"] = e.target["inp-phNumber"].value;
          newDataItem["gender"] = e.target["inp-gender"].value;
          newDataItem["reportingManager"] = e.target["inp-reportingTo"].value;
          newDataItem["aadharNumber"] = e.target["inp-aadharNo"].value;
          newDataItem["panNumber"] = e.target["inp-panNo"].value;
          // newDataItem["password"] = e.target["inppassword"].value;
        } 
        try {
          const response = await axios.put(props.editUrl, newDataItem);
          console.log(newDataItem);
          if(props.editUrl.includes("/editFile")){
            const updatedDataFiles = [...props.data];
            const updatedCheckedRows = response.data.updatedFile;
            const updatedCheckedRowsArray = [updatedCheckedRows];
            const dataIndex = updatedDataFiles.findIndex((item) => item._id === updatedCheckedRows._id);
            if(dataIndex !== -1){
              updatedDataFiles[dataIndex] = updatedCheckedRows;
            }

          // if (typeof window !== `undefined`) {
          //   window.location.reload();
          setRowNext(updatedCheckedRowsArray, updatedDataFiles);
        } else {
            props.onConfirmed();
            props.handleClose();
        }
        } catch (err) {
          setErrorMessage(err.response.data.data.type);
          // props.setError();
          console.log(err);
          // setIsProcessing(false);
        }
      })();
    }
  };

  return (
    <>
      <div
        className="fixed inset-0 bg-opacity-60 z-20"
        onClick={() => {
          props.setPopupState(false);
        }}
      ></div>
      <form
        onSubmit={submitForm}
        className="w-4/5 fixed space-y-4 px-8 py-10 h-c-6 overflow-x-auto overflow-y-auto grid grid-cols-2 gap-x-12 bg-[#f8f1f2] top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-30 rounded-lg max-h-screen"
      >
        <h2 className="text-center col-span-full">
          Enter the following details:
        </h2>
        {props.inputs.map((input, i) => {
          if (input === "Filestatus") {
            return;
          }
          if (input === "Status") {
            return (
              <div key={i}>
                <label
                  htmlFor={`inp-${input}`}
                  className="block font-semibold mb-2"
                >
                  Enter {input}
                </label>

                <select
                  id="status"
                  className="shadow-lg rounded-xl p-4 block w-full"
                  defaultValue={
                    !props.isForAddNew ? props.checkedRows[0]?.[input] : ""
                  }
                >
                  {Status.statusArray.map((opt, i) => (
                    <option key={i + "-inner"} value={opt}>
                      {opt.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
            );
          }

          if (input === "role") {
            return (
              <>
              <div>
              <label htmlFor="inp-empId" className="block font-semibold mb-2">
                Enter Employee Id
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                required
                type="text"
                id="inp-empId"
                placeholder="Enter Employee Id"
                defaultValue={props.setDefaultValue?.employeeId}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-email" className="block font-semibold mb-2">
                Employee Email
              </label>
              <input
                required
                type="text"
                id="inp-email"
                disabled
                // placeholder="Enter Employee Id"
                defaultValue={props.setDefaultValue?.employeeEmail}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-firstName" className="block font-semibold mb-2">
                Enter First Name
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                required
                type="text"
                id="inp-firstName"
                placeholder="Enter First Name"
                defaultValue={props.setDefaultValue?.employeeFirstName}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-lastName" className="block font-semibold mb-2">
                Enter Last Name
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                type="text"
                id="inp-lastName"
                placeholder="Enter Last Name"
                defaultValue={props.setDefaultValue?.employeeLastName}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-dob" className="block font-semibold mb-2">
                Date Of Birth
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                required
                type="date"
                id="inp-dob"
                placeholder="Enter Date Of Birth"
                defaultValue={props.setDefaultValue?.employeeDOB}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-joiningDate" className="block font-semibold mb-2">
                Date Of Joining
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                required
                type="date"
                id="inp-joiningDate"
                // placeholder="Enter Date Of Birth"
                defaultValue={props.setDefaultValue?.employeeJoiningDate}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-address" className="block font-semibold mb-2">
                Enter Address
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                required
                type="text"
                id="inp-address"
                placeholder="Enter Address"
                defaultValue={props.setDefaultValue?.employeePresentAddress}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-city" className="block font-semibold mb-2">
                Enter City
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                required
                type="text"
                id="inp-city"
                placeholder="Enter City"
                defaultValue={props.setDefaultValue?.employeeCity}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-pin" className="block font-semibold mb-2">
                Enter PinCode
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                required
                type="text"
                id="inp-pin"
                placeholder="Enter Pin Code"
                defaultValue={props.setDefaultValue?.employeePin}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-phNumber" className="block font-semibold mb-2">
                Enter Phone Number
                <span className="text-red-500 text-medium">*</span>
              </label>
              <input
                required
                type="text"
                id="inp-phNumber"
                placeholder="Enter Phone Number"
                defaultValue={props.setDefaultValue?.employeePhoneNumber}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-gender" className="block font-semibold mb-2">
                Change Gender
                <span className="text-red-500 text-medium">*</span>
              </label>
              <select
                required
                id="inp-gender"
                className="shadow-lg rounded-xl p-4 block w-full"
                defaultValue={props.setDefaultValue?.employeeGender}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div>
              <label htmlFor="inp-reportingTo" className="block font-semibold mb-2">
                Change Reporting Manager
                <span className="text-red-500 text-medium">*</span>
              </label>
              <select
                key={Date.now()}
                required
                id="inp-reportingTo"
                className="shadow-lg rounded-xl p-4 block w-full"
                defaultValue={
                  props.setDefaultValue?.employeeReportingManager
                  // !props.isForAddNew ? props.checkedRows[0]?.[input] : ""
                }
              >
                {UserRole.userReportingArray.map((value, i) => {
                  // if (value=="stakeholders" && getRole()=="logistics") return (<></>);
                  return (
                    <option key={i} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label htmlFor="inp-bankName" className="block font-semibold mb-2">
                Enter Bank Name
              </label>
              <input
                type="text"
                id="inp-bankName"
                placeholder="Enter Bank Name"
                defaultValue={props.setDefaultValue?.employeeBankName}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
              {props.setDefaultValue?.employeePassBookPic === null ? (
                <div>
                  <label
                    htmlFor="inp-passBookPic"
                    className="flex items-center space-x-2 cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6 text-gray-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    {/* <span className="text-gray-600">Upload Aadhar Picture</span> */}
                  </label>
                  <input
                    className="hidden"
                    type="file"
                    name="passBookPic"
                    accept="image/*"
                    id="inp-passBookPic"
                    // onChange={handleAadharPicChange}
                  />
                </div>
              ) : (
                <label
                  htmlFor="inp-passBookPic"
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <span className="text-gray-600">
                    {props.setDefaultValue?.employeePassBookPic.name}
                  </span>
                </label>
              )}
            </div>
            <div>
              <label htmlFor="inp-accountNo" className="block font-semibold mb-2">
                Enter Bank Account Number
              </label>
              <input
                type="text"
                id="inp-accountNo"
                placeholder="Enter Account Number"
                defaultValue={props.setDefaultValue?.employeeAccountNumber}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-ifsc" className="block font-semibold mb-2">
                Enter IFSC Code
              </label>
              <input
                type="text"
                id="inp-ifsc"
                placeholder="Enter Ifsc"
                defaultValue={props.setDefaultValue?.employeeIFSC}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-aadharNo" className="block font-semibold mb-2">
                Enter Aadhar Number
              </label>
              <input
                required
                type="text"
                id="inp-aadharNo"
                placeholder="Enter Aadhar Number"
                defaultValue={props.setDefaultValue?.employeeAadharNumber}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
              {/* Aadhar Pic */}
              {props.setDefaultValue?.employeeAadharPic === null ? (
                <div>
                  <label
                    htmlFor="inp-aadharPic"
                    className="flex items-center space-x-2 cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6 text-gray-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    {/* <span className="text-gray-600">Upload Aadhar Picture</span> */}
                  </label>
                  <input
                    className="hidden"
                    type="file"
                    name="aadharPic"
                    accept="image/*"
                    id="inp-aadharPic"
                    // onChange={handleAadharPicChange}
                  />
                </div>
              ) : (
                <label
                  htmlFor="inp-aadharPic"
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <span className="text-gray-600">
                    {props.setDefaultValue?.employeeAadharPic?.name}
                  </span>
                </label>
              )}
            </div>
            <div>
              <label htmlFor="inp-panNo" className="block font-semibold mb-2">
                Enter Pan Number
              </label>
              <input
                required
                type="text"
                id="inp-panNo"
                placeholder="Enter Pan Number"
                defaultValue={props.setDefaultValue?.employeePanNumber}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
              {/* Pan Pic */}
              {props.setDefaultValue?.employeePanPic === null ? (
                <div>
                  <label
                    htmlFor="inp-panPic"
                    className="flex items-center space-x-2 cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6 text-gray-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    {/* <span className="text-gray-600">Upload Aadhar Picture</span> */}
                  </label>
                  <input
                    className="hidden"
                    type="file"
                    name="panPic"
                    accept="image/*"
                    id="inp-panPic"
                    // onChange={handleAadharPicChange}
                  />
                </div>
              ) : (
                <label
                  htmlFor="inp-panPic"
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <span className="text-gray-600">
                    {props.setDefaultValue?.employeePanPic?.name}
                  </span>
                </label>
              )}
            </div>
            <div>
              <label htmlFor="inp-esicNo" className="block font-semibold mb-2">
                Enter ESIC Number
              </label>
              <input
                type="text"
                id="inp-esicNo"
                placeholder="Enter Esic Number"
                defaultValue={props.setDefaultValue?.employeeESICNumber}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-uanNo" className="block font-semibold mb-2">
                Enter UAN Number
              </label>
              <input
                type="text"
                id="inp-uanNo"
                placeholder="Enter Uan Number"
                defaultValue={props.setDefaultValue?.employeeUANNumber}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-marital" className="block font-semibold mb-2">
                Change Marital Status
              </label>
              <select
                required
                id="inp-marital"
                className="shadow-lg rounded-xl p-4 block w-full"
                defaultValue={props.setDefaultValue?.employeeMaritalStatus}
              >
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="seperated">Seperated</option>
                <option value="divorced">Divorced</option>
              </select>
            </div>
            <div>
              <label htmlFor="inp-spouse" className="block font-semibold mb-2">
                Enter Spouse Name
              </label>
              <input
                type="text"
                id="inp-spouse"
                placeholder="Enter Spouse Name"
                defaultValue={props.setDefaultValue?.employeeSpouseName}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-bloodGroup" className="block font-semibold mb-2">
                Enter Blood Group
              </label>
              <input
                type="text"
                id="inp-bloodGroup"
                placeholder="Enter Blood Group"
                defaultValue={props.setDefaultValue?.employeeBloodGroup}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
              {/* Blood Group Pic */}
              {props.setDefaultValue?.employeeBloodGroupPic === null ? (
                <div>
                  <label
                    htmlFor="inp-bloodGroupPic"
                    className="flex items-center space-x-2 cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6 text-gray-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    {/* <span className="text-gray-600">Upload Aadhar Picture</span> */}
                  </label>
                  <input
                    className="hidden"
                    type="file"
                    name="bloodGroupPic"
                    accept="image/*"
                    id="inp-bloodGroupPic"
                    // onChange={handleAadharPicChange}
                  />
                </div>
              ) : (
                <label
                  htmlFor="inp-bloodGroupPic"
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <span className="text-gray-600">
                    {props.setDefaultValue?.employeeBloodGroupPic?.name}
                  </span>
                </label>
              )}
            </div>
            {/* Highest Qualification  */}
            <div>
              <label
                htmlFor="inp-highestQualification"
                className="block font-semibold mb-2"
              >
                Highest Qualification
              </label>
              <input
                type="text"
                id="inp-highestQualification"
                placeholder="Enter Highest Qualification"
                defaultValue={props.setDefaultValue?.employeeHighestQualification}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
              {/* Blood Group Pic */}
              {props.setDefaultValue?.employeeHighestQualificationPic === null ? (
                <div>
                  <label
                    htmlFor="inp-highestQualificationPic"
                    className="flex items-center space-x-2 cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6 text-gray-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    {/* <span className="text-gray-600">Upload Aadhar Picture</span> */}
                  </label>
                  <input
                    className="hidden"
                    type="file"
                    name="highestQualificationPic"
                    accept="image/*"
                    id="inp-highestQualificationPic"
                    // onChange={handleAadharPicChange}
                  />
                </div>
              ) : (
                <label
                  htmlFor="inp-highestQualificationPic"
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <span className="text-gray-600">
                    {props.setDefaultValue?.employeeHighestQualification?.name}
                  </span>
                </label>
              )}
            </div>
            <div>
              <label htmlFor="inp-degree" className="block font-semibold mb-2">
                Enter Degree
              </label>
              <input
                required
                type="text"
                id="inp-degree"
                placeholder="Enter Degree"
                defaultValue={props.setDefaultValue?.employeeDegree}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div>
              <label htmlFor="inp-university" className="block font-semibold mb-2">
                Enter University
              </label>
              <input
                required
                type="text"
                id="inp-university"
                placeholder="Enter University"
                defaultValue={props.setDefaultValue?.employeeUniversity}
                className="shadow-lg rounded-xl p-4 block w-full"
              />
            </div>
            <div key={i}>
                <label
                  htmlFor={`inp-${input}`}
                  className="block font-semibold mb-2"
                >
                  Enter {input}
                </label>

                <select
                  key={Date.now()}
                  required
                  id={`inp${input.toLowerCase()}`}
                  placeholder={`Enter ${input}`}
                  className="shadow-lg rounded-xl p-4 block w-full"
                  defaultValue={
                    props.setDefaultValue?.employeeRole
                    // !props.isForAddNew ? props.checkedRows[0]?.[input] : ""
                  }
                >
                  {UserRole.userRoleArray.map((value, i) => {
                    if (value == "Stakeholders" && getRole() == "Logistics")
                      return <></>;
                    return (
                      <option key={i} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </div>
            {props.setDefaultValue?.employeeCancelledChequePic === null ? (
          <div>
            <label class="block font-semibold mb-2" for="cancelledChequePic">
              Cancelled Cheque Photo
            </label>
            <input
              class="appearance-none block w-full shadow-lg text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              // id="profilePic"
              // filename={profilePic}
              name="cancelledChequePic"
              // value={employeeDetails.profilePic}
              type="file"
              accept="image/*"
              // onChange={handleChequePicChange}
            />
          </div>
        ) : (
          <></>
        )}
            </>
            )
            // return (
            //   <div key={i}>
            //     <label
            //       htmlFor={`inp-${input}`}
            //       className="block font-semibold mb-2"
            //     >
            //       Enter {input}
            //     </label>

            //     <select
            //       key={Date.now()}
            //       required
            //       id={`inp${input.toLowerCase()}`}
            //       placeholder={`Enter ${input}`}
            //       className="shadow-lg rounded-xl p-4 block w-full"
            //       defaultValue={
            //         props.setDefaultValue?.employeeRole
            //         // !props.isForAddNew ? props.checkedRows[0]?.[input] : ""
            //       }
            //     >
            //       {UserRole.userRoleArray.map((value, i) => {
            //         if (value == "stakeholders" && getRole() == "Logistics")
            //           return <></>;
            //         return (
            //           <option key={i} value={value}>
            //             {value}
            //           </option>
            //         );
            //       })}
            //     </select>
            //   </div>
            // );
          }
          return (
            input !== "_id" &&
            input !== "Production" &&
            input !== "QA" &&
            input !== "Final" && (
              <div key={i}>
                <label htmlFor={`inp-${input}`} className="block font-semibold mb-2">
                  Enter {input}
                  {props.isForAddNew  && input !== "fileType" && input !== "Publicatie" && input !== "Source" && ( 
                  <span className="text-red-500 text-medium">*</span>
                )}
                </label>
                  {input === "Client" ? (
                    <select
                      id={`inp${input.toLowerCase()}`}
                      className="shadow-lg rounded-xl p-4 block w-full"
                      defaultValue={""}
                    >
                      {props.client.map((client, index) => (
                        <option key={index} value={client.label}>
                          {client.label}
                        </option>
                      ))}
                    </select>
                  ) : input === "Priority" ? (
                    <select
                      id={`inp${input.toLowerCase()}`}
                      className="shadow-lg rounded-xl p-4 block w-full"
                      defaultValue={""}
                    >
                      {["High", "Medium", "Low"].map((priority, index) => (
                        <option key={index} value={priority}>
                          {priority}
                        </option>
                      ))}
                    </select>
                  ) :  input === "Complexity" ? (
                    <select
                      id={`inp${input.toLowerCase()}`}
                      className="shadow-lg rounded-xl p-4 block w-full"
                      defaultValue={""}
                    >
                      {["Simple", "Medium", "Complex"].map((complexity, index) => (
                        <option key={index} value={complexity}>
                          {complexity}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      key={Date.now()}
                      required
                      type="text"
                      id={`inp${input.toLowerCase()}`}
                      placeholder={`Enter ${input}`}
                      className="shadow-lg rounded-xl p-4 block w-full"
                      defaultValue={
                        !props.isForAddNew ? props.checkedRows[0]?.[input] : ""
                      }
                      {...(input === "fileType" || input === "Publicatie" || input === "Source"
                      ? { required: false }
                      : { required: true })}
                    />
                  )}
               
              </div>
            )
          );
        })}
        {/* {props.addUrl.includes("/user") ? (
          <div>
            
            <label htmlFor={`inp-password`} className="block font-semibold mb-2">
              Enter password
            </label>
            <input
              key={Date.now()}
              required={props.isForAddNew}
              type="password"
              id={`inppassword`}
              defaultValue={
                props.setDefaultValue?.employeePassword
              }
              placeholder={`Enter password`}
              className="shadow-lg rounded-xl p-4 block w-full"
            />
          </div>
        ) : (
          <></>
        )} */}
        <div className="col-span-full space-x-12 text-center ">
          <button className="bg-blue-500 mb-10 mt-5 justify-self-center py-2 px-8 text-white">
            {props.isForAddNew ? "ADD NEW" : "EDIT"}
          </button>
          <button
            className="bg-red-500 mb-10 mt-5 justify-self-center py-2 px-8 text-white"
            onClick={(e) => {
              e.preventDefault();
              props.setPopupState(false);
            }}
          >
            CANCEL
          </button>
        </div>
      </form>
    </>
  );
});

export default Popup;
