import React, {
  useState,
  useEffect,
  useMemo,
} from "react";
import Layout from "../../components/Layout/Layout";
import { getRole } from "../../service/auth";
import axios from "../../service/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { Button, Menu, MenuItem, Modal } from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { debounce } from "lodash";
import "../ManageEmployee/LoadingSpinner.css";

const EmployeeShiftPage = () => {
  const [rosterDetailsData, setRosterDetailsData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [selectedType, setSelectedType] = useState("WFH");
  const [editSelectedType, setEditSelectedType] = useState("");
  const [editSelectedStartTime, setEditSelectedStartTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState("All");
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedDatesId, setSelectedDatesId] = useState(null);
  const [shiftStartTimes, setShiftStartTimes] = useState([
    "12:30 AM",
    "04:00 AM",
    "08:00 AM",
    "09:30 AM",
    "03:30 PM",
  ]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [importProgress, setImportProgress] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const open = Boolean(anchorEl);

  const [departments, setDepartments] = useState([
    "Admin",
    "Logistics",
    "Production InCharge",
    "Production",
  ]);
  const [availabilities, setAvailabilities] = useState([
    "Morning Shift",
    "Days Shift",
    "Evening Shift",
    "Night Shift",
  ]);
  const [types, setTypes] = useState([
    "WFH",
    "WFO",
    "Weekoff",
    "Leave",
    "Absent",
    "Holiday",
    "HalfDay",
  ]);

  const debouncedCallApi = useMemo(
    () =>
      debounce((searchText) => {
        fetchData(searchText);
      }, 1000),
    [
      page,
      perPage,
      selectedAvailability,
      selectedDepartment,
      selectedType,
      selectedDate,
    ]
  );

  const fetchData = async (searchTextValue) => {
    try {
      const response = await axios.post("/user/getAllShiftDetails", {
        page: page,
        limit: perPage,
        availability: selectedAvailability,
        department: selectedDepartment,
        type: selectedType,
        date: format(selectedDate, "yyyy-MM-dd"),
        searchParams: searchTextValue ?? searchText,
      });
      const { rosterDetails, totalPages } = response.data;
      setRosterDetailsData(rosterDetails);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching roster data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    perPage,
    selectedAvailability,
    selectedDepartment,
    selectedType,
    selectedDate,
  ]);

  const pageLimit = 5;
  const totalItems = totalPages * pageLimit;

  const getPageNumbers = (totalPages) => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const getPageNumbersToShow = () => {
    const maxVisiblePages = 5;
    const middleIndex = Math.floor(maxVisiblePages / 2);
    const startIndex = Math.max(page - middleIndex, 1);
    const endIndex = Math.min(startIndex + maxVisiblePages - 1, totalPages);
    return pageNumbers.slice(startIndex - 1, endIndex);
  };

  const pageNumbers = getPageNumbers(totalPages);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleImportRoster = async (event) => {
    try {
      setIsLoading(true);
      const file = event.target.files[0];
      if (!file) return;

      // Use FileReader to read the file
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const data = e.target.result;
          const response = await axios.post("/user/userRoster", {
            fileData: data,
            onUploadProgress: (progressEvent) => {
              // Calculate progress percentage
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              // Update progress state using setState updater function
              setImportProgress((prevProgress) => progress);
            },
          });

          alert("Roster data imported successfully");
          console.log("Roster data imported successfully:", response.data);
        } catch (error) {
          alert("Error importing roster data. Please check the file format.");
          console.error("Error parsing Excel data:", error);
        } finally {
          setIsLoading(false); // Set loading state to false when request completes (success or error)
        }
      };

      reader.readAsBinaryString(file);
    } catch (error) {
      console.error("Error while importing roster:", error);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = (id, datesId) => {
    setSelectedId(id);
    setSelectedDatesId(datesId);
    setOpenModal(true);
    handleMenuClose();
  };

  // const handleDeleteClick = async (rosterId, dateId) => {

  //   try {
  //     await axios.delete(`/user/deleteUserRoster/${rosterId}/${dateId}`);
  //     // Refresh the roster data after deletion
  //     setRosterDetailsData((prevData) => {
  //       const updatedRoster = prevData.filter(
  //         (roster) => roster._id !== rosterId
  //       );
  //       return updatedRoster;
  //     });
  //     handleMenuClose();
  //     alert("User roster deleted successfully...")
  //   } catch (error) {
  //     console.error("Error deleting roster data:", error);
  //   }
  // };

  const handleEditModalClose = () => {
    setOpenModal(false);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / pageLimit);
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleEditSave = async (newType, newTime) => {
    try {
      // Call your edit API here
      const response = await axios.put(`/user/editShiftDetail/${selectedId}`, {
        newType,
        newTime,
        dateIdToUpdate: selectedDatesId,
      });

      const updatedDate = response.data.roster.dates.find(
        (date) => date._id === selectedDatesId
      );

      setRosterDetailsData((prevData) =>
        prevData.map((item) =>
          item._id === selectedId
            ? {
                ...item,
                userDetail: {
                  ...item.userDetail,
                  dates: item.userDetail.dates.map((dateItem) =>
                    dateItem._id === selectedDatesId ? updatedDate : dateItem
                  ),
                },
              }
            : item
        )
      );

      setPage(1);
      handleEditModalClose();
    } catch (error) {
      console.error("Error editing roster data:", error);
    }
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleDownloadExcelRoster = async () => {
    try {
      const url = `/user/downloadShiftDetail?fromDate=${fromDate}&toDate=${toDate}`;

      const response = await axios.get(url, {
        responseType: "blob", // Indicate that the response should be treated as a Blob
      });

      const fileName = `roster_${fromDate}_to_${toDate}.xlsx`;
      // Create a URL for the Blob response
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element and trigger a click to download the file
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.log(error);
    }
  };

  // const renderLinearProgress = importProgress > 0 && importProgress < 100;
  const availabilityColors = {
    "Night Shift": "bg-gray-500",
    "Morning Shift": "bg-blue-500",
    "Days Shift": "bg-orange-500",
    "Evening Shift": "bg-purple-500",
  };

  const handleModalCloseAndRedirect = () => {
    setShowSuccessModal(false);
    // navigate("/manageEmployees"); // Redirect to manageEmployees
  };

  const handleSearchChange = (e) => {
    const searchTextValue = e.target.value;
    setSearchText(searchTextValue);
    debouncedCallApi(searchTextValue);
  };

  return (
    <Layout isAdmin={getRole() === "Admin" || getRole() === "Stakeholders"}>
      <div className="flex justify-center mt-2">
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleImportRoster}
          className="hidden"
          id="rosterFileInput"
        />
        <label
          htmlFor="rosterFileInput"
          className="cursor-pointer bg-blue-500 px-5 py-2 rounded-lg text-white text-sm"
        >
          Import Roster
        </label>
      </div>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="spinner"></div>
        </div>
      )}

      {/* Filter Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4">
        {/* Department Filter */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Department
          </label>
          <select
            className="mt-1 p-2 border rounded-md w-full"
            onChange={(e) => setSelectedDepartment(e.target.value)}
            value={selectedDepartment}
          >
            <option value="">All</option>
            {departments.map((department) => (
              <option key={department} value={department}>
                {department}
              </option>
            ))}
          </select>
        </div>

        {/* Type Filter */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Type
          </label>
          <select
            className="mt-1 p-2 border rounded-md w-full"
            onChange={(e) => setSelectedType(e.target.value)}
            value={selectedType}
          >
            {types.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        {/* Availability Filter */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Availability
          </label>
          <select
            className="mt-1 p-2 border rounded-md w-full"
            onChange={(e) => setSelectedAvailability(e.target.value)}
            value={selectedAvailability}
          >
            <option value="">All</option>
            {availabilities.map((availability) => (
              <option key={availability} value={availability}>
                {availability}
              </option>
            ))}
          </select>
        </div>

        {/* Date Filter */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Date
          </label>
          <DatePicker
            className="mt-1 p-2 border rounded-md w-full md:w-auto"
            placeholderText="Choose Date"
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
          />
        </div>
        {/* Search Bar */}
        <div className="mb-4 relative">
          <label className="sr-only">Search</label>
          <input
            type="text"
            className="mt-1 p-2 border rounded-md w-full"
            placeholder="Search..."
            value={searchText}
            onChange={handleSearchChange}
            // onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div class="flex items-center">
          <div class="mr-2">
            <label className="block text-sm font-medium text-gray-700">
              From Date
            </label>
            <div className="relative">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                name="start"
                type="date"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select date start"
                value={fromDate}
                onChange={handleFromDateChange}
                // max={new Date().toISOString().split('T')[0]}
              />
            </div>
          </div>
          <span class="mr-4 flex justify-center text-gray-500">to</span>
          <div class="mr-2">
            <label className="block text-sm font-medium text-gray-700">
              End Date
            </label>
            <div className="relative">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                name="end"
                type="date"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select date end"
                value={toDate}
                onChange={handleToDateChange}
                // max={new Date().toISOString().split('T')[0]}
              />
            </div>
          </div>
          <div className="mt-1">
            <IconButton
              color="primary"
              className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-6 mb-4 md:p-2 md:ml-2 lg:p-2 lg:ml-2 xl:p-2 xl:ml-2"
              onClick={handleDownloadExcelRoster}
            >
              <CloudDownloadIcon fontSize="large" />
              {/* <ArrowDropDownIcon /> */}
            </IconButton>
          </div>
        </div>
      </div>

      {/* Employee List Section */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-b rounded-md text-center divide-gray-200 table-fixed divide-y">
          <thead>
            <tr>
              {/* <th className="px-4 py-2">Profile Pic</th> */}
              <th className="px-1 py-2">Full Name</th>
              <th className="px-1 py-2">Role</th>
              <th className="px-1 py-2">Shift Date</th>
              <th className="px-1 py-2">Shift Start Time</th>
              <th className="px-1 py-2">Availability</th>
              <th className="px-1 py-2">Type</th>
              <th className="px-1 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {rosterDetailsData.map((item, index) => (
              <tr key={item._id}>
                <td className="px-1 py-2">{item.userDetail.fullName}</td>
                <td className="px-1 py-2">{item.userDetail.role}</td>
                <td className="px-1 py-2">
                  {item.userDetail.dates.map((dateItem) => (
                    <div key={dateItem._id}>
                      {new Date(dateItem.date).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </div>
                  ))}
                </td>
                <td className="px-1 py-2">
                  {item.userDetail.dates.map((dateItem) => (
                    <div key={dateItem._id}>
                      {new Date(dateItem.shiftStartDate).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "numeric",
                          minute: "numeric",
                        }
                      )}
                    </div>
                  ))}
                </td>
                <td className="px-1 py-2">
                  {/* <span className="bg-green-500 text-white rounded-xl p-2 mb-2 inline-block"> */}
                  <span
                    className={`rounded-xl p-2 mb-2 inline-block ${
                      availabilityColors[item.userDetail.availability]
                    }`}
                  >
                    <span className="text-medium">
                      {item.userDetail.availability}
                    </span>
                  </span>
                </td>
                <td className="px-1 py-2">
                  <span className="mb-2 inline-block">
                    {item.userDetail.dates.map((dateItem) => (
                      <div
                        key={dateItem._id}
                        className={`rounded-xl p-2 mr-2 inline-block ${
                          dateItem.type === "Absent"
                            ? "bg-red-500"
                            : dateItem.type === "WFO"
                            ? "bg-green-500"
                            : dateItem.type === "WFH"
                            ? "bg-blue-500"
                            : dateItem.type === "Holiday"
                            ? "bg-orange-500"
                            : dateItem.type === "Weekoff"
                            ? "bg-purple-500"
                            : dateItem.type === "HalfDay"
                            ? "bg-pink-500"
                            : dateItem.type === "Leave"
                            ? "bg-yellow-500"
                            : ""
                        } text-white`}
                      >
                        {dateItem.type}
                      </div>
                    ))}
                  </span>
                </td>
                <td className="px-4 py-2">
                  <Button
                    onClick={() =>
                      handleEditClick(item._id, item.userDetail.dates[0]._id)
                    }
                    // onClick={handleMenuClick}
                    variant="contained"
                    color="primary"
                  >
                    Actions
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                  >
                    <MenuItem
                      onClick={() =>
                        handleEditClick(item._id, item.userDetail.dates[0]._id)
                      }
                    >
                      Edit
                    </MenuItem>
                    {/* <MenuItem onClick={() => handleDeleteClick(item._id, item.userDetail.dates[0]._id)}>Delete</MenuItem> */}
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="p-4 bg-white max-w-md mx-auto mt-20 rounded-md">
          <h2 className="text-2xl font-bold mb-4">Edit Shift Details</h2>
          <select
            className="p-2 border rounded-md w-full mb-4"
            onChange={(e) => setEditSelectedType(e.target.value)}
            value={editSelectedType}
          >
            {types.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <select
            className="p-2 border rounded-md w-full mb-4"
            onChange={(e) => setEditSelectedStartTime(e.target.value)}
            value={editSelectedStartTime}
          >
            {shiftStartTimes.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
          <Button
            onClick={() =>
              handleEditSave(editSelectedType, editSelectedStartTime)
            }
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </Modal>

      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 backdrop-blur-md">
          <div className="absolute bg-white p-5 border w-96 shadow-lg rounded-md">
            <div className="mt-3 text-center">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <svg
                  className="h-6 w-6 text-blue-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              </div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Successful!
              </h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  Employee Roster has been successfully Uploaded!
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                  // onClick={() => setShowSuccessModal(false)} // Close the modal
                  onClick={handleModalCloseAndRedirect}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <nav aria-label="Page navigation example">
        <ul class="inline-flex items-center -space-x-px">
          <li>
            <a
              href="#"
              class="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={handlePreviousPage}
            >
              <span class="sr-only">Previous</span>
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </li>
          {getPageNumbersToShow().map((pageNumber) => (
            <li key={pageNumber}>
              <a
                href="#"
                className={`${
                  pageNumber === page
                    ? "z-10 text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                    : "text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                } px-3 py-2 leading-tight border border-gray-300 rounded-l-lg`}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </a>
            </li>
          ))}
          <li>
            <a
              href="#"
              class="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={handleNextPage}
            >
              <span class="sr-only">Next</span>
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </Layout>
  );
};

export default EmployeeShiftPage;
