import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import debounce from "lodash/debounce";
import ShowPopup from "../../components/Popup/ConfirmPopup";
import axios from "../../service/api";
import { Link } from "react-router-dom";
import { getRole } from "../../service/auth";
import EmployeeEditPopup from "../../components/Popup/Popup";
import ConfirmPopup from "../../components/Popup/ConfirmPopup";
import { FcDownload } from "react-icons/fc"; // Import the FcDownload icon

const ViewEmployee = () => {
  const [data, setData] = useState([]);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [showEmployeeEditPopup, setShowEmployeeEditPopup] = useState(false);
  const [employeeEditId, setEmployeeEditId] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [employeeFirstName, setEmployeeFirstName] = useState(null);
  const [employeeLastName, setEmployeeLastName] = useState(null);
  const [employeeDOB, setEmployeeDOB] = useState(null);
  const [employeeEmail, setEmployeeEmail] = useState(null);
  const [employeePhoneNumber, setEmployeePhoneNumber] = useState(null);
  const [employeeJoiningDate, setEmployeeJoiningDate] = useState(null);
  const [employeeGender, setEmployeeGender] = useState(null);
  const [employeePresentAddress, setEmployeePresentAddress] = useState(null);
  const [employeeCity, setEmployeeCity] = useState(null);
  const [employeePin, setEmployeePin] = useState(null);
  const [employeeBankName, setEmployeeBankName] = useState(null);
  const [employeeAccountNumber, setEmployeeAccountNumber] = useState(null);
  const [employeeIFSC, setEmployeeIFSC] = useState(null);
  const [employeeESICNumber, setEmployeeESICNumber] = useState(null);
  const [employeeUANNumber, setEmployeeUANNumber] = useState(null);
  const [employeeMaritalStatus, setEmployeeMaritalStatus] = useState(null);
  const [employeeSpouseName, setEmployeeSpouseName] = useState(null);
  const [employeeBloodGroup, setEmployeeBloodGroup] = useState(null);
  const [employeeHighestQualification, setEmployeeHighestQualification] =
    useState(null);
  const [employeeDegree, setEmployeeDegree] = useState(null);
  const [employeeUniversity, setEmployeeUniversity] = useState(null);
  const [employeeReportingManager, setEmployeeReportingManager] =
    useState(null);
  const [employeeRole, setEmployeeRole] = useState(null);
  const [employeeAadharNumber, setEmployeeAadharNumber] = useState(null);
  const [employeePanNumber, setEmployeePanNumber] = useState(null);
  const [employeeAadharPic, setEmployeeAadharPic] = useState(null);
  const [employeePanPic, setEmployeePanPic] = useState(null);
  const [employeeBloodGroupPic, setEmployeeBloodGroupPic] = useState(null);
  const [employeePassBookPic, setEmployeePassBookPic] = useState(null);
  const [employeeCancelledChequePic, setEmployeeCancelledChequePic] =
    useState(null);
  const [employeeHighestQualificationPic, setEmployeeHighestQualificationPic] =
    useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParams, setSearchParams] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggle = async (userId) => {
    try {
      const user = data.find((user) => user._id === userId);
      const isActive = !user.isActive;
      await axios.put(`/user/deactivateUser/${userId}`, { isActive });
      setData((prevData) =>
        prevData.map((user) =>
          user._id === userId ? { ...user, isActive: !user.isActive } : user
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenConfirmPopup = (employeeId) => {
    setEmployeeToDelete(employeeId);
    setShowConfirmPopup(true);
  };

  const handleCloseConfirmPopup = () => {
    setShowConfirmPopup(false);
  };

  const handleToggleAllRows = () => {
    if (selectedRows.length === data.length) {
      // If all rows are selected, unselect all
      setSelectedRows([]);
    } else {
      // Otherwise, select all rows
      setSelectedRows(data.map((employee) => employee._id));
    }
  };

  const handleToggleRow = (employeeId) => {
    if (selectedRows.includes(employeeId)) {
      // If the row is selected, unselect it
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => id !== employeeId)
      );
    } else {
      // Otherwise, select the row
      setSelectedRows((prevSelected) => [...prevSelected, employeeId]);
    }
  };

  useEffect(() => {
    const debouncedSearch = debounce(fetchEmployees, 1000); // Debounce the fetchEmployees function
    debouncedSearch();
    return () => {
      // Cleanup function to cancel the debounce timer
      debouncedSearch.cancel();
    };
    // // Fetch employee data from the server and update the state
    // fetchEmployees();
  }, [searchParams, page, perPage]);

  const handleConfirmed = async () => {
    // Call fetchEmployees to trigger the useEffect
    try {
      if (employeeToDelete) {
        await deleteEmployee(employeeToDelete);
        setShowConfirmPopup(false);
        fetchEmployees(); // Fetch the updated employee list
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditConfirmed = () => {
    fetchEmployees();
  };

  const handlePrevious = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleEditClick = (
    employeeId,
    employeePId,
    employeeEmail,
    employeeFirstName,
    employeeLastName,
    employeeDateOfBirth,
    employeeJoiningDate,
    employeeReportingTo,
    employeeAddress,
    employeeCity,
    employeePin,
    employeeBankName,
    employeeAccNumber,
    employeeIfsc,
    employeeEsicNo,
    employeeUanNo,
    employeeMarital,
    employeeSpouse,
    employeeBloodGroup,
    employeeHighestQualification,
    employeeDegree,
    employeeUniversity,
    employeeGender,
    employeePNumber,
    employeeRole,
    employeeAadharNo,
    employeePanNo,
    employeeAadharPic,
    employeePanPic,
    employeeBloodGroupPic,
    employeePassBookPic,
    employeeCancelledChequePic,
    employeeHighestQualificationPic
  ) => {
    // setPopupEdit(true);
    setEmployeeEditId(employeeId);
    setEmployeeId(employeePId);
    setEmployeeFirstName(employeeFirstName);
    setEmployeeLastName(employeeLastName);
    setEmployeeDOB(employeeDateOfBirth);
    setEmployeeJoiningDate(employeeJoiningDate);
    setEmployeeEmail(employeeEmail);
    setEmployeeGender(employeeGender);
    setEmployeePresentAddress(employeeAddress);
    setEmployeeCity(employeeCity);
    setEmployeePin(employeePin);
    setEmployeeBankName(employeeBankName);
    setEmployeeAccountNumber(employeeAccNumber);
    setEmployeeIFSC(employeeIfsc);
    setEmployeeESICNumber(employeeEsicNo);
    setEmployeeUANNumber(employeeUanNo);
    setEmployeeMaritalStatus(employeeMarital);
    setEmployeeSpouseName(employeeSpouse);
    setEmployeeBloodGroup(employeeBloodGroup);
    setEmployeeHighestQualification(employeeHighestQualification);
    setEmployeeDegree(employeeDegree);
    setEmployeeUniversity(employeeUniversity);
    setEmployeePhoneNumber(employeePNumber);
    setEmployeeReportingManager(employeeReportingTo);
    setEmployeeRole(employeeRole);
    setEmployeeAadharNumber(employeeAadharNo);
    setEmployeePanNumber(employeePanNo);
    setEmployeeAadharPic(employeeAadharPic);
    setEmployeePanPic(employeePanPic);
    setEmployeePassBookPic(employeePassBookPic);
    setEmployeeBloodGroupPic(employeeBloodGroupPic);
    setEmployeeCancelledChequePic(employeeCancelledChequePic);
    setEmployeeHighestQualificationPic(employeeHighestQualificationPic);
    setShowEmployeeEditPopup(true);
  };

  const closePopUp = () => {
    setShowEmployeeEditPopup(false);
  };

  const editEmployee = async (id) => {
    try {
      await axios.put(`/user/editUser/${id}`, {
        employeeId: employeeId,
        employeeFirstName: employeeFirstName,
        employeeLastName: employeeLastName,
        employeeDOB: employeeDOB,
        // employeeJoiningDate: employeeJoiningDate,
        employeeEmail: employeeEmail,
        employeeGender: employeeGender,
        employeePresentAddress: employeePresentAddress,
        employeeCity: employeeCity,
        employeePin: employeePin,
        employeeBankName: employeeBankName,
        employeeAccountNumber: employeeAccountNumber,
        employeeIFSC: employeeIFSC,
        employeeESICNumber: employeeESICNumber,
        employeeUANNumber: employeeUANNumber,
        employeeMaritalStatus: employeeMaritalStatus,
        employeeSpouseName: employeeSpouseName,
        employeeBloodGroup: employeeBloodGroup,
        employeeDegree: employeeDegree,
        employeeUniversity: employeeUniversity,
        employeePhoneNumber: employeePhoneNumber,
        employeeReportingManager: employeeReportingManager,
        employeeRole: employeeRole,
        employeeAadharNumber: employeeAadharNumber,
        employeePanNumber: employeePanNumber,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteEmployee = async (id) => {
    try {
      await axios.post(`/user/deleteUser/${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    setSearchParams(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleDownloadClick = async (empId) => {
    try {
      const response = await axios.get(`/user/downloadDoc/${empId}`, {
        responseType: "blob", // Important: Set the response type to 'blob'
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: "application/zip" });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${empId}.zip`; // Set the desired file name
      document.body.appendChild(link);

      // Simulate a click on the link to trigger the download
      link.click();

      // Clean up: remove the link and revoke the Blob URL
      link.remove();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading employee documents:", error);
    }
  };

  const pageLimit = 5;
  const totalItems = totalPages * pageLimit;

  const getPageNumbers = (totalPages) => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const pageNumbers = getPageNumbers(totalPages);

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / pageLimit);
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const getPageNumbersToShow = () => {
    const maxVisiblePages = 5;
    const middleIndex = Math.floor(maxVisiblePages / 2);
    const startIndex = Math.max(page - middleIndex, 1);
    const endIndex = Math.min(startIndex + maxVisiblePages - 1, totalPages);
    return pageNumbers.slice(startIndex - 1, endIndex);
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.post("/user/getUser", {
        page: page,
        perPage: perPage,
        // role: type,
        searchParams: searchParams,
      });
      setData(response.data.user);
      setTotalPages(Math.ceil(response.data.totalItems / perPage));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadEmployeeExcel = async () => {
    try {
      if (selectedRows.length === 0) {
        // setErrorMessage(true);
        // return;
        setErrorMessage("Please select a checkbox");

        //Set a timer to clear the error message after 5 sec....
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        return;
      }
      const url = `/user/downloadEmployeeExcel?checkedEmp=${selectedRows}`;

      const response = await axios.get(url, {
        responseType: "blob", // Indicate that the response should be treated as a Blob
      });

      // Create a URL for the Blob response
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element and trigger a click to download the file
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "Employee.xlsx");
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout isAdmin={getRole() === "Admin" || getRole() === "Stakeholders"}>
      {/* <div className="py-3"> */}
      <div className="w-full mt-0 bg-white shadow-2xl h-14 font-bold flex justify-center items-center">
        Employee Personal Details
      </div>
      <div class="p-2 flex flex-col md:flex-row items-center justify-between">
        {/* <label for="table-search" class="sr-only ">Search</label> */}
        <div class="relative mt-1 mb-2 md:mb-0 md:mr-2">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for users"
            onChange={handleSearch}
          />
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded"
          onClick={handleDownloadEmployeeExcel}
        >
          Download Employee
        </button>
        {errorMessage && <p className="text-red-500 mt-2 ml-2 md:ml-0">{errorMessage}</p>}
        <Link
          to="/employee"
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
        >
          Add Employee
        </Link>
      </div>
      <div class="overflow-x-auto mt-2">
        <div class="flex flex-col">
          <div class="w-full">
            <div class="border-b border-gray-200 shadow">
              <table class="w-full table-fixed divide-y divide-green-400 ">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="w-2 px-4 py-2 text-xs text-gray-500 text-center">
                      <input
                        type="checkbox"
                        checked={selectedRows.length === data.length}
                        onChange={handleToggleAllRows}
                        className="form-checkbox h-5 w-5 text-blue-500 cursor-pointer"
                      />
                    </th>
                    <th className="w-1/12 px-4 py-2 text-xs text-gray-500 text-center">
                      Emp ID
                    </th>
                    <th className="w-2/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Name
                    </th>
                    <th className="w-4/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Email
                    </th>
                    <th className="w-1/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Role
                    </th>
                    <th className="w-1/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Gender
                    </th>
                    {/* <th className="w-2/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Date Of Joining
                    </th> */}
                    <th className="w-fit px-6 py-2 text-xs text-gray-500 text-center">
                      Active
                    </th>
                    <th className="w-fit px-6 py-2 text-xs text-gray-500 text-center">
                      Edit
                    </th>
                    <th className="w-fit px-6 py-2 text-xs text-gray-500 text-center">
                      Delete
                    </th>
                    <th className="w-fit px-2 py-2 text-xs text-gray-500 text-center">
                      Download
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white divide-y divide-gray-300">
                  {data.length > 0 &&
                    data.map((employee, index) => {
                      const joiningDate = new Date(
                        employee.joiningDate
                      ).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      });
                      return (
                        <tr class="whitespace-nowrap" key={index}>
                          <td class="w-2 px-4 py-2 text-sm text-gray-500 text-center">
                            {/* Checkbox for selecting/deselecting individual row */}
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(employee._id)}
                              onChange={() => handleToggleRow(employee._id)}
                              className="form-checkbox h-5 w-5 text-blue-500 cursor-pointer"
                            />
                          </td>
                          <td class="w-1/12 px-6 py-4 text-sm text-gray-500 text-center">
                            {employee.empId}
                          </td>
                          <td class="w-2/12 px-6 py-4">
                            <div class="text-sm text-gray-900 text-center">
                              {employee.fullName}
                            </div>
                          </td>
                          <td class="w-5/12 px-6 py-4">
                            <div class="text-sm text-gray-500 text-center">
                              {employee.email}
                            </div>
                          </td>
                          <td class="w-1/12 px-6 py-4">
                            <div class="text-sm text-gray-500 text-center">
                              {employee.role}
                            </div>
                          </td>
                          <td class="w-1/12 px-6 py-4">
                            <div class="text-sm text-gray-500 text-center">
                              {employee.gender}
                            </div>
                          </td>
                          {/* <td class="w-2/12 px-6 py-4 text-sm text-gray-500 text-center">
                            {joiningDate}
                          </td> */}
                          {/* Toggle button Added Here */}
                          <td class="w-1/12 px-6 py-4 text-center">
                            <label class="relative inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                value=""
                                class="sr-only peer"
                                checked={employee.isActive}
                                onChange={() => handleToggle(employee._id)}
                              />
                              <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                          </td>
                          {/* Edit button Added Here */}
                          <td class="w-1/12 px-6 py-4 text-center">
                            <div class="flex justify-center items-center">
                              <a
                                href="#"
                                onClick={() => {
                                  handleEditClick(
                                    employee._id,
                                    employee.empId,
                                    employee.email,
                                    employee.firstName,
                                    employee.lastName,
                                    employee.dob,
                                    employee.joiningDate,
                                    employee.reportingManager,
                                    employee.presentAddress,
                                    employee.city,
                                    employee.presentAddressPin,
                                    employee.bankName,
                                    employee.accountNumber,
                                    employee.ifscCode,
                                    employee.esicNumber,
                                    employee.uanNumber,
                                    employee.maritalStatus,
                                    employee.spouseName,
                                    employee.bloodGroup,
                                    employee.highestQualification,
                                    employee.degree,
                                    employee.university,
                                    employee.gender,
                                    employee.phoneNumber,
                                    employee.role,
                                    employee.aadharNumber,
                                    employee.panNumber,
                                    employee.aadharPic,
                                    employee.panPic,
                                    employee.bloodGroupPic,
                                    employee.passBookPic,
                                    employee.cancelledChequePic,
                                    employee.highestQualificationPic
                                  );
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="w-6 h-6 text-blue-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 
                      2 0 112.828 
                      2.828L11.828 15H9v-2.828l8.586-8.586z"
                                  />
                                </svg>
                              </a>
                            </div>
                            {showEmployeeEditPopup && (
                              <EmployeeEditPopup
                                setPopupState={setShowEmployeeEditPopup}
                                handleClose={closePopUp}
                                inputs={["role"]}
                                isForAddNew={false}
                                setDefaultValue={{
                                  employeeId,
                                  employeeFirstName,
                                  employeeLastName,
                                  employeeDOB,
                                  employeeEmail,
                                  employeePhoneNumber,
                                  employeeJoiningDate,
                                  employeeReportingManager,
                                  employeePresentAddress,
                                  employeeCity,
                                  employeePin,
                                  employeeBankName,
                                  employeeAccountNumber,
                                  employeeIFSC,
                                  employeeESICNumber,
                                  employeeUANNumber,
                                  employeeMaritalStatus,
                                  employeeSpouseName,
                                  employeeBloodGroup,
                                  employeeHighestQualification,
                                  employeeDegree,
                                  employeeUniversity,
                                  employeeGender,
                                  employeeRole,
                                  employeeAadharNumber,
                                  employeePanNumber,
                                  employeeAadharPic,
                                  employeePanPic,
                                  employeeBloodGroupPic,
                                  employeePassBookPic,
                                  employeeCancelledChequePic,
                                  employeeHighestQualificationPic,
                                }}
                                addUrl={`/user`}
                                editUrl={`/user/editUser/${employeeEditId}`}
                                onConfirmed={handleEditConfirmed}
                                // setError={/* Pass the setError function here */}
                              />
                            )}
                          </td>
                          {/* Delete button Added Here */}
                          <td class="w-1/12 px-6 py-4 text-center">
                            <div class="flex justify-center items-center">
                              <a
                                href="#"
                                onClick={() => {
                                  handleOpenConfirmPopup(employee._id);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="w-6 h-6 text-red-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 
                      4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </a>
                            </div>
                            {showConfirmPopup && (
                              <ConfirmPopup
                                id={employeeToDelete}
                                message=" delete this user"
                                openPopup={handleOpenConfirmPopup}
                                handleClose={handleCloseConfirmPopup}
                                onConfirmed={handleConfirmed} //pass the callback function as a prop.
                              />
                            )}
                          </td>
                          {/* Download Button Added Here */}
                          <td class="w-1/12 px-2 py-4 text-center">
                            <div class="flex justify-center items-center">
                              <a
                                // href={`/download/${employee._id}`}
                                href="#"
                                // target="_blank"
                                onClick={() =>
                                  handleDownloadClick(employee.empId)
                                }
                                rel="noopener noreferrer"
                              >
                                <FcDownload size={24} />{" "}
                                {/* Use the FcDownload icon */}
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Apply Pagination Here */}
      <nav aria-label="Page navigation example">
        <ul class="inline-flex items-center -space-x-px">
          <li>
            <a
              href="#"
              class="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={handlePreviousPage}
            >
              <span class="sr-only">Previous</span>
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </li>
          {getPageNumbersToShow().map((pageNumber) => (
            <li key={pageNumber}>
              <a
                href="#"
                className={`${
                  pageNumber === page
                    ? "z-10 text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                    : "text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                } px-3 py-2 leading-tight border border-gray-300 rounded-l-lg`}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </a>
            </li>
          ))}
          <li>
            <a
              href="#"
              class="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={handleNextPage}
            >
              <span class="sr-only">Next</span>
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </li>
        </ul>
      </nav>
      <></>
    </Layout>
  );
};

export default ViewEmployee;
