import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import axios from "../../../service/api";
import ProtectRoute from "../../../utils/protectRoute";
import io from "socket.io-client";
import { getToken, getRole, logout, getUser } from "../../../service/auth";
import { useNotification } from "../../Context/NotificationContext";
import { RingLoader } from "react-spinners";

const DeliveredFiles = () => {
  const { showPushNotification } = useNotification();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showLoading, setShowLoading] = useState(true);
  const checkLogistics = getRole() === "Logistics";
  const checkTeamLead = getRole() === "Production InCharge";
  const checkProd = getRole() === "Production";

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    // callApi(page, "All");
    const socket = io.connect(
      // "https://staging.api.webathena.patelinfo.com"
      axios.defaults.baseURL
      // transports: ['websockets']
    );

    if(checkTeamLead  || checkProd){
    
    socket.on("newSimpleAllocation", (response) => {
      if(response.Allocation.some(allocation => allocation.member === getUser()?.email)){
      const fileName = response.filename || 'N/A';
      const priority = response.Priority === 'True' ? 'High Priority' : 'Low Priority';
      const turnaroundTime = response['TAT'] || 'N/A';

      // const title = `New File Received - ${fileName}`;
      // const body = `Priority: ${priority}, TAT: ${turnaroundTime}`;

      // debouncedCallApi(page, "All"); // Call the API to fetch the latest data
      showPushNotification(`New Repub File Received - ${fileName}`,{
          body: `Priority: ${priority}, TAT: ${turnaroundTime}`,
          icon: '/athena-icon.png'
          // url: response.result.url,
        });
      }
      })
      
      socket.on("newBulkAllocation", (response) => {
        if(response.Allocation.some(allocation => allocation.member === getUser()?.email)){
        const fileName = response.filename || 'N/A';
        const priority = response.Priority === 'True' ? 'High Priority' : 'Low Priority';
        const turnaroundTime = response['TAT'] || 'N/A';
  
        // const title = `New File Received - ${fileName}`;
        // const body = `Priority: ${priority}, TAT: ${turnaroundTime}`;
  
        // debouncedCallApi(page, "All"); // Call the API to fetch the latest data
        showPushNotification(`New Repub File Received - ${fileName}`,{
            body: `Priority: ${priority}, TAT: ${turnaroundTime}`,
            icon: '/athena-icon.png'
            // url: response.result.url,
          });
        }
        })
    }

    if(checkLogistics || checkTeamLead){
        socket.on("newMail", (response) => {
          // debouncedCallApi(page, "All"); // Call the API to fetch the latest data
          showPushNotification("New Repub File Received", {
            body: `${response.filename} received for waiting allocation`,
            icon: "/athena-icon.png",
          });
        // });
      // ]);
      // console.log("CHECK NEW ALLOCATION FILE>>>>", response);
    })
  }

    // debouncedCallApi(page, "All");

    return () => {
      socket.disconnect(); // Disconnect the socket when the component unmounts
    };
  }, []);

  const fetchData = async (page) => {
    try {
      setShowLoading(true);
      const response = await axios.get(`/files/deliveredFiles?page=${page}`);
      setData(response.data.data);
      setTotalPages(response.data.totalPages);
      // setCurrentPage(response.data.currentPage)
      setShowLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleGoToPage = (e) => {
    e.preventDefault();
    const pgNumber = parseInt(e.target.pgNumber.value);
    if (pgNumber >= 1 && pgNumber <= totalPages) {
      setCurrentPage(pgNumber);
    }
  };

  const generatePaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    let startPage = currentPage;
    let endPage = currentPage + maxButtonsToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = endPage - maxButtonsToShow + 1;
      startPage = startPage < 1 ? 1 : startPage;
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-4 mt-2 py-2 bg-gray-200 rounded-md ${
            i === currentPage ? "bg-blue-600 text-white" : "text-gray-700"
          } hover:bg-blue-400 hover:text-white`}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  return (
    <ProtectRoute>
      <Layout
        isStakeholders={getRole() === "Stakeholders"}
        isLogistics={getRole() === "Logistics"}
        isTL={getRole() === "Production InCharge"}
      >
        {showLoading ? (
          <div className="w-full mt-5 bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
            <RingLoader color="#000" size={50} />
            <span className="ml-2">Loading data...</span>
          </div>
        ) : data && data.length > 0 ? (
          <div className="w-full mt-5 bg-white shadow-2xl rounded-lg">
            <div
              className="overflow-x-auto mx-auto"
              style={{ maxWidth: "80vw" }}
            >
              <table className="min-w-full divide-y divide-gray-200">
                {/* Rest of the table rendering code here */}
                <thead>
                    <tr>
                      <th className="px-3 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Filename
                      </th>
                      <th className="px-3 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Publicatie
                      </th>
                      <th className="px-3 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Publisher
                      </th>
                      <th className="px-3 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Issue
                      </th>
                      <th className="px-3 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Release Date
                      </th>
                      <th className="px-3 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Received
                      </th>
                      <th className="px-3 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Pages
                      </th>
                      <th className="px-3 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Pubcode
                      </th>
                      <th className="px-3 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        TAT
                      </th>
                      {/* <th className="px-3 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th> */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {data.map((item, index) => (
                      <tr
                        key={index}
                        className={
                          Boolean(item.Priority.toLowerCase() === "true")
                            ? "bg-orange-200"
                            : ""
                        }
                      >
                        <td className="px-3 py-4 whitespace-nowrap text-xs">
                          {item.filename}
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap text-xs">
                          {item.Publicatie}
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap text-xs">
                          {item.Publisher}
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap text-xs">
                          {item.Issue}
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap text-xs">
                          {item["Release date"]}
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap text-xs">
                          {item["Received client"]}
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap text-xs">
                          {item["Number of pages"]}
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap text-sm">
                          {item.Pubcode}
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap text-sm">
                          {item["TAT"]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
              </table>
              <div className="w-full flex justify-end items-end mt-5 mb-10">
                <form onSubmit={handleGoToPage} className="mr-8">
                  <label>Enter Page Number:</label>
                  <input
                    type="number"
                    id="pgNumber"
                    className="w-20 h-10 mr-5 p-1"
                    defaultValue={currentPage}
                  />
                  <button
                    type="submit"
                    className="bg-blue-500 px-5 py-2 rounded-lg text-white"
                  >
                    GO
                  </button>
                </form>
                <div className="flex flex-wrap items-center space-x-1">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="flex mt-2 items-center px-4 py-2 text-gray-500 bg-gray-300 rounded-md"
                    disabled={currentPage === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M11 17l-5-5m0 0l5-5m-5 5h12"
                      />
                    </svg>
                  </button>
                  {generatePaginationButtons()}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="px-4 mt-2 py-2 text-gray-500 bg-gray-300 rounded-md hover:bg-blue-400 hover:text-white"
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full mt-5 bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
            No Delivered Files Found
          </div>
        )}
      </Layout>
    </ProtectRoute>
  );
};

export default DeliveredFiles;