import React, { useEffect, useState, useRef } from "react";
import Button from "../../Button/button";
// import "../styles/animation.scss";
import ErrorPoup from "../ErrorPopup";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import DataSubmittingPop from "../DataSubmitPopup";
import axios from "../../../service/api";

const animatedComponents = makeAnimated();

const BulkAllocationPopup = React.memo((props) => {
  const [users, setUsers] = useState([]);
  const [stages, setStages] = useState();
  const [checkedValue, setCheckedValue] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [isError, setErrorStatus] = useState(false);
  const [pageInput, setPageInput] = useState("");
  const [inputValue, setInpputValue] = useState("");
  const [isVisible, setVisibility] = useState(false);
  const [isMaformedInput, setIsMalformedInput] = useState(false);
  const [currentRow, setCurrentRow] = useState(0);
  const [isInputText, setIsInputText] = useState(false);
  const [isUserSelectionVisible, setUserSelectionVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProductionDisabled, setProductionDisabled] = useState(false);
  const [isQADisabled, setQADisabled] = useState(false);
  const [isFinalDisabled, setFinalDisabled] = useState(false);
  const isFirstTime = useRef(true);
  const valueInInputText = useRef(null);

  const isCheckedQA = props.checkedRows
    .some((row) => row.Allocation.some((al) => al.stage === "QA Assign"))
    .toString();

  const isCheckedProduction = props.checkedRows
    .some((row) =>
      row.Allocation.some((al) => al.stage === "Production Assign")
    )
    .toString();

  const isCheckedFinal = props.checkedRows
    .some((row) => row.Allocation.some((al) => al.stage === "Final Assign"))
    .toString();

  useEffect(() => {
    setProductionDisabled(isCheckedProduction === "true");
    setQADisabled(isCheckedQA === "true");
    setFinalDisabled(isCheckedFinal === "true");
  }, [isCheckedProduction, isCheckedQA, isCheckedFinal]);

  const getUsersFromLocalStorage = () => {
    const usersFromLocalStorage = localStorage.getItem("userDetailsMap");
    if (usersFromLocalStorage) {
      setUsers(JSON.parse(usersFromLocalStorage));
    }
  };

  const storeUsersMapInLocalStorage = (data) => {
    localStorage.setItem("userDetailsMap", JSON.stringify(data));
  };

  const getData = async () => {
    try {
      // const userDetailsCheck = localStorage.getItem("userDetailsMap");
      // if (userDetailsCheck) {
      //   setUsers(JSON.parse(userDetailsCheck));
      // } else {
      var user = await axios.post("/allocation/getUserAllocation", {
        role: ["Production", "Production InCharge", "Logistics"],
        isAllocation: true,
      });
      user = user.data.user;
      if (user?.length > 0) {
        user = user.map((value) => {
          return { value: value.email, label: value.fullName };
        });
        storeUsersMapInLocalStorage(user);
        setUsers(user);
      }
      // }
    } catch (error) {
      props.setError();
    }
  };

  const setRowNext = (updatedData, updatedDataArr, callback) => {
    if (props.checkedRows.length - 1 !== currentRow) {
      setCheckedValue("");
      setSelectedUser([]);
      setIsProcessing(false);
      if (valueInInputText.current != null) {
        // console.log(valueInInputText.current);
        valueInInputText.current.value = "";
      }
      setCurrentRow(currentRow + 1);
    } else {
      props.setCheckedRows(updatedData);
      props.setData(updatedDataArr);
      props.closePopUp();
      setCurrentRow(0);

      // if (typeof callback === "function") {
      //   callback(updatedData);
      // }
    }
  };

  const malformInputPopUp = () => {
    if (isMaformedInput)
      return (
        <ErrorPoup
          isShowAction={true}
          action={() => {
            setIsMalformedInput(false);
          }}
          message="please enter a valid message"
        />
      );
    else <></>;
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const sendData = async () => {
    // if(!isUserSelectionVisible){
    //   setIsMalformedInput(true);
    // } else {
    //   // var temp = [];
    //   var temp = [1, 5, 6]
    // }
    // if (!isUserSelectionVisible) {
    //   const textAreaText = valueInInputText.current.value.replaceAll(
    //     "\\n",
    //     "\n"
    //   );
    //   var arr = textAreaText.replace(/\n{,10}/, "\n").split("\n");

    //   var temp = [];
    //   arr.forEach((a) => {
    //     const regex = /^([^\s]+)\s+(\d{1,5})\s?-\s?(\d{1,5})$/;

    //     var match = regex.exec(a.trim());

    //     if (match !== null) {
    //       temp.push(a);
    //     } else {
    //       //error malformatted
    //       setIsMalformedInput(true);
    //       temp = [];
    //     }
    //   });
    // } else {
    //   temp = [1, 5, 6];
    // }

    // if (temp.length > 1) {
    let tempChecking = isUserSelectionVisible
      ? selectedUser.length == 0
      : checkedValue.trim().length === 0;
    console.log(selectedUser);
    console.log(tempChecking);
    console.log(tempChecking && checkedValue.trim().length === 0);

    if (!tempChecking && checkedValue.trim().length > 0) {
      setIsProcessing(true);
      let str = "";
      if (isUserSelectionVisible) {
        let tempUserArray = selectedUser.map((value) => value.value);
        str = tempUserArray.join(",");
      } else if(isInputText){
          // const textAreaText = valueInInputText.current.value.split('\n');
          const textAreaText = valueInInputText.current.value.replaceAll(
            "\\n",
            "\n"
          );

          const textAreaArr = textAreaText.replace(/\n{,10}/, "\n").split("\n");
          const combinedData = textAreaArr.map((line) => {
            const lastSpaceIndex = line.lastIndexOf(' ');
            const fullName = toTitleCase(line.slice(0, lastSpaceIndex));
            const pageRange = line.slice(lastSpaceIndex + 1);
            const matchedUser = users.find((u) => u.label === fullName);
            const email = matchedUser ? matchedUser.value : '';
            return `${email} ${pageRange}`;
          });
          str = combinedData.join("\n");
      } else {
        let tempUserArraySel = selectedUser.map((value) => value.value);
        const pageRanges = pageInput.split(" "); // Split the pageInput into an array of page ranges
        const combinedData = tempUserArraySel.map((user, index) => {
          return `${user} ${pageRanges[index]}`;
        });
        str = combinedData.join("\n");
        // str = temp.join("\n");
      }

      try {
        const updatedDataFiles = [...props.data];
        const response = await axios.post("/allocation/bulkAllocation", {
          id: props.checkedRows[currentRow]._id,
          type: props.checkedRows[currentRow].Pubcode,
          allocation: str,
          stage: checkedValue,
          pageNumber: props.checkedRows[currentRow]["Number of pages"],
          equal_distrbution: isUserSelectionVisible,
        });

        const updatedCheckedRows = response.data.file;
        const updatedCheckedRowsArray = [updatedCheckedRows];
        const dataIndex = updatedDataFiles.findIndex(
          (item) => item._id === updatedCheckedRows._id
        );
        if (dataIndex !== -1) {
          updatedDataFiles[dataIndex] = updatedCheckedRows;
        }
        setRowNext(updatedCheckedRowsArray, updatedDataFiles);
        // props.setCheckedRows([]);
      } catch (error) {
        if (error.response) {
          props.setError(error.response.data.message);
        }
        // if (error.response) {
        //   props.setError(error.response.data.message);
        // } else {
        //   props.setError("Unable to reach to server");
        // }
      }
    } else {
      setErrorStatus(true);
      if (isUserSelectionVisible) {
        setIsMalformedInput(true);
        // }
      }
    }
  };

  useEffect(() => {
    if (isFirstTime.current) {
      localStorage.removeItem(localStorage.getItem("userDetailsMap"));
      const storedOptions = getUsersFromLocalStorage();
      if (storedOptions) {
        setUsers(storedOptions);
      } else {
        getData();
      }
      isFirstTime.current = false;
    }
  }, []);

  return isProcessing ? (
    <DataSubmittingPop />
  ) : (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-20 z-20"
        onClick={() => {
          props.closePopUp();
          // props.setPopupState(false);
        }}
      ></div>
      {users ? (
        <div className="w-c-6 fixed space-y-4 px-8 py-10 h-46 flex flex-col justify-center items-center bg-white top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-30 rounded-lg">
          <div className="w-full flex flex-col items-center">
            <span className="text-purple-700 text-3xl mb-2">
              {props.checkedRows[currentRow].filename}
            </span>
            <span className="text-purple-700 text-3xl">
              Total Pages: {props.checkedRows[currentRow]["Number of pages"]}
            </span>
          </div>
          <div className="w-full flex justify-center items-center">
            <input
              checked={isUserSelectionVisible}
              onChange={(e) => {
                setUserSelectionVisible(e.target.checked);
              }}
              id="customCheckForName"
              type="checkbox"
            ></input>
            <label htmlFor="customCheckForName" className="ml-1">
              Equal Distribution
            </label>
          </div>
          <div className="w-full flex justify-center items-center ml-6">
            <input
              checked={isInputText}
              onChange={(e) => {
                setIsInputText(e.target.checked);
              }}
              id="customCheckForName"
              type="checkbox"
            ></input>
            <label htmlFor="customCheckForName" className="ml-1">
              Select User Input Text
            </label>
          </div>
          <div className="w-full px-10">
            <div className="flex items-center mt-2 ">
              {isUserSelectionVisible ? (
                <Select
                  className="w-full"
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    setSelectedUser(e);
                  }}
                  components={animatedComponents}
                  isMulti
                  options={users}
                />
              ) : (
                isInputText ? 
                <div className="w-full px-10 ml-4">
                  <div className="text-lg font-bold">Input Allocation:</div>
                <textarea
                  type="text"
                  rows="5"
                  ref={valueInInputText}
                  placeholder="Enter Allocation"
                  className="w-full pr-10 pl-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                /> 
                {/* <div className="text-lg font-bold mt-2">Pages:</div>
                <input
                  type="text"
                  value={pageInput}
                  onChange={(e) => setPageInput(e.target.value)}
                  placeholder="Enter Pages"
                  className="w-full pr-4 pl-2 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                /> */}
                </div>
                : 
                <div className="w-full px-10">
                  <div className="text-lg font-bold">Selected User:</div>
                  <div className="rounded-lg">
                    <Select
                      className="w-full"
                      closeMenuOnSelect={false}
                      onChange={(e) => {
                        setSelectedUser(e);
                      }}
                      components={animatedComponents}
                      isMulti
                      options={users}
                    />
                  </div>
                  <div className="text-lg font-bold mt-2">Pages:</div>
                  <input
                    type="text"
                    value={pageInput}
                    onChange={(e) => setPageInput(e.target.value)}
                    placeholder="Enter Pages"
                    className="w-full pr-4 pl-2 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                  />
                </div>
              )}
            </div>
          </div>
          {isVisible ? (
            <div className="w-full shadow-2xl relative pr-10 py-10  pl-4 rounded-lg ">
              <div className="w-full h-40 overflow-scroll py-2 px-5 top-0 left-0 absolute bg-white z-20">
                {users
                  .filter((val) =>
                    val.fullName
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  )
                  .map((val, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setVisibility(false);
                        setInpputValue(val.fullName);
                      }}
                      className="w-full my-2 cursor-pointer border-2 py-2 px-5 hover:bg-gray-300 rounded-md top-0 left-0 bg-white z-20"
                    >
                      {val.fullName}
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="flex">
            <div className="mr-5">
              <input
                type="checkbox"
                checked={checkedValue === "Production Assign"}
                onChange={(e) => {
                  setErrorStatus(false);
                  setCheckedValue("Production Assign");
                }}
                name="check"
                id="GFG"
                className="mr-2"
                disabled={isProductionDisabled}
              />{" "}
              Production
            </div>
            <div className="mr-5">
              <input
                type="checkbox"
                checked={checkedValue === "QA Assign"}
                onChange={(e) => {
                  setErrorStatus(false);
                  setCheckedValue("QA Assign");
                }}
                name="check"
                id="GFG"
                className="mr-2"
                disabled={isQADisabled}
              />{" "}
              QA/Final
            </div>

            {/* <div className="mr-5">
              <input
                type="checkbox"
                checked={checkedValue === "Final Assign"}
                onChange={(e) => {
                  setErrorStatus(false);
                  setCheckedValue("Final Assign");
                }}
                name="check"
                id="GFG"
                className="mr-2"
                disabled={isFinalDisabled}
              />{" "}
              Finalize
            </div> */}
          </div>
          {isError ? (
            <div className="w-full flex items-center justify-center">
              <span className="text-red-600">Please select a option</span>
            </div>
          ) : (
            <></>
          )}
          <div className="w-full flex justify-center items-center">
            <Button className="bg-blue-500 mx-2" onClick={() => sendData()}>
              Done
            </Button>
            <Button
              onClick={() => props.closePopUp()}
              className="bg-red-500 mx-2"
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {malformInputPopUp()}
    </>
  );
});

export default BulkAllocationPopup;
