import React, { useState } from 'react';
import 'tailwindcss/tailwind.css';


const DataSubmittingPop = () => {
    const [isVisible, setIsVisible] = useState(false);
  
    const handleClick = () => {
      setIsVisible(!isVisible);
    };
  
    return (
      <>
        {isVisible && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-20 z-20"></div>
            <div className={`w-96 fixed space-y-4 px-8 py-10 h-34 overflow-x-auto bg-white top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-30 rounded-lg`}>
              <div className="w-full rounded-full h-3 overflow-hidden bg-blue-300">
                <div className="animate-progress-bar w-1/4 block h-full bg-blue-500 rounded-full"></div>
              </div>
              <div className="flex justify-center">
                Please Wait
              </div>
            </div>
          </>
        )}
        <button onClick={handleClick}>Show Popup</button>
      </>
    );
  };


  export default DataSubmittingPop;