import React, { useState, useEffect, useRef } from "react";
import Status from "../../utils/Stage";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import Button from "../Button/button";
import axios from "../../service/api";
import ErrorPopup from "./ErrorPopup";
import DataSubmittingPop from "./DataSubmitPopup";

const animatedComponents = makeAnimated();

const EmailPopup = React.memo((props) => {
  const [isError, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSending, setIsSending] = useState(false);
  // const [currentRow, setCurrentRow] = useState(0);
  // const [selectedRows, setSelectedRows] = useState([]);
  const [currentRow, setCurrentRow] = useState(0);
  const [selectedRows, setSelectedRows] = useState([...props.row]);
  const valueInInputText = useRef(null);


  // useEffect(() => {
  //   setSelectedRows([]);
  // }, [props.row]);


  const editPopupRef = useRef(null);
  // const setRowNext = (updatedData, updatedDataArr) => {
  //   // if (props.row.length - 1 !== currentRow) {
  //   //   setIsProcessing(false);
  //   //   setCurrentRow(currentRow + 1);
  //   //   setAllocationArray([...props.row[currentRow + 1].Allocation]);
  //   // } else {


  //     props.setCheckedRows(updatedData)
  //     props.setData(updatedDataArr);
  //     // if (typeof window !== `undefined`) {
  //     //   window.location.reload();
  //     // }

  //     props.onCancel();
  //     // setCurrentRow(0);
  //   // }
  // };

  
  const sendEmailsToClient = async () => {
    try {

      setIsSending(true);

      // const updatedDataFiles = [...props.data];
      // Send emails for all selected rows
      const fileIds = selectedRows.map(row => row._id);

    // Send emails for all selected rows
    const response = await axios.post("/files/sendEmailToClient", { fileIds });

    // if (typeof window !== `undefined`) {
    //   window.location.reload();
    // }

    // Extract updated files from the response
    // const updatedFiles = response.data.files;

    // // Update the parent component's data
    // const updatedDataFiles = props.data.map(data => {
    //   const updatedFile = updatedFiles.find(file => file._id === data._id);
    //   return updatedFile ? updatedFile : data;
    // });

    // const updatedCheckedRows = props.row.map(row => {
    //   const updatedFile = updatedFiles.find(file => file._id === row._id);
    //   return updatedFile ? updatedFile : row;
    // });

    const updatedDataFiles = [...props.data];
    const updatedCheckedRows = response.data.files;
    const updatedCheckedRowsArray = [updatedCheckedRows];

    updatedCheckedRows.forEach(updatedCheckedRow => {
      const dataIndex = updatedDataFiles.findIndex((item) => item._id === updatedCheckedRow._id);
      if(dataIndex !== -1){
        updatedDataFiles[dataIndex] = updatedCheckedRow;
      }
    })

    const filteredDataFiles = updatedDataFiles.filter(file => file.Status !== Status.DELIVERED);
    props.setData(filteredDataFiles);
    props.setCheckedRows([]);
    props.refreshList();


    //   // Update the parent component's data
    //   props.setData(updatedDataFiles);
    //   props.setCheckedRows([])
      // Close the popup
      setIsSending(false);
      props.onCancel();
    } catch (error) {
      setError(true);
    }
  };


  const handleOutsideClick = (e) => {
    if (editPopupRef.current && !editPopupRef.current.contains(e.target)) {
      // props.setPopupState(false);
      props.onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);


  if (isError) {
    return (
      <ErrorPopup
        message={isError}
        isShowAction={true}
        action={() => {
          props.onCancel();
        }}
      />
    );
  }
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-20 z-20"></div>
      <div className="w-4/5 fixed px-8 py-10 h-c-6 overflow-x-auto bg-white top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-30 rounded-lg shadow-lg">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-3xl font-bold mb-4 text-blue-900">Email Review</h1>
        </div>
        <div className="grid grid-cols-2 gap-4 max-h-96 overflow-y-auto">
          {selectedRows.map((row, index) => {
            return (
              <div key={index} className="shadow bg-gray-100 rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-blue-900">Email #{index + 1}</h2>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  onClick={(e) => {
                    e.stopPropagation(); 
                    props.row.splice(index, 1); 
                    setSelectedRows([...props.row]); 
                  }}
                  className="cursor-pointer"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#fc3d03"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59 7 17 8.41 13.41 12 17 15.59z" />
                </svg>
              </div>
              <div className="mt-4">
                <p className="text-lg text-gray-800"><strong>Subject:</strong> Delivery_New Feed - {row.Pubcode}</p>
                <p className="text-base text-gray-700 mt-4"><strong>Dear Team,</strong></p>
                <p className="text-base text-gray-700 mt-6">We have published the below feed: <br />
                <span className="font-medium">{row.filename.replace(".zip", "").replace(".pdf", "").replace("_feed", "").replace("_original", "")}</span>
                </p>
              
                {/* Placeholder for closing remarks */}
                <p className="text-base text-gray-700 mt-6">Regards,<br />Production Team</p>
              </div>
            </div>
            );
           
          })}
        </div>
        <div className="mt-8 flex justify-center items-center">
          <Button
            className="bg-blue-500 text-white mx-2"
            onClick={() => {
              sendEmailsToClient();
            }}
          >
             {isSending ? "Sending..." : "Send Emails"}
          </Button>
          <Button
            onClick={() => {
              props.onCancel();
            }}
            className="bg-red-500 text-white mx-2"
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
  
  
});

export default EmailPopup;
