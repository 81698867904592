export default class Status {
    static NOT_DOWNLOADED = "Not Downloaded"
    static WAITING_ALLOCATION = "Waiting Allocation"
    static ALLOCATED = "Allocated"
    static PROD_UN_PROCESS = "Production Under Process";
    static PROD_COMPLETED = "Production Completed";
    static WAITING_FOR_QA = "Waiting For QA";
    static QA_UNDER_PROCESS = "QA Under Progress";
    static QACOMP = "QA Completed";
    static WAITING_FOR_FINALIZE = "Waiting For Finalization";
    static FINALIZE_UN_PROCESS = "Finalize Under Process";
    static READY_DELIVER = "Ready For Delivery";
    static DELIVERED = "Delivered";
    static statusArray = [
      "Not Downloaded",
      "Waiting Allocation",
      "Allocated", // Production
      "Production Under Process",
      "Production Completed",
      "Waiting For QA", // QA
      "QA Under Progress",
      "QA Completed",
      "Waiting For Finalization", // FInalize
      "Finalize Under Process",
      "Waiting Delivery", // X
      "Delivered",
    ];
    static statusStageArray = [
      "Production Assign",
      "QA Assign",
      // "Final Assign"
    ]
  }
  