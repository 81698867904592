import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { getRole } from "../../service/auth";
import axios from "../../service/api";
import debounce from "lodash/debounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { format } from 'date-fns';

const EmpActivity = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParams, setSearchParams] = useState("");
  const [punchInDate, setPunchInDate] = useState(
      format(new Date(), 'yyyy-MM-dd')
  );
  const [punchOutDate, setPunchOutDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  // const [availabilities, setAvailabilities] = useState([
  //   "Early Morning Shift",
  //   "Morning Shift",
  //   "Day Shift",
  //   "Evening Shift",
  //   "Night Shift",
  // ]);
  const [departments, setDepartments] = useState([
    "Admin",
    "Logistics",
    "Production InCharge",
    "Production",
  ]);

  useEffect(() => {
    const debouncedSearch = debounce(fetchActivity, 1000); // Debounce the fetchEmployees function
    debouncedSearch();
    return () => {
      // Cleanup function to cancel the debounce timer
      debouncedSearch.cancel();
    };
  }, [
    searchParams,
    page,
    perPage,
    fromDate,
    toDate,
    selectedDepartment,
    punchInDate,
    punchOutDate,
  ]);

  const pageLimit = 5;
  const totalItems = totalPages * pageLimit;

  const getPageNumbers = (totalPages) => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / pageLimit);
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePunchInDate = (date) => {
    if (date instanceof Date) {
      // const formattedDate = date.toISOString().split("T")[0];
      const formattedDate = format(date, 'yyyy-MM-dd');
      setPunchInDate(formattedDate);
    }
  };

  const handlePunchOutDate = (date) => {
    if (date instanceof Date) {
      // const formattedDate = date.toISOString().split("T")[0];
      const formattedDate = format(date, 'yyyy-MM-dd');
      setPunchOutDate(formattedDate);
    }
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    setSearchParams(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const getPageNumbersToShow = () => {
    const maxVisiblePages = 5;
    const middleIndex = Math.floor(maxVisiblePages / 2);
    const startIndex = Math.max(page - middleIndex, 1);
    const endIndex = Math.min(startIndex + maxVisiblePages - 1, totalPages);
    return pageNumbers.slice(startIndex - 1, endIndex);
  };

  const fetchActivity = async () => {
    try {
      const response = await axios.post("/user/userActivity", {
        page: page,
        perPage: perPage,
        searchParams: searchParams,
        department: selectedDepartment,
        punchInDate: punchInDate,
        punchOutDate: punchOutDate,
        fromDate: fromDate,
        toDate: toDate,
      });
      setData(response.data.data);
      setTotalPages(Math.ceil(response.data.totalItems / perPage));
    } catch (error) {
      console.log(error);
    }
  };

  const pageNumbers = getPageNumbers(totalPages);

  const handleDownloadExcel = async () => {
    try {
      const url = `/user/downloadExcel?fromDate=${fromDate}&toDate=${toDate}`;

      const response = await axios.get(url, {
        responseType: "blob", // Indicate that the response should be treated as a Blob
      });

      // Create a URL for the Blob response
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element and trigger a click to download the file
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "attendance.xlsx");
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.log(error);
    }
  };

  // Add this function before the EmpActivity component
  const getShiftColor = (shift) => {
    switch (shift.toLowerCase()) {
      case "early morning shift":
        return "bg-green-400";
      case "morning shift":
        return "bg-blue-400";
      case "day shift":
        return "bg-teal-500";
      case "evening shift":
        return "bg-yellow-400";
      case "night shift":
        return "bg-gray-900";
      default:
        return "bg-gray-200";
    }
  };

  return (
    <Layout isAdmin={getRole() === "Admin" || getRole() === "Stakeholders"}>
      <div className="p-4 md:flex md:items-center md:justify-between">
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-6 p-4">
          {/* Department Filter */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Department
            </label>
            <select
              className="mt-1 p-2 border rounded-md w-full"
              onChange={(e) => setSelectedDepartment(e.target.value)}
              value={selectedDepartment}
            >
              <option value="">All</option>
              {departments.map((department) => (
                <option key={department} value={department}>
                  {department}
                </option>
              ))}
            </select>
          </div>

          {/* Shift Filter */}
          {/* <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Shift
            </label>
            <select
              className="mt-1 p-2 border rounded-md w-full"
              // onChange={(e) => setSelectedAvailability(e.target.value)}
              // value={selectedAvailability}
            >
              {availabilities.map((availability) => (
                <option key={availability} value={availability}>
                  {availability}
                </option>
              ))}
            </select>
          </div> */}

          {/* Date Filter */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Punch In Date
            </label>
            <DatePicker
              className="mt-1 p-2 border rounded-md w-full"
              placeholderText="Choose Date"
              selected={punchInDate ? new Date(punchInDate) : null}
              onChange={(date) => handlePunchInDate(date)}
              // isClearable
              // onBlur={() => {
              //   if (punchInDate) {
              //     setPunchInDate("")
              //   }
              // }}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Punch Out Date
            </label>
            <DatePicker
              className="mt-1 p-2 border rounded-md w-full"
              placeholderText="Select Date"
              selected={punchOutDate ? new Date(punchOutDate) : null}
              onChange={(date) => handlePunchOutDate(date)}
              isClearable
              onBlur={() => {
                if (punchOutDate) {
                  setPunchOutDate("")
                }
              }}
              maxDate={new Date()}
            />
          </div>

          <div class="flex items-center mb-2">
            <div class="mr-4">
              <label className="block text-sm font-medium text-gray-700">
                From Date
              </label>
              <div className="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  name="start"
                  type="date"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select date start"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  // max={new Date().toISOString().split('T')[0]}
                />
              </div>
            </div>
            <span class="mr-4 flex justify-center text-gray-500">to</span>
            <div class="mr-4">
              <label className="block text-sm font-medium text-gray-700">
                End Date
              </label>
              <div className="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  name="end"
                  type="date"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select date end"
                  value={toDate}
                  onChange={handleToDateChange}
                  // max={new Date().toISOString().split('T')[0]}
                />
              </div>
            </div>
            <div className="mt-2">
              <IconButton
                color="primary"
                className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-6 mb-4 md:p-2 md:ml-2 lg:p-2 lg:ml-2 xl:p-2 xl:ml-2"
                onClick={handleDownloadExcel}
              >
                <CloudDownloadIcon fontSize="large" />
                {/* <ArrowDropDownIcon /> */}
              </IconButton>
            </div>
          </div>
          <div className="mb-2 relative col-span-full md:col-span-2 lg:col-span-3">
            <label className="sr-only">Search</label>
            <input
              type="text"
              className="p-2 border rounded-md w-7/12 border-gray-400 focus:border-gray-400"
              placeholder="Search..."
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      <div class="overflow-x-auto mt-2">
        <div class="flex flex-col">
          <div class="w-full">
            <div class="border-b border-gray-200 shadow">
              <table class="w-full table-fixed divide-y divide-green-400 ">
                <thead class="bg-gray-50">
                  <tr>
                    {/* <th class="w-1/12 px-4 py-2 text-xs text-gray-500 text-center">
                      Emp ID
                    </th> */}
                    <th class="w-1/12 px-4 py-2 text-xs text-gray-500 text-center">
                      Emp Id
                    </th>
                    {/* <th class="w-3/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Email
                    </th> */}
                    <th class="w-1/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Full Name
                    </th>
                    <th class="w-1/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Shift
                    </th>
                    <th class="w-1/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Punch IN
                    </th>
                    <th class="w-1/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Punch Out
                    </th>
                    <th class="w-1/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Online Athena
                    </th>
                    {/* <th class="w-1/12 px-6 py-2 text-xs text-gray-500 text-center">
                      Is Active
                    </th> */}
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-300">
                  {data.length > 0 &&
                    data.map((employee, index) => {
                      return (
                        <tr class="whitespace-nowrap" key={index}>
                          {/* <td class="w-1/12 px-6 py-4 text-sm text-gray-500 text-center">
                            {employee.empId}
                          </td> */}
                          <td class="w-1/12 px-6 py-4">
                            <div class="text-sm text-gray-900 text-center">
                              {employee.empId}
                            </div>
                          </td>
                          {/* <td class="w-3/12 px-6 py-4">
                            <div class="text-sm text-gray-500 text-center">
                              {employee.email}
                            </div>
                          </td> */}
                          <td class="w-1/12 px-6 py-4">
                            <div class="text-sm text-gray-500 text-center">
                              {employee.fullName}
                            </div>
                          </td>
                          <tr
                            class={`whitespace-nowrap ${getShiftColor(
                              employee.shift
                            )}`}
                            key={index}
                          >
                            <td class="w-1/12 px-6 py-4">
                              <div class="text-sm text-gray-500 text-center font-bold">
                                {employee.shift}
                              </div>
                            </td>
                          </tr>
                          <td class="w-1/12 px-6 py-4">
                            <div class="text-sm text-gray-500 text-center">
                              {employee.PunchInTime
                                ? employee.PunchInTime
                                : "-"}
                            </div>
                          </td>
                          <td class="w-1/12 px-6 py-4">
                            <div class="text-sm text-gray-500 text-center">
                              {employee.PunchOutTime
                                ? employee.PunchOutTime
                                : "-"}
                            </div>
                          </td>
                          <td class="w-1/12 px-6 py-4">
                            <div class="text-sm text-gray-500 text-center">
                              {employee.hoursOnline
                                ? `${parseFloat(
                                    employee.hoursOnline.toFixed(2)
                                  )} hrs`
                                : "-"}
                            </div>
                          </td>
                          {/* <td class="w-1/12 px-6 py-4">
                            <div class="text-sm text-gray-500 text-center">
                              {employee.isActive.toString()}
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="inline-flex items-center -space-x-px">
          <li>
            <a
              href="#"
              class="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={handlePreviousPage}
            >
              <span class="sr-only">Previous</span>
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </li>
          {getPageNumbersToShow().map((pageNumber) => (
            <li key={pageNumber}>
              <a
                href="#"
                className={`${
                  pageNumber === page
                    ? "z-10 text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                    : "text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                } px-3 py-2 leading-tight border border-gray-300 rounded-l-lg`}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </a>
            </li>
          ))}
          <li>
            <a
              href="#"
              class="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={handleNextPage}
            >
              <span class="sr-only">Next</span>
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </Layout>
  );
};

export default EmpActivity;
