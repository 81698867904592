import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
// import { useParams } from "react-router-dom";
import { getUser, getRole } from "../../service/auth";
import { useNotification } from "../../components/Context/NotificationContext";
import { MdEventAvailable, MdOutlineEventAvailable } from "react-icons/md";
import { TbCalendarTime } from "react-icons/tb";
import { LuCalendarDays } from "react-icons/lu";
import { FcLeave } from "react-icons/fc";
import io from "socket.io-client";
import axios from "../../service/api";
import ChartBar from "./ChartBar";
import { ReactComponent as LoadingSpinner } from "../../assets/loading-spinner.svg";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";

const UserProfile = () => {
  const id = getUser()?.id;
  const { showPushNotification } = useNotification();
  const [userDetails, setUserDetails] = useState("");
  const [activeTab, setActiveTab] = useState("profile");
  const [hoveredValue, setHoveredValue] = useState(null);
  const [profilePic, setProfilePic] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const checkLogistics = getRole() === "Logistics";
  const checkTeamLead = getRole() === "Production InCharge";
  const checkProd = getRole() === "Production";
  const localizer = momentLocalizer(moment);
  const [shiftDetails, setShiftDetails] = useState([]);

  const handleTabClick = async (tab) => {
    setActiveTab(tab);

    if (tab === "shiftInfo" && shiftDetails.length === 0) {
      try {
        const response = await axios.get(`/user/getShiftDetail`);
        setShiftDetails(response.data.shiftDetails);
      } catch (error) {
        console.error("Error fetching shift details:", error);
      }
    }
  };

  const callApi = async (endpoint) => {
    try {
      const response = await axios.get(endpoint);
      return response.data.data[0];
    } catch (error) {
      console.error("Error calling API:", error);
      throw error;
    }
  };

  const formattedShiftDetails = shiftDetails.map((shift) => ({
    ...shift,
    date: new Date(shift.date),
    shiftStartDate: new Date(shift.shiftStartDate),
    shiftEndDate: new Date(shift.shiftEndDate),
  }));

  useEffect(() => {
    const fetchUserDetails = async () => {
      const profileName = getUser().profilePic.split("/")[3];
      const endpoint = `/user/aboutMe/${id}`;
      const profilePicEndpoint = `/user/profile-pic/${profileName}`; // Update with the correct API endpoint
      try {
        const data = await callApi(endpoint);
        setUserDetails(data);
        setIsLoading(false); // Mark loading as complete

        const profilePicResponse = await axios.get(profilePicEndpoint, {
          responseType: "arraybuffer",
        });
        const arrayBufferView = new Uint8Array(profilePicResponse.data);
        const blob = new Blob([arrayBufferView], { type: "image/*" });
        const dataUrl = URL.createObjectURL(blob);
        setProfilePic(dataUrl);
      } catch (error) {
        console.log(error);
        setIsLoading(false); // Mark loading as complete even on error
      }
    };

    fetchUserDetails();

    const socket = io.connect(axios.defaults.baseURL);

    if (checkTeamLead || checkProd) {
      socket.on("newSimpleAllocation", (response) => {
        if (
          response.Allocation.some(
            (allocation) => allocation.member === getUser()?.email
          )
        ) {
          const fileName = response.filename || "N/A";
          const priority =
            response.Priority === "True" ? "High Priority" : "Low Priority";
          const turnaroundTime = response["TAT"] || "N/A";

          showPushNotification(`New Repub File Received - ${fileName}`, {
            body: `Priority: ${priority}, TAT: ${turnaroundTime}`,
            icon: "/athena-icon.png",
          });
        }
      });

      socket.on("newBulkAllocation", (response) => {
        if (
          response.Allocation.some(
            (allocation) => allocation.member === getUser()?.email
          )
        ) {
          const fileName = response.filename || "N/A";
          const priority =
            response.Priority === "True" ? "High Priority" : "Low Priority";
          const turnaroundTime = response["TAT"] || "N/A";

          // const title = `New File Received - ${fileName}`;
          // const body = `Priority: ${priority}, TAT: ${turnaroundTime}`;

          // debouncedCallApi(page, "All"); // Call the API to fetch the latest data
          showPushNotification(`New Repub File Received - ${fileName}`, {
            body: `Priority: ${priority}, TAT: ${turnaroundTime}`,
            icon: "/athena-icon.png",
            // url: response.result.url,
          });
        }
      });
    }

    if (checkLogistics || checkTeamLead) {
      socket.on("newMail", (response) => {
        showPushNotification("New Repub File Received", {
          body: `${response.filename} received for waiting allocation`,
          icon: "/athena-icon.png",
        });
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [id]);

  // const dataPoints = [
  //   { label: "Mon", value: 4 },
  //   { label: "Tue", value: 7 },
  //   { label: "Wed", value: 5 },
  //   { label: "Thurs", value: 2 },
  //   { label: "Fri", value: 6 },
  //   { label: "Sat", value: 3 },
  //   { label: "Sun", value: 9 },
  // ];
  const dataPointValues =
    userDetails?.weeklyTimeLogs?.map((dataPoint) => dataPoint.value) || [];
  // const dataPointValues = dataPoints.map((dataPoint) => dataPoint.value);
  // const totalMaximum = Math.max(...dataPointValues);
  const totalMaximum = 12;

  const handleBarHover = (value) => {
    setHoveredValue(value);
  };

  const formatTotalExperience = (experience) => {
    return `${experience.years} year ${experience.months} months`;
  };

  return (
    <Layout
      isStakeholders={getRole() === "Stakeholders"}
      isAdmin={getRole() === "Admin"}
      isLogistics={getRole() === "Logistics"}
      isTL={getRole() === "Production InCharge"}
      isProd={getRole() === "Production"}
    >
      {isLoading ? (
        <div className="flex items-start justify-center h-screen pb-44">
          {/* Use the loading spinner component */}
          <LoadingSpinner className="w-16 h-16 animate-spin text-teal-500" />
        </div>
      ) : userDetails ? (
        <div className="min-w-full rounded-none shadow-lg p-8 md:p-12 mx-2 mt-14 md:mx-auto bg-white min-h-screen relative">
          <div className="flex items-center flex-col md:flex-row">
            <img
              src={profilePic}
              alt="Profile"
              className="w-24 h-24 rounded-full object-cover border-black mr-2 mb-28"
            />
            <div className="md:mr-6 mb-28 ml-2">
              <h1 className="text-2xl md:text-3xl font-bold mb-2 text-left">
                {userDetails.fullName}
              </h1>
              <p className="text-slate-600 text-2xl mb-2 text-left">
                {userDetails.email}
              </p>
            </div>

            <div className="flex space-x-10 mt-6">
              <button
                onClick={() => handleTabClick("profile")}
                className={`${
                  activeTab === "profile"
                    ? "border-b border-teal-500 text-teal-500 pb-2"
                    : "text-gray-800"
                } font-bold text-xl`}
              >
                My Profile
              </button>
              <button
                onClick={() => handleTabClick("shiftInfo")}
                className={`${
                  activeTab === "shiftInfo"
                    ? "border-b border-teal-500 text-teal-500 pb-2"
                    : "text-gray-800"
                } font-bold text-xl`}
              >
                Shift Info
              </button>
            </div>
          </div>

          {activeTab === "profile" && (
            <>
              {/* Main div */}
              <div className="md:flex md:space-x-4">
                {/* Left Section  */}
                <div className="md:flex-1">
                  <div className="flex flex-row md:mt-4">
                    {/* Department */}
                    <div className="border-l-2 border-teal-500 md:flex-1 md:h-auto px-4 space-y-4">
                      <p className="text-gray-900 font-semibold">
                        {userDetails.empId}
                      </p>
                      <p className="text-gray-700">Employee ID</p>
                    </div>
                    <div className="border-l-2 border-green-500 md:flex-1 md:h-500 px-4 space-y-4">
                      <p className="text-gray-900 font-semibold">
                        {userDetails.role}
                      </p>
                      <p className="text-gray-700">Department</p>
                    </div>
                    <div className="border-l-2 border-purple-500 md:flex-1 md:h-500 px-4 space-y-4">
                      <p className="text-gray-900 font-semibold">
                        {userDetails.previousExperience}
                      </p>
                      <p className="text-gray-700">Previous Experience</p>
                    </div>
                  </div>

                  <div className="flex flex-row md:mt-10">
                    <div className="border-l-2 border-blue-500 md:flex-1 md:h-500 px-4 space-y-4">
                      <p className="text-gray-900 font-semibold">
                        {userDetails.reportingManager}
                      </p>
                      <p className="text-gray-700">Reporting Manager</p>
                    </div>
                    <div className="border-l-2 border-blue-500 md:flex-1 md:h-500 px-4 space-y-4">
                      <p className="text-gray-900 font-semibold">
                        {formatTotalExperience(userDetails.totalExperience)}
                      </p>
                      <p className="text-gray-700">Total Experience</p>
                    </div>
                    <div className="border-l-2 border-orange-500 md:flex-1 md:h-500 px-4 space-y-4">
                      <p className="text-gray-900 font-semibold">
                        {userDetails.joiningDate}
                      </p>
                      <p className="text-gray-700">Joining Date</p>
                    </div>
                  </div>
                  {/* <div className="flex flex-col md:flex-row mt-10 space-y-4 md:space-y-0 md:space-x-4">
                    <div className="flex items-center p-4 bg-slate-100 rounded-lg shadow-xs w-1/2">
                      <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                        <TbCalendarTime className="w-5 h-5" />
                      </div>
                      <div className="flex-1">
                        <p className="text-lg font-semibold text-gray-700">
                          {"50"}
                        </p>
                        <p className="mb-2 text-sm font-medium text-gray-600">
                          Available Days
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center p-4 bg-slate-100 rounded-lg shadow-xs w-1/2">
                      <div className="p-3 mr-4 text-blue-500 bg-green-100 rounded-full">
                        <LuCalendarDays className="w-5 h-5" />
                      </div>
                      <div className="flex-1">
                        <p className="text-lg font-semibold text-gray-700">
                          {"30"}
                        </p>
                        <p className="mb-2 text-sm font-medium text-gray-600">
                          Paid Leave
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center p-4 bg-slate-100 rounded-lg shadow-xs w-1/2">
                      <div className="p-3 mr-4 text-green-500 bg-red-100 rounded-full">
                        <FcLeave className="w-5 h-5" />
                      </div>
                      <div className="flex-1">
                        <p className="text-lg font-semibold text-gray-700">
                          {"20"}
                        </p>
                        <p className="text-sm font-medium text-gray-600">
                          Leave Taken
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center p-4 bg-slate-100 rounded-lg shadow-xs w-1/2">
                      <div className="p-3 mr-4 text-teal-500 bg-purple-100 rounded-full">
                        <MdEventAvailable className="w-5 h-5" />
                      </div>
                      <div className="flex-1">
                        <p className="text-lg font-semibold text-gray-700">
                          {"18"}
                        </p>
                        <p className="mb-2 text-sm font-medium text-gray-600">
                          Leave Approved
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="pr-6 md:pr-10">
                    <h2 className="text-xl font-bold mb-4 mt-10">
                      Current Week Time Log
                    </h2>
                    <div className="flex justify-between h-40">
                      {userDetails.weeklyTimeLogs.map((dataPoint) => (
                        <div
                          key={dataPoint.label}
                          className="relative w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-4 md:mb-6"
                          onMouseEnter={() => handleBarHover(dataPoint.value)}
                          onMouseLeave={() => handleBarHover(null)}
                        >
                          <ChartBar
                            //  key={dataPoint.label}
                            value={dataPoint.value}
                            maxValue={totalMaximum}
                            label={dataPoint.label}
                          />
                          {hoveredValue === dataPoint.value && (
                            <span className="absolute top-[-60px] right-1/2 transform -translate-x-1/2 bg-gray-100 text-sm rounded-md">
                              {dataPoint.value} Hrs
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex justify-between">
                  {/* Personal Information */}
                  <div className="pl-10">
                    <div className="ml-auto max-w-lg space-y-4 bg-slate-100 p-8">
                      <h2 className="text-xl font-semibold mb-4">
                        Personal Information
                      </h2>
                      <div className="flex flex-row items-center">
                        <label className="text-slate-600 w-72">Name:</label>
                        <span className="flex-1 font-bold">
                          {userDetails.fullName}
                        </span>
                      </div>
                      <div className="flex flex-row items-center">
                        <label className="text-slate-600 w-72">Gender:</label>
                        <span className="flex-1 font-bold">
                          {userDetails.gender}
                        </span>
                      </div>
                      <div className="flex flex-row items-center">
                        <label className="text-slate-600 w-72">
                          Reporting To:
                        </label>
                        <span className="flex-1 font-bold">
                          {userDetails.reportingManager}
                        </span>
                      </div>
                      <div className="flex flex-row items-center">
                        <label className="text-slate-600 w-72">
                          Date Of Birth:
                        </label>
                        <span className="flex-1 font-bold">
                          {userDetails.dob}
                        </span>
                      </div>
                      <div className="flex flex-row items-center">
                        <label className="text-slate-600 w-72">Age:</label>
                        <span className="flex-1 font-bold">
                          {userDetails.age}
                        </span>
                      </div>
                      <div className="flex flex-row items-center">
                        <label className="text-slate-600 w-72">Country:</label>
                        <span className="flex-1 font-bold">India</span>
                      </div>
                      <div className="flex flex-row items-center">
                        <label className="text-slate-600 w-72">
                          Mobile Number:
                        </label>
                        <span className="flex-1 font-bold">
                          {userDetails.phoneNumber}
                        </span>
                      </div>
                      <div className="flex flex-row items-center">
                        <label className="text-slate-600 w-72">
                          Marital Status:
                        </label>
                        <span className="flex-1 font-bold">
                          {userDetails.maritalStatus}
                        </span>
                      </div>
                      <div className="flex flex-row items-center">
                        <label className="text-slate-600 w-72">
                          Blood Group:
                        </label>
                        <span className="flex-1 font-bold">
                          {userDetails.bloodGroup}
                        </span>
                      </div>
                      <div className="flex flex-row items-center">
                        <label className="text-slate-600 w-72">
                          Current Address:
                        </label>
                        <span className="flex-1 font-bold">
                          {userDetails.presentAddress}
                        </span>
                      </div>
                      <div className="flex flex-row items-center">
                        <label className="text-slate-600 w-72">
                          Educational Qualification:
                        </label>
                        <span className="flex-1 font-bold">
                          {userDetails.degree}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeTab === "shiftInfo" && (
            <div className="mt-6 ml-4">
              {/* <p className="text-xl font-bold">Shift Information</p> */}
              <div className="flex items-center mt-4">
                {/* <label className="mr-2">Select Date:</label> */}
                <div className="w-full xl:w-4/5 3xl:w-3/4 mx-auto">
                  <Calendar
                    localizer={localizer}
                    events={formattedShiftDetails}
                    startAccessor="shiftStartDate"
                    endAccessor="shiftEndDate"
                    titleAccessor={(event) => (
                      <div
                        className={`${
                          event.type === "WFO"
                          ? "bg-green-500"
                          : event.type === "WFH"
                          ? "bg-blue-500"
                          : event.type === "Leave"
                          ? "bg-yellow-500"
                          : event.type === "Weekoff"
                          ? "bg-purple-500"
                          : event.type === "Absent"
                          ? "bg-red-500"
                          : event.type === "Holiday"
                          ? "bg-orange-500"
                          : event.type === "HalfDay"
                          ? "bg-pink-500"
                          : ""
                        } text-lg`}
                        style={{
                          fontSize: `${24 - event.availability.length / 2}px`,
                        }}
                      >
                        {`${event.availability} (${event.type})`}
                      </div>
                    )}
                    style={{ height: 500 }}
                    eventPropGetter={(event) => {
                      return {
                        className:
                          event.type === "WFO"
                            ? "bg-green-500 text-lg"
                            : event.type === "WFH"
                            ? "bg-blue-500 text-lg"
                            : event.type === "Leave"
                            ? "bg-yellow-500 text-lg"
                            : event.type === "Weekoff"
                            ? "bg-purple-500 text-lg"
                            : event.type === "Absent"
                            ? "bg-red-500 text-lg"
                            : event.type === "Holiday"
                            ? "bg-orange-500 text-lg"
                            : event.type === "HalfDay"
                            ? "bg-pink-500"
                            : "",
                      };
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default UserProfile;
