import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import AddEmployee from "./pages/ManageEmployee/EmployeeForm";
import ViewEmployee from "./pages/ManageEmployee/ViewEmployee";
import ActivityEmployee from "./pages/EmployeeActivity/EmpActivity";
// import DeletedEmployee from "./pages/ManageEmployee/DeletedEmployee";
import UserPanel from "./pages/User/UserPanel";
import RepubAllocation from "./components/Allocation/Repub/fileAllocation";
import EpubAllocation from "./components/Allocation/Epub/fileAllocation";
import EpubDeliveredFiles from "./components/Allocation/Epub/deliveredFiles";
import ProjectProduction from "./pages/Projects/Production";
import ProjectEpubProduction from "./pages/Projects/EpubProduction";
import ShiftManagementPage from "./pages/ShiftManagement/EmployeeRoster";
// import ProjectFinalProduction from "./pages/Projects/Final";
import NotificationPage from "./pages/Notification/NotificationPage";
import UserProfile from "./pages/AboutMe/UserProfile";
import Header from "./components/NavBar/Header";
import DeliveredFiles from "./components/Allocation/Repub/deliveredFiles";
import moment from 'moment-timezone';

function App() {
moment.tz.setDefault("Asia/Kolkata");
  return (
    <Router>
      <Header />
      <Routes>
      {/* <Route
          path="/*"
          element={
            <>
              <Header />
            </>
          }
        /> */}
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/employee" element={<AddEmployee/>} />
        <Route path="/manageEmployees" element={<ViewEmployee/>} />
        {/* <Route path="/deletedEmployees" element={<DeletedEmployee />} /> */}
        <Route path="/employeeActivity" element={<ActivityEmployee/>} />
        <Route path="/files/repub/production" element={<ProjectProduction/>}/>
        <Route path="/files/epub/production" element={<ProjectEpubProduction/>}/>
        <Route path="/shift/roster" element={<ShiftManagementPage/>}/>
        {/* <Route path="/files/finalProduction" element={<ProjectFinalProduction />}/> */}
        <Route path="/repub/FileAllocation" element={<RepubAllocation />} />
        <Route path="/repub/deliveredFiles" element={<DeliveredFiles/>}/>
        <Route path="/epub/fileAllocation" element={<EpubAllocation />} />
        <Route path="/epub/deliveredFiles" element={<EpubDeliveredFiles/>} />
        {/* <Route path="/user" element={<UserPanel />} /> */}
        <Route path="/notification" element={<NotificationPage />} />
        <Route path="/profile" element={<UserProfile />} />
      </Routes>
    </Router>
  );
}

export default App;
