import { useEffect, useState, useContext, useRef } from "react";
import { Navigate, Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import axios from "../../service/api";
import Chart from "react-apexcharts";
import io from "socket.io-client";
import { getRole, getToken, isLoggedIn, getUser } from "../../service/auth";
import { useNotification } from "../../components/Context/NotificationContext";

const Dashboard = () => {
  const [authenticated, setAuthenticated] = useState(true);
  const { showPushNotification } = useNotification();
  const [optionsProd] = useState({
    chart: {
      offsetX: 25, // Adjust the chart position vertically
    },
    labels: ["Production", "QA", "Pending Files", "Repub", "Epub"], // Custom series names
  });
  const [optionsAdmin] = useState({
    chart: {
      offsetX: 25, // Adjust the chart position vertically
    },
    labels: ["Male", "Female", "Others"], // Custom series names
  });
  // [maleCount, femaleCount, others];
  const [seriesAdmin, setSeriesAdmin] = useState([0, 0, 0]);
  const [seriesAdminBar, setSeriesAdminBar] = useState([
    {
      name: "count",
      data: [0, 0, 0, 0, 0],
    },
  ]);
  const [seriesLogisticsBar, setSeriesLogisticsBar] = useState([
    {
      name: "count",
      data: [10, 20, 30, 40, 50, 60],
    },
  ]);
  const [seriesProdBar, setSeriesProdBar] = useState([
    {
      name: "Series A",
      data: [30, 40, 45, 50, 60],
    },
  ]);
  // const [seriesAdminStateBar, setSeriesAdminStateBar] = useState([30, 40, 45, 50, 60])
  const [seriesProd, setSeriesProd] = useState([44, 55, 41, 17, 15]);
  // const [labelsProd] = useState(["A", "B", "C", "D", "E"]);
  const [dataLog, setDataLog] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPagesProduction, setTotalPagesProduction] = useState(0);
  const [totalPagesQAProduction, setTotalPagesQAProduction] = useState(0);
  const [totalPendingAssignedFiles, setTotalPendingAssignedFiles] = useState(0);
  const [totalRepubFiles, setTotalRepubFiles] = useState(0);
  const [totalEpubFiles, setTotalEpubFiles] = useState(0);
  const checkStakeholders = getRole() === "Stakeholders";
  const checkAdmin = getRole() === "Admin";
  const checkLogistics = getRole() === "Logistics";
  const checkTeamLead = getRole() === "Production InCharge";
  const checkProd = getRole() === "Production";
  const dropdownRef = useRef(null);

  const [optionsProdBar] = useState({
    chart: {
      toolbar: {
        show: false, // Hide the download button
      },
    },
    xaxis: {
      categories: ["Production", "QA", "Assigned Files", "Repub", "Epub"],
    },
  });

  const optionsAdminBar = {
    chart: {
      toolbar: {
        show: false, // Hide the download button
      },
      id: "basic-bar",
    },
    xaxis: {
      categories: [
        "Admin",
        // "IT Team",
        "Logistics",
        "Production InCharge",
        "Production Member",
        "Accounts",
        "IT Team",
      ],
    },
  };

  const optionsLogisticsBar = {
    chart: {
      toolbar: {
        show: false, // Hide the download button
      },
      id: "basic-bar",
    },
    xaxis: {
      categories: [
        "Waiting For Allocation",
        "Allocated",
        "Production Pending",
        "QA Pending",
        "Delivered Files",
        "Total Files Pending",
        // "IT Team",
      ],
    },
  };

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("userData");
    if (loggedInUser) {
      setAuthenticated(true);
      fetchData(currentPage);
    } else {
      setAuthenticated(false);
    }
    const socket = io.connect(axios.defaults.baseURL);

    if (checkTeamLead || checkProd) {
      socket.on("newSimpleAllocation", (response) => {
        if (
          response.Allocation.some(
            (allocation) => allocation.member === getUser()?.email
          )
        ) {
          const fileName = response.filename || "N/A";
          const priority =
            response.Priority === "True" ? "High Priority" : "Low Priority";
          const turnaroundTime = response["TAT"] || "N/A";

          showPushNotification(`New Repub File Received - ${fileName}`, {
            body: `Priority: ${priority}, TAT: ${turnaroundTime}`,
            icon: "/athena-icon.png",
          });
        }
      });

      socket.on("newBulkAllocation", (response) => {
        if (
          response.Allocation.some(
            (allocated) => allocated.member === getUser()?.email
          )
        ) {
          const fileName = response.filename || "N/A";
          const priority =
            response.Priority === "True" ? "High Priority" : "Low Priority";
          const turnaroundTime = response["TAT"] || "N/A";

          // const title = `New File Received - ${fileName}`;
          // const body = `Priority: ${priority}, TAT: ${turnaroundTime}`;

          // debouncedCallApi(page, "All"); // Call the API to fetch the latest data
          showPushNotification(`New Repub File Received - ${fileName}`, {
            body: `Priority: ${priority}, TAT: ${turnaroundTime}`,
            icon: "/athena-icon.png",
            // url: response.result.url,
          });
        }
      });
    }

    if (checkLogistics || checkTeamLead) {
      socket.on("newMail", (response) => {
        showPushNotification("New Repub File Received", {
          body: `${response.filename} received for waiting allocation`,
          icon: "/athena-icon.png",
        });
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [currentPage]);

  // Close the dropdown when clicking outside of it
  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setShowToolsDropdown(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/dashboard");
      setData(response.data.data);
      setDataLog(response.data.data.data);
      if (checkAdmin || checkStakeholders) {
        const newSeriesAdmin = [
          response.data.data.maleEmployees,
          response.data.data.femaleEmployees,
          0,
        ]; // Update the third value as needed
        // const newSeriesAdminBar = roleCounts.map(role => role.count);
        setSeriesAdmin(newSeriesAdmin);
        // const categories = response.data.data.roleCounts.map(role => role.role);
        const data = response.data.data.roleCounts.map((role) => role.count);

        const newSeriesAdminBar = [
          {
            name: "count",
            data: data,
          },
        ];
        setSeriesAdminBar(newSeriesAdminBar);
      } else if (checkLogistics || checkTeamLead) {
        const newSeriesLog = [
          response.data.data.waitingAllocation,
          response.data.data.allocatedFiles,
          response.data.data.productionPending,
          response.data.data.qaPending,
          response.data.data.deliveredCount,
          response.data.data.totalPendingFiles,
        ];
        const newSeriesLogistics = [
          {
            name: "count",
            data: newSeriesLog,
          },
        ];
        setSeriesLogisticsBar(newSeriesLogistics);
      } else if (checkProd) {
        setTotalPagesProduction(response.data.data.productionCounts);
        setTotalPagesQAProduction(response.data.data.qaProductionCounts);
        setTotalPendingAssignedFiles(
          response.data.data.totalPendingAssignedFiles
        );
        setTotalRepubFiles(response.data.data.totalRepubFiles);
        setTotalEpubFiles(response.data.data.totalEpubFiles);
        const newSeriesProd = [
          response.data.data.productionCounts,
          response.data.data.qaProductionCounts,
          response.data.data.totalPendingAssignedFiles,
          response.data.data.totalRepubFiles,
          response.data.data.totalEpubFiles,
        ]; // Update the third value as needed
        setSeriesProd(newSeriesProd);
        // const newSeriesAdminBar = roleCounts.map(role => role.count);
        const newSeriesProdBar = [
          {
            name: "count",
            data: newSeriesProd,
          },
        ];
        setSeriesProdBar(newSeriesProdBar);
      }
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleGoToPage = (e) => {
    e.preventDefault();
    const pgNumber = parseInt(e.target.pgNumber.value);
    if (pgNumber >= 1 && pgNumber <= totalPages) {
      setCurrentPage(pgNumber);
    }
  };

  const generatePaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    let startPage = currentPage;
    let endPage = currentPage + maxButtonsToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = endPage - maxButtonsToShow + 1;
      startPage = startPage < 1 ? 1 : startPage;
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-4 mt-2 py-2 bg-gray-200 rounded-md ${
            i === currentPage ? "bg-blue-600 text-white" : "text-gray-700"
          } hover:bg-blue-400 hover:text-white`}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div>
      {!authenticated ? (
        <Navigate to="/" />
      ) : checkAdmin || checkStakeholders ? (
        <Layout isAdmin={checkAdmin} isStakeholders={checkStakeholders}>
          <div>
            <div class="container px-6 mx-auto grid">
              <h2 class="my-6 text-2xl font-semibold text-gray-700">
                Athena Dashboard Page
                <div className="flex justify-end">
                  <Link
                    to="/employee"
                    className="inline-block text-sm px-2 py-2 leading-none text-white bg-green-500 hover:bg-green-600 rounded"
                  >
                    Add Employee
                  </Link>
                </div>
              </h2>

              <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
                <div class="flex items-center p-4 bg-white rounded-lg shadow-xs">
                  <div class="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                    </svg>
                  </div>
                  <div>
                    <p class="mb-2 text-sm font-medium text-gray-600">
                      Total Employees
                    </p>
                    <p class="text-lg font-semibold text-gray-700">
                      {data.totalEmployees}
                    </p>
                  </div>
                </div>
                <div class="flex items-center p-4 bg-white rounded-lg shadow-xs">
                  <div class="p-3 mr-4 text-green-500 bg-green-100 rounded-full">
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                    </svg>
                  </div>
                  <div>
                    <p class="mb-2 text-sm font-medium text-gray-600">
                      Active Employees
                    </p>
                    <p class="text-lg font-semibold text-gray-700">
                      {data.activeEmployees}
                    </p>
                  </div>
                </div>
                <div class="flex items-center p-4 bg-white rounded-lg shadow-xs">
                  <div class="p-3 mr-4 text-yellow-500 bg-yellow-100 rounded-full">
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                    </svg>
                  </div>
                  <div>
                    <p class="mb-2 text-sm font-medium text-gray-600">
                      InActive Employees
                    </p>
                    <p class="text-lg font-semibold text-gray-700">
                      {data.inactiveEmployees}
                    </p>
                  </div>
                </div>
                <div class="flex items-center p-4 bg-white rounded-lg shadow-xs">
                  <div class="p-3 mr-4 text-red-500 bg-red-100 rounded-full">
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                    </svg>
                  </div>
                  <div>
                    <p class="mb-2 text-sm font-medium text-gray-600">
                      Employees Suspend
                    </p>
                    <p class="text-lg font-semibold text-gray-700">
                      {data.deletedEmployees}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="donut w-full md:w-1/2 pr-4">
                <Chart
                  options={optionsAdmin}
                  series={seriesAdmin}
                  type="donut"
                  width="450"
                />
              </div>
              <div className="w-full md:w-1/2 pr-4">
                <Chart
                  options={optionsAdminBar}
                  series={seriesAdminBar}
                  type="bar"
                  width="400"
                  height="300"
                />
              </div>
              {/* <div className="w-full md:w-1/2">
    <Chart
      options={options}
      series={series}
      type="bar"
      width="400"
      height="300"
    />
  </div> */}
            </div>
          </div>
        </Layout>
      ) : checkLogistics || checkTeamLead || checkStakeholders ? (
        <Layout
          // isAdmin={getRole() === "admin"}
          isStakeholders={checkStakeholders}
          isLogistics={checkLogistics}
          isTL={checkTeamLead}
        >
          <div className="w-full bg-white shadow-2xl rounded-lg flex flex-row">
            {/* <div className="px-4 py-3 border-b border-gray-200">
              <h1 className="text-2xl font-semibold">Athena Dashboard Page</h1>
            </div> */}

            <div className="table-container max-w-screen-lg mx-auto ml-1 min-h-[100vh]">
              <table className="table-auto w-full text-xs md:text-xs">
                <thead>
                  <tr>
                    <th className="text-left text-xs font-bold text-black uppercase tracking-wider border-r border-b border-black">
                      S.No.
                    </th>
                    <th className=" bg-gray-50 text-left text-xs font-bold text-black uppercase tracking-wider border-r border-b border-black">
                      Publicatie
                    </th>
                    <th className=" bg-gray-50 text-left text-xs font-bold text-black uppercase tracking-wider border-r border-b border-black">
                      Publisher
                    </th>
                    <th className=" bg-gray-50 text-left text-xs font-bold text-black uppercase tracking-wider border-r border-b border-black">
                      Issue
                    </th>
                    <th className=" bg-gray-50 text-left text-xs font-bold text-black uppercase tracking-wider border-r border-b border-black">
                      Release Date
                    </th>
                    <th className=" bg-gray-50 text-left text-xs font-bold text-black uppercase tracking-wider border-r border-b border-black">
                      Received
                    </th>
                    <th className=" bg-gray-50 text-left text-xs font-bold text-black uppercase tracking-wider border-r border-b border-black">
                      Pages
                    </th>
                    <th className=" bg-gray-50 text-left text-xs font-bold text-black uppercase tracking-wider border-r border-b border-black">
                      Pubcode
                    </th>
                    <th className=" bg-gray-50 text-left text-xs font-bold text-black uppercase tracking-wider border-r border-b border-black">
                      TAT
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {dataLog.map((item, index) => (
                    <tr
                      className={
                        Boolean(item.Priority.toLowerCase() === "true")
                          ? "bg-orange-300 w-0"
                          : ""
                      }
                      key={index}
                    >
                      <td className="px-2 py-2 border-r border-black">
                        {index + 1}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap border-r border-black">
                        {item.Publicatie}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap border-r border-black">
                        {item.Publisher}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap border-r  border-black">
                        {item.Issue}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap border-r border-black">
                        {item["Release date"]}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap border-r border-black">
                        {item["Received client"]}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap border-r border-black">
                        {item.pages}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap border-r border-black">
                        {item.Pubcode}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap border-r border-black">
                        {item.TAT}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <div className="w-full flex justify-end items-end mt-10 mb-20">
                <form onSubmit={handleGoToPage} className="mr-8">
                  <label>Enter Page Number:</label>
                  <input
                    type="number"
                    id="pgNumber"
                    className="w-20 h-10 mr-5 p-1"
                    defaultValue={currentPage}
                  />
                  <button
                    type="submit"
                    className="bg-blue-500 px-5 py-2 rounded-lg text-white"
                  >
                    GO
                  </button>
                </form>
                <div className="flex flex-wrap items-center space-x-1">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="flex mt-2 items-center px-4 py-2 text-gray-500 bg-gray-300 rounded-md"
                    disabled={currentPage === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M11 17l-5-5m0 0l5-5m-5 5h12"
                      />
                    </svg>
                  </button>
                  {generatePaginationButtons()}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="px-4 mt-2 py-2 text-gray-500 bg-gray-300 rounded-md hover:bg-blue-400 hover:text-white"
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </button>
                </div>
              </div> */}
            </div>
            <div className="w-full md:w-1/2 mt-10">
              <Chart
                options={optionsLogisticsBar}
                series={seriesLogisticsBar}
                type="bar"
                width="100%"
                height="300"
              />
            </div>
          </div>
        </Layout>
      ) : checkTeamLead ? (
        <Layout isTL={checkTeamLead}></Layout>
      ) : checkProd ? (
        <Layout isProd={checkProd}>
          {/* <div className="flex justify-end">
            <div className="relative">
              <button
                className="relative p-2 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 focus:outline-none mr-4 mt-2"
                onClick={toggleToolsDropdown}
              >
                Tools
              </button>
              {showToolsDropdown && (
                <div
                  ref={dropdownRef}
                  className="absolute top-0 right-0 mt-8 w-40 bg-white shadow-lg rounded-lg divide-y divide-gray-200"
                >
                  <button
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none"
                    onClick={handleRepubClick}
                  >
                    Patel Repub
                  </button>
                  <button
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none"
                    onClick={handlePTeamRepubClick}
                  >
                    Pteam Repub
                  </button>
                  <button
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none"
                    onClick={handleAccessibilityClick}
                  >
                    Patel Accessibility
                  </button>
                </div>
              )}
            </div>
          </div> */}
          <div class="py-10">
            <main class="h-full overflow-y-auto">
              <div class="container  mx-auto grid">
                <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
                  <div class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                    <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100 dark:bg-orange-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.2em"
                        height="1.2em"
                        viewBox="0 0 512 512"
                        fill="currentColor"
                      >
                        <path d="M427.84 380.67l-196.5 97.82a18.6 18.6 0 0 1-14.67 0L20.16 380.67c-4-2-4-5.28 0-7.29L67.22 350a18.65 18.65 0 0 1 14.69 0l134.76 67a18.51 18.51 0 0 0 14.67 0l134.76-67a18.62 18.62 0 0 1 14.68 0l47.06 23.43c4.05 1.96 4.05 5.24 0 7.24zm0-136.53l-47.06-23.43a18.62 18.62 0 0 0-14.68 0l-134.76 67.08a18.68 18.68 0 0 1-14.67 0L81.91 220.71a18.65 18.65 0 0 0-14.69 0l-47.06 23.43c-4 2-4 5.29 0 7.31l196.51 97.8a18.6 18.6 0 0 0 14.67 0l196.5-97.8c4.05-2.02 4.05-5.3 0-7.31zM20.16 130.42l196.5 90.29a20.08 20.08 0 0 0 14.67 0l196.51-90.29c4-1.86 4-4.89 0-6.74L231.33 33.4a19.88 19.88 0 0 0-14.67 0l-196.5 90.28c-4.05 1.85-4.05 4.88 0 6.74z" />
                      </svg>
                    </div>
                    <div>
                      <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                        Total Pages Production
                      </p>
                      <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
                        {totalPagesProduction}
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                    <div class="p-3 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 5l7 7-7 7M5 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                    <div>
                      <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                        Total Pages QA
                      </p>
                      <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
                        {totalPagesQAProduction}
                      </p>
                    </div>
                  </div>
                  {/* <div class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                    <div class="p-3 mr-4 text-teal-500 bg-teal-100 rounded-full dark:text-teal-100 dark:bg-teal-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1.5em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                      </svg>
                    </div>
                    <div>
                      <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                        Total Pages Finalize
                      </p>
                      <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
                        376
                      </p>
                    </div>
                  </div> */}
                  <div class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                    <div class="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                    </div>
                    <div>
                      <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                        Total Pending Assigned Files
                      </p>
                      <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
                        {totalPendingAssignedFiles}
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                    <div class="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <div>
                      <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                        Total Repub Files
                      </p>
                      <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
                        {totalRepubFiles}
                      </p>
                    </div>
                  </div>

                  <div class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                    <div class="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                    <div>
                      <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                        Total Epub Files
                      </p>
                      <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
                        {totalEpubFiles}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <div className="flex">
            <div className="donut w-1/2 pr-4">
              <Chart
                options={optionsProd}
                series={seriesProd}
                type="donut"
                width="450"
              />
            </div>
            <div className="w-1/2">
              <Chart
                options={optionsProdBar}
                series={seriesProdBar}
                type="bar"
                width="450"
                height="300"
              />
            </div>
          </div>
        </Layout>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Dashboard;
