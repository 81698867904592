import React, { useState, useEffect } from "react";
import axios from "../../service/api";
import { useNavigate } from "react-router-dom";
import athena_logo from "../../images/athena-logo.png";
import illustration_image from "../../images/illustration.png";
import company_logo from "../../images/patel-logo.png";
import security_image from "../../images/security-logo.png";
import { setUser, isLoggedIn } from "../../service/auth";
import ProtectRoute from "../../utils/protectRoute";
import Header from "../../components/NavBar/Header";
import "./Login.css";
import ErrorPoup from "../../components/Popup/ErrorPopup";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupErrorMessage, setPopupErrorMessage] = useState("");
  const [isDateSynced, setIsDateSynced] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/dashboard");
    }
  }, []);

  // useEffect(() => {
  //   // Fetch IST time from the API
  //   const fetchISTTime = async () => {
  //     try {
  //       const response = await axios.get('/api/getISTTime');
  //       const istTime = new Date(response.data.utc_datetime);
  //       const systemTime = new Date();

  //       console.log('check system time......', systemTime);
  //       console.log('check ist time.......', istTime)
  //         if(systemTime === istTime){
  //         setIsDateSynced(true);
  //         } else {
  //           setIsDateSynced(false);
  //         }
  //     } catch (error) {
  //       console.error("Error fetching IST time:", error);
  //     }
  //   };

  //   fetchISTTime();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    try {
      const response = await axios.post("/user/login", {
        email,
        password,
      });
      console.log(response.data.data);
      //create a object to store the user's information....
      const userData = {
        id: response.data.data.id,
        token: response.data.data.token,
        userName: response.data.data.fullName,
        role: response.data.data.role,
        profilePic: response.data.data.profilePic,
        email: response.data.data.email,
      };
      // save the user's information to session storage
      // sessionStorage.setItem("userData", JSON.stringify(userData));
      // const userDataLogin = response.data.data.user;
      setUser(userData);
      setAuthenticated(true);
      if (userData.role === "Production") {
        navigate("files/repub/production");
      } else {
        navigate("/dashboard");
      }
      window.location.reload();
      // redirect to dashboard or another page on successful login
    } catch (error) {
      // setShowPopup(true);
      // setPopupErrorMessage("Unable to connect to the server")
      console.error(error);
      if (error.message === "Network Error") {
        setShowPopup(true);
        setPopupErrorMessage("Unable to connect to the server. ");
      } else if (error.response.data.data.type === "User status") {
        alert(error.response.data.data.message);
      } else {
        // setShowPopup(false);
        setError(error.response?.data);
      }
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(null);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(null);
    setErrorMessage(null);
  };

  return (
    // <ProtectRoute>
    <div className="main-container-page">
      <div className="left-main-container-page">
        <div className="left-main-container-logo-page">
          <img alt="logo" src={athena_logo} />
        </div>
        <div className="left-main-container-form-page">
          <form onSubmit={handleSubmit}>
            <div className="label-input">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your Email"
                value={email}
                onChange={handleEmailChange}
                // className={error && error.data.type === "Wrong email" ? "invalid" : ""}
                required={submitted}
              />
              {/* {submitted && !email && (
                <div className="error-message">Email is required</div>
              )} */}
              {error && error.data.type === "Wrong email" && (
                <div className="error-message">{error.data.message}</div>
              )}
            </div>
            {/* <br></br> */}
            <div className="label-input">
              <label htmlFor="password">Password</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter your Password"
                  value={password}
                  onChange={handlePasswordChange}
                  // className={error && error.data.type === "Wrong password" ? "invalid" : ""}
                  required={submitted}
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              </div>

              {submitted && !password && (
                <div className="error-message">Password is required</div>
              )}
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              {error && error.data.type === "Wrong password" && (
                <div className="error-message">{error.data.message}</div>
              )}
            </div>
            <div className="button-container">
              <button type="submit">Log In</button>
            </div>
          </form>
          {/* {token && role && uname && <Form token={token} role={role} userName={userName} />} */}
        </div>
      </div>
      <div className="right-main-container-page">
        <div className="logo-image-container">
          <div className="right-main-container-page-company-logo">
            <img alt="patel_logo" src={company_logo} />
          </div>
        </div>
        <div className="right-main-container-page-text">
          <span>
            {/* Securely Upload And Store Your Important Documents With{" "} */}
            Securely Download Files And Effortlessly Mark Your Daily Attendance with {" "}
            <span style={{ color: "#1C9FDD", fontSize: "40px" }}>Athena!</span>
            {/* <br></br>
            Experience Seamless Work Management with {" "}
            <span style={{ color: "#1C9FDD", fontSize: "40px" }}>Athena!</span> */}
          </span>
        </div>
        <div className="right-image-container">
          <div className="illustration-container">
            <img alt="illustration" src={illustration_image} />
            <div className="security-container">
              <img alt="security_img" src={security_image} />
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <ErrorPoup
          message={popupErrorMessage}
          isShowAction={true}
          buttonText="try again"
          action={() => window.location.reload()}
        />
      )}
      {/* {isAuthenticated && <Header isAuthenticated={isAuthenticated}/>} */}
    </div>
    // </ProtectRoute>
  );
};

export default Login;
