import React, { useState, useEffect } from "react";

import Layout from "../../Layout/Layout";
// import DataSet from "../AllocationList/RepubData";
import DataSubmittingPop from "../../Popup/DataSubmitPopup";
import axios from "../../../service/api";
import { useNavigate } from "react-router-dom";
import Button from "../../Button/button";
import Status from "../../../utils/Stage";
import ProtectRoute from "../../../utils/protectRoute";
import { getToken, getRole, logout } from "../../../service/auth";
import { useNotification } from "../../Context/NotificationContext";
import ErrorPoup from "../../Popup/ErrorPopup";
import io from "socket.io-client";
import { debounce } from "lodash";
import { RingLoader } from "react-spinners";


const DeliveredFilesEpub = () => {

    return (
        <ProtectRoute>
            <Layout
               isStakeholders={getRole() === "Stakeholders"}
               isLogistics={getRole() === "Logistics"}
               isTL={getRole() === "Production InCharge"}
            >
            <div className="w-full mt-5 bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
            <RingLoader color="#000" size={50} />
            <span className="ml-2">Loading data...</span>
          </div>
            </Layout>
        </ProtectRoute>
    )
}


export default DeliveredFilesEpub;