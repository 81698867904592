import React from 'react';

const ChartBar = (props) => {
  // let barFillHeight = '0%';
  const barFillHeight = props.maxValue > 0
  ? Math.round((props.value / props.maxValue) * 100) + '%'
  : '0%';

  // if (props.maxValue > 0) {
  //   barFillHeight = Math.round((props.value / props.maxValue) * 100) + '%';
  // }

  return (
    <div className="flex flex-col items-center h-full">
      <div className="h-full w-4 border-2 border-gray-700 rounded-lg bg-gray-300 overflow-hidden flex flex-col justify-end">
        <div
          className="bg-indigo-700 h-[barFillHeight] transition-all duration-300"
          style={{ height: barFillHeight }}
        ></div>
      </div>
      <div className="font-bold text-xs">{props.label}</div>
    </div>
  );
};



export default ChartBar;