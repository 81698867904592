import axios from 'axios'
import { getUser } from './auth'

// export default axios.create({
// 	// baseURL: "https://api.webathena.patelinfo.com/",              //Live API domain
// 	// baseURL: "https://staging.api.webathena.patelinfo.com",      //Staging API domain
// 	baseURL: "http://localhost:8000",                                //Local API domain
// 	// timeout: 10000,
// 	headers: { Authorization: 'Bearer ' + getUser()?.token }
// })

const api = axios.create({
	  baseURL: "https://api.athena.patelgtech.com/",                  //Live API domain
    //   baseURL: "https://staging.api.webathena.patelinfo.com",          //Staging API domain
	//  baseURL: "http://localhost:8000",                                 //Local API domain
  });
  
  api.interceptors.request.use((config) => {
	const token = getUser()?.token;
	if (token) {
	  config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
  });
  
  export default api;