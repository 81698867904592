import React, { useState, useEffect, useRef } from "react";
import Button from "../Button/button";
import axios from "../../service/api";
import ErrorPopup from "../Popup/ErrorPopup";
import DataSubmittingPop from "./DataSubmitPopup";

const AllocationPopupStatus = React.memo((props) => {
  const [isError, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentRow, setCurrentRow] = useState(0);
  const [allocationArray, setAllocationArray] = useState([]);

  const editPopupRef = useRef(null);

  const getData = async () => {  
    try {
      let response = await axios.post("/files/allocationFileStatus", {
        fileType: props.row[currentRow].Pubcode,
        checkedFile: props.row[currentRow]._id,
      });
      setAllocationArray(response.data.allocation); // Updated to access the 'allocation' array in the response
    } catch (error) {
      console.error(error);
      setError("Error fetching data"); // Set an error message based on your error handling logic
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const formatAllocatedTime = (timeString) => {
    const date = new Date(timeString);
    return date.toLocaleString("en-US", {
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const handleOutsideClick = (e) => {
    if (editPopupRef.current && !editPopupRef.current.contains(e.target)) {
      props.onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  if (isError) {
    return (
      <ErrorPopup
        message={isError}
        isShowAction={true}
        action={() => {
          props.onCancel();
        }}
      />
    );
  }

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-20 z-20"
        onClick={props.onCancel}
      ></div>
      <div
        className="w-full md:w-3/5 fixed px-8 py-10 max-h-96 overflow-x-auto bg-blue-50 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-30 rounded-lg resizable"
        ref={editPopupRef}
      >
        <div className="flex flex-col justify-center items-center mb-6">
          <span className="font-bold text-3xl mb-2">
            {props.row[currentRow].filename}
          </span>
          <span className="font-bold text-3xl">
            Total Pages: {props.row[currentRow]["Number of pages"]}
          </span>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {allocationArray.map((allocation, index) => (
            <div
              key={index}
              className="shadow-inner bg-white m-1 rounded-lg p-4"
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Allocation #{index + 1}</h2>
              </div>
              <div className="flex mb-2">
                <p className="w-48 font-semibold">Member</p>
                <span className="font-serif">{allocation.member}</span>
              </div>
              <div className="flex mb-2">
                <p className="w-48 font-semibold">Page</p>
                <span className="font-serif">{allocation.pages}</span>
              </div>
              <div className="flex mb-2">
                <p className="w-48 font-semibold">Started Time</p>
                <span
                  className={`font-serif  ${
                    allocation.startTime ? "" : "bg-blue-200"
                  }`}
                >
                  {allocation.startTime
                    ? formatAllocatedTime(allocation.startTime)
                    : "Not Started"}
                </span>
              </div>
              <div className="flex mb-2">
                <p className="w-48 font-semibold">Completed Time</p>
                <span
                  className={`font-serif  ${
                    allocation.completeTime ? "" : "bg-yellow-200"
                  }`}
                >
                  {allocation.completeTime
                    ? formatAllocatedTime(allocation.completeTime)
                    : "Not Completed"}
                </span>
              </div>
              <div className="flex mb-2">
                <p className="w-48 font-semibold">Stage</p>
                <span className="font-serif">{allocation.stage}</span>
              </div>
              <div className="flex mb-2">
                <p className="w-48 font-semibold">Allocated Time</p>
                <span  className={`font-serif  ${
                    allocation.allocatedTime ? "" : "bg-green-200"
                  }`}>
                {allocation.allocatedTime
                  ? formatAllocatedTime(allocation.allocatedTime)
                  : "Not Allocated"} {/* Updated to display "Not Allocated" */}
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-8 flex justify-center items-center">
          <Button onClick={props.onCancel} className="bg-red-500 mx-2">
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
});

export default AllocationPopupStatus;
