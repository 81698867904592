import React, { useState, useEffect, useRef } from "react";

import Layout from "../../Layout/Layout";
import DataSet from "../AllocationList/RepubData";
import axios from "../../../service/api";
import { useNavigate } from "react-router-dom";
import Button from "../../Button/button";
import Status from "../../../utils/Stage";
import ProtectRoute from "../../../utils/protectRoute";
import { getToken, getRole, logout } from "../../../service/auth";
import { useNotification } from "../../Context/NotificationContext";
import ErrorPoup from "../../Popup/ErrorPopup";
import io from "socket.io-client";
import { debounce } from "lodash";
import { RingLoader } from "react-spinners";


const FileAllocationEpub = React.memo(() => {

    const navigate = useNavigate();
    const { showPushNotification } = useNotification();
    const [datas, setData] = useState();
    const [allCount, setAllCount] = useState();
    const [showError, setShowError] = useState();
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState();
    const [perPage, setPerPage] = useState(10);
    const [totalItem, setTotalItem] = useState();
    const [showLoading, setShowLoading] = useState(true);
    const [pageType, setPageType] = useState("All");
    const [options, setOptions] = useState();
    const [searchParams, setSearchParams] = useState([]);
    const [showProgress, setShowProgress] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [message, setMessage] = useState("");
    const [showPopUp, setShowPopup] = useState(false);
    const [selectedClient, setSelectedClient] = useState("All")

    const isFirstTime = useRef(true);

    const optionsStorageKey = "allocationEpubFilesOptions";

    const getStoredOptions = () => {
      const storedOptions = localStorage.getItem(optionsStorageKey);
      if (storedOptions) {
        return JSON.parse(storedOptions);
      }
      return null;
    };

    const setOptionsInStorage = (options) => {
      localStorage.setItem(optionsStorageKey, JSON.stringify(options));
    };


    const debouncedCallApi = useRef(
      debounce((currentPage, type, search) => {
        callApi(currentPage, type, search);
      }, 1000)
    ).current;
  
    useEffect(() => {
      const socket = io.connect(
        axios.defaults.baseURL
        // transports: ['websockets']
      );
      socket.on("newMailEpub", (response) => {
        debouncedCallApi(page, "All"); // Call the API to fetch the latest data
        showPushNotification("New Epub File Received", {
          body: `${response.filename} received for waiting allocation`,
          icon: "/athena-icon.png",
        });
      });
   
      if (isFirstTime.current) {
        localStorage.removeItem(optionsStorageKey);
        const storedOptions = getStoredOptions();
        if (storedOptions) {
          setOptions(storedOptions);
        } else {
          callAPIForOptions();
        }
        debouncedCallApi(page, "All");
        isFirstTime.current = false;
      }
      return () => {
        socket.disconnect(); // Disconnect the socket when the component unmounts
      };
    }, [isFirstTime]);

    const handleClientChange = async (val) => {
      setSelectedClient(val)
      await callApi(page, pageType, searchText, val); 
    }


    const callApi = async (currentPage, type, search = "", selectedClient) => {
      try {
        setShowProgress(true);
        // setShowLoading(true)
        searchParams !== "" ? setShowLoading(true) : setTimeout(() => {
          setShowLoading(false); // Show the loading message
        }, 3000);
        const res = await axios.post("/files/allocationEpubFiles", {
          is_allocation: true,
          page: currentPage,
          perPage: perPage,
          filterString: type,
          searchParams: search,
          client: selectedClient
        });
        setPage(currentPage);
        setPageType(type);
        setSearchParams(search);
        // setData();
        setData(res.data.files);
        // setTimeout(() => {
        //   // setIsDataAvailable(/* Check if data is available */);
        //   setShowLoading(false);
        // }, 3000);
        setShowLoading(false); // Hide the loading message
        if (res.data.files.length === 0 && search) {
          setShowError(true);
        }
        setTotalItem(res.data.totalItems);
        setAllCount(res.data.allCount);
  
        let tempString = [];
        for (let key in res.data.counts) {
          if (res.data.counts.hasOwnProperty(key)) {
            let val = res.data.counts[key];
  
            if (val > 0) {
              tempString.push(`${key}(${val})`);
            }
          }
        }
        console.log(tempString.join("|"));
        setStatus(tempString.join("|"));
      } catch (err) {
        if (err.response) {
          if (err.response.status === 401) {
            logout(() =>
              typeof window !== `undefined` ? navigate("/") : (() => {})()
            );
          } else {
            setMessage(err.response.data.message);
            setShowProgress(false);
            setShowPopup(true);
          }
        } else {
          console.log("hello");
          setMessage("Unable to reach to server");
          setShowProgress(false);
          setShowPopup(true);
        }
      }
    };

    const callAPIForOptions = async (dataTemp) => {
      try {
        const res = await axios.post("/options/getOptions", {
          type: "allocationEpub",
        });
        // setShowProgress(false);
        // setOptions();
        setOptions(res.data.options);
        setOptionsInStorage(res.data.options)
      } catch (err) {
        // setShowProgress(false);
        if (err.response) {
          if (err.response.status === 401) {
            setShowPopup(true);
            setMessage(err.response.data.message);
          }
          if (err.response.status === 404) {
            setOptions([2, 3, 5, 6, 8, 10, 14, 17]);
            // setOptions([2, 3, 5, 6, 7, 11, 12, 13, 16,17]);
            const temp = [...Object.keys(dataTemp[0])];
            setOptions([
              temp.indexOf("Received client"),
              temp.indexOf("title"),
              temp.indexOf("Release date"),
              temp.indexOf("fileType"),
              temp.indexOf("Publisher"),
              temp.indexOf("Client"),
              temp.indexOf("Status"),
              temp.indexOf("Pubcode"),
              temp.indexOf("Download"),
              temp.indexOf("Number of pages")
            // ... (add other default options as needed)
          ]);
            // setOptions([
            //   temp.indexOf("Publisher"),
            //   temp.indexOf("Publicatie"),
            //   temp.indexOf("Client"),
            //   temp.indexOf("Released date"),
            //   temp.indexOf("Number of pages"),
            //   temp.indexOf("Received client"),
            //   temp.indexOf("Status"),
            // ]);
            setOptionsInStorage([
              temp.indexOf("Received client"),
              temp.indexOf("title"),
              temp.indexOf("Release date"),
              temp.indexOf("fileType"),
              temp.indexOf("Publisher"),
              temp.indexOf("Client"),
              temp.indexOf("Status"),
              temp.indexOf("Pubcode"),
              temp.indexOf("Download"),
              temp.indexOf("Number of pages")
            ]); // Store default options in local storage
          } else {
            setShowPopup(true);
            setMessage(err.response.data.message);
          }
        } else {
          setShowPopup(true);
          console.log("hello");
          setMessage("Unable to reach to server");
        }
      }
    };

    const handleSearch = (searchText) => {
        setSearchText(searchText);
        debouncedCallApi(1, pageType, searchText);
      };

    return (
        <ProtectRoute>
            <Layout
               isStakeholders={getRole() === "Stakeholders"}
               isLogistics={getRole() === "Logistics"}
               isTL={getRole() === "Production InCharge"}
            >
           {showLoading ? (
          <div className="w-full mt-5 bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
            <RingLoader color="#000" size={50} />
            <span className="ml-2">Loading data...</span>
          </div>
        ) : datas ? (
            <DataSet
              data={datas}
              title="EPUB Files"
              needPaddedRows={true}
              currentPage={page}
              actions={status}
              changePage={(number) => {
                callApi(number, pageType);
              }}
              currentPageType={pageType}
              setData={setData}
              allCount={allCount}
              handleClientChange={handleClientChange}
              onTypeChange={(type) => callApi(1, type)}
              onSearch={handleSearch}
              searchText={searchText}
              perPage={perPage}
              requiredManager={4}
              readOnlyStatus={false}
              searchParams={searchParams}
              totalItem={totalItem}
              addUrl="/files/manualUploadFile"
              editUrl="/files/editFile"
              // onConfirmed={handlePostConfirmed}
              // refreshList={() => callApi()}
              // cols={[...Object.keys(datas[0])]}
              cols={datas.length ? [...Object.keys(datas[0])]: []}
              visibleColumnIndices={options}
              inputs={datas.length ? Object.keys(datas[0]) : []}
              unsortableFields={["Download"]}
            />
          ) : (
          <div className="w-full mt-5 font-semibold bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
              {/* Error: Unable to fetch data */}
              Please wait to reload the epub data
            </div>
        )}
        </Layout>
        {showPopUp ? <ErrorPoup message={message} /> : <></>}
        </ProtectRoute>
    )
});


export default FileAllocationEpub;