import React, { useState, useEffect, useRef } from "react";

// import { ReactComponent as Cloud } from "../../../assets/cloud.svg";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Button from "../../Button/button";
import Status from "../../../utils/Stage";
import axios from "../../../service/api";
import ErrorPoup from "../../Popup/ErrorPopup";
import { getUser } from "../../../service/auth";
import DataSubmittingPop from "../../Popup/DataSubmitPopup";
import EmailPopup from "../../Popup/EmailTemplatePopup";
import AlertPoup from "../../Popup/AlertPopup";

const RepubRow = React.memo((props) => {
  
  const [isProcessing, setProcessing] = useState(false);
  const [isError, setError] = useState(false);
  const [displayImportantNote, setDisplayImportantNote] = useState(false);
  const [isStartDisabled, setStartDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isShowAlert, setShowAlert] = useState(false);
  const [selectedChangeStatus, setSelectedChangeStatus] = useState(
    props.item.Status
  );
  const [isEmailTempOpen, setEmailTempOpen] = useState(false);
  // const [rowVisibility, setRowVisibility] = useState(
  //   Array(props.data.length).fill(true)
  // );

  const selectedStatus = useRef(null);
  const [allocationActionBtn, setAllocationActionBtn] = useState(false);
  // Putting file name at front
  const keyValues = Object.entries(props.item);
  const filenameKeyValuePair = keyValues.find((el) => el[0] === "filename");

  if (filenameKeyValuePair) {
    const indexOfFilenameKeyValuePair = keyValues.indexOf(filenameKeyValuePair);

    [keyValues[0], keyValues[indexOfFilenameKeyValuePair]] = [
      keyValues[indexOfFilenameKeyValuePair],
      keyValues[0],
    ];
  }


  const renderInstructionsLink = () => {
    const instructions = props.item.instructions;
  
    if (Array.isArray(instructions) && instructions.length > 0) {
      const hasNone = instructions.every(instruction => instruction === 'NONE');
      if(hasNone){
        return <span className="text-gray-500">No Instructions</span>;
      } else {
      return (
        <div>
          {instructions.map((link, index) => (
            <a
              key={index}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200 mr-1"
            >
              Click here to view instructions {index + 1}
            </a>
          ))}
        </div>
      );
          }
    } else if (typeof instructions === 'string' && instructions !== 'NONE') {
      return (
        <a
          href={instructions}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
        >
          Click here to view instructions
        </a>
      );
    } else {
      return <span className="text-gray-500">No Instructions</span>;
    }
  };
  

    // if (props.item.instructions !== "NONE") {
    //   return (
    //     <a
    //       href={props.item.instructions}
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       className="inline-block px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
    //     >
    //       Click here to view instructions
    //     </a>
    //   );
    // } else {
    //   return <span className="text-gray-500">No Instructions</span>;
    // }
  // };

  const showProgress = () => {
    if (isProcessing) {
      return <DataSubmittingPop />;
    } else {
      return <></>;
    }
  };

  const onCheckChange = (row) => (e) => {
    const checkedRowsCopy = [...props.checkedRows];

    if (e.target.checked && !checkedRowsCopy.includes(row)) {
      props.onCheckedChange(true, props.index);
      checkedRowsCopy.push(row);
    } else {
      checkedRowsCopy.splice(checkedRowsCopy.indexOf(row), 1);
      props.onCheckedChange(false, props.index);
    }

    props.setCheckedRows([...checkedRowsCopy]);
  };


  // Google drive Api code...
    // const handleClickDownload = async () => {
    // try {
    //   // const fileId = props.item["Download"].Input;
    //   const fileId = props.item["_id"]
    //   const pubCode = props.item["Pubcode"];

    //   const driveResponse = await axios.get(`/files/getDownloaded/${fileId}`, {
    //     params: { type: pubCode },
    //     responseType: 'json', // Set the response type to 'blob'
    //   });

    //   const downloadLink = driveResponse.data.downloadLink;
    //   const fileName = driveResponse.data.fileName;
  
    //   // Create an anchor tag
    //   const link = document.createElement('a');
      
    //   // Set the href attribute to the download link
    //   link.href = downloadLink;
      
    //   // Set the download attribute to the desired filename
    //   link.download = fileName;
  
    //   // Append the anchor tag to the document body
    //   document.body.appendChild(link);
      
    //   // Trigger a click on the anchor tag to initiate the download
    //   link.click();

    //     // Remove the anchor tag from the document body
    //   document.body.removeChild(link);
    // } catch (error) {
    //   console.error('Error occurred while initiating Drive download:', error);
    // }
    // };


  // Download code from FTP
  // const handleClickDownload = async () => {
  //   try {
  //     const fileId = props.item["_id"];
  //     const response = await axios.get(`/files/downloadFilesRepub/${fileId}`, {
  //       responseType: 'blob', // Set the response type to 'blob'
  //     });

  //     // Create a Blob from the response data
  //     const blob = new Blob([response.data], { type: 'application/octet-stream' });

  //     // Create a link to trigger the download
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = props.item["filename"]
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);

  //     // Optionally, you can refresh the component or perform other actions after download
  //     props.refreshList();
  //   } catch (error) {
  //     console.error('Error occurred while initiating FTP download:', error);
  //   }
  // };
 

  // Download Dropbox Code.....
  const handleClickDownload = async () => {
    try {
      const id = props.item["_id"];
      const pubCode = props.item["Pubcode"];
      const dropboxDownloadResponse = await axios.post(`/files/getDownloaded/${id}`,
        {type: pubCode}
      );
      const dropboxURL = dropboxDownloadResponse.data.downloadLink;
      const filename = dropboxDownloadResponse.data.filename;

      const link = document.createElement("a");
      link.href = dropboxURL;
      link.download = filename;
      link.click();
      props.refreshList();
    } catch (error) {
      console.log("Error occurred while initiating file download:", error);
    }
  }

  const handleChange = async (e, value) => {
    const newStatus = e.target.value;
    setSelectedChangeStatus(newStatus);
    setProcessing(true);
    try {
      const selectedIds = [
        props.item["_id"],
        ...props.checkedRows.map((row) => row._id),
      ];

      await axios.put("/files/fileStatusChange", {
        ids: selectedIds,
        filename: props.item["filename"],
        status: newStatus,
      });

      // props.item[props.cols[value]] = newStatus;
      props.item.Status = newStatus;
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      setError(true);
    }
  };

  const renderOptions = () => {
    const tempArray = [...Status.statusArray];
    tempArray.splice(0, 1);
    return tempArray.map((opt, i) => (
      <option key={i + "-inner"} value={opt}>
        {opt.toUpperCase()}
      </option>
    ));
  };

  useEffect(() => {
    // Logic to determine if the "Start" button should be disabled
    const checkStartDisabled = () => {
      if (props.page === "Production") {
        setStartDisabled(false);
      }
      if (props.page === "QA") {
        const hasProductionAssign = props.item.Allocation.some(
          (allocation) =>
            (allocation.stage === "Production Assign" &&
              allocation.complete === false &&
              allocation.member === getUser()?.email) ||
            (allocation.stage === "Production Assign" &&
              allocation.complete === false &&
              allocation.member !== getUser()?.email)
        );

        setStartDisabled(hasProductionAssign);
      } else if (props.page === "Final") {
        const hasQaAssigned = props.item.Allocation.some(
          (allocation) =>
            (allocation.stage === "QA Assign" &&
              allocation.complete === false &&
              allocation.member === getUser()?.email) ||
            (allocation.stage === "QA Assign" &&
              allocation.complete === false &&
              allocation.member !== getUser()?.email)
        );
        setStartDisabled(hasQaAssigned);
      }
    };

    checkStartDisabled();
  }, [props.page, props.item.Allocation]);

  useEffect(() => {
    // Calculate allocationActionBtn and set it in the state
    setAllocationActionBtn(calculateAllocationActionBtn());
  }, [props.page, props.item.Allocation]);

  // Function to handle click for Start or Complete
  const handleAllocationActionClick = () => {
    if (allocationActionBtn) {
      // Handle Start logic
      handleClickStart();
    } else {
      // Handle Complete logic
      handleClickComplete();
    }
  };

  const calculateAllocationActionBtn = () => {
    if (props.page === "Production") {
      return props.item.Allocation.some(
        (allocation) =>
          allocation.stage === "Production Assign" &&
          allocation.start === false &&
          allocation.member === getUser()?.email
      );
    } else if (props.page === "QA") {
      return props.item.Allocation.some(
        (allocation) =>
          allocation.stage === "QA Assign" &&
          allocation.start === false &&
          allocation.member === getUser()?.email
      );
    } else if (props.page === "Final") {
      return props.item.Allocation.some(
        (allocation) =>
          allocation.stage === "Final Assign" &&
          allocation.start === false &&
          allocation.member === getUser()?.email
      );
    }
  };

  const pubcodeList = [
    "BLTH",
    "BIST",
    "BIF",
    "BILZ",
    "BISAN",
    "BIHAN",
    "BISA",
    "BID",
    "BIDD",
    "BILWF",
    "BILB",
    "BIHB",
    "BIN",
    "BIMU",
    "BIRW",
    "BILH",
    "BIRO",
    "BZDDE"
  ];

  const handleClickStart = async () => {
    setProcessing(true);
    try {

      if (isPubcodeInList()) {
        // Display a Notification
        setErrorMessage("Press Shift + C while creating this article" + "\n" + "URL will not captured in advertisement")
        setDisplayImportantNote(true);
      }
      await axios.post("/user/userStartAction", {
        id: props.item["_id"],
        type: props.item["Pubcode"]
        // id: membersToStart
      });

      const updatedData = props.data.map((item) => {
        if (item._id === props.item["_id"]) {
          item.Allocation = item.Allocation.map((all) => {
            if (
              all.stage === `${props.page} Assign` &&
              all.member === getUser()?.email
            ) {
              return { ...all, start: true };
            }
            return all;
          });
        }
        return item;
      });

      // props.setData(updatedData);
      setAllocationActionBtn(false);
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      setError(true);
    }
  };

  const isPubcodeInList = () => {
    return pubcodeList.includes(props.item["Pubcode"]);
  };

  const handleClickComplete = async () => {
    setProcessing(true);
    try {
      await axios.post("/user/userCompleteAction", {
        id: [props.item["_id"]],
        fileType: props.item["Pubcode"],
        stage: allocationStage,
        type: "WEB",
      });

      // Set the 'complete' field to true for the current user in the Allocation array
      const updatedData = props.data.map((item) => {
        if (item._id === props.item["_id"]) {
          item.Allocation = item.Allocation.map((allocation) => {
            if (
              allocation.stage === `${props.page} Assign` &&
              (allocation.member === getUser()?.email ||
                allocation.member !== getUser()?.email)
            ) {
              return { ...allocation, complete: true };
            }
            return allocation;
          });
        }
        return item;
      });

      const updatedDataWithoutCompleted = updatedData.filter((item) =>
        item.Allocation.some(
          (allocation) =>
            allocation.complete !== true &&
            allocation.stage === `${props.page} Assign`
        )
      );
      props.setData(updatedDataWithoutCompleted);
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      setError(true);
    }
  };

  const handleSendEmailClick = () => {
    setEmailTempOpen(true);
  }

  const formatAllocatedTime = (timeString) => {
    const date = new Date(timeString);

    const formattedDate = date.toLocaleString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedDate; // Adjust the format based on your preference
  };

  const allocationStage = (() => {
    if (props.page === "Production") {
      const allocation = props.item.Allocation.find(
        (allocation) => allocation.stage === "Production Assign"
      );
      return allocation ? allocation.stage : null;
    } else if (props.page === "QA") {
      const allocation = props.item.Allocation.find(
        (allocation) => allocation.stage === "QA Assign"
      );
      return allocation ? allocation.stage : null;
    } else if (props.page === "Final") {
      const allocation = props.item.Allocation.find(
        (allocation) => allocation.stage === "Final Assign"
      );
      return allocation ? allocation.stage : null;
    }
  })();

  const allocatedTime =
  props.item.Allocation.length > 0
    ? (() => {
        switch (props.page) {
          case "Production":
            return props.item.Allocation.find(
              (allocation) => allocation.stage === "Production Assign"
            )?.allocatedTime;
          case "QA":
            return props.item.Allocation.find(
              (allocation) => allocation.stage === "QA Assign"
            )?.allocatedTime;
          case "Final":
            return props.item.Allocation.find(
              (allocation) => allocation.stage === "Final Assign"
            )?.allocatedTime;
          default:
            return null;
        }
      })()
    : null;

  return (
    <>
      {/* <tr className="py-2 px-2 w-full whitespace-nowrap"> */}
      <tr
                      className={
                        Boolean(props.item.Priority.toLowerCase() === "true")
                          ? "py-2 px-2 w-full whitespace-nowrap bg-orange-50 w-0"
                          : "py-2 px-2 w-full whitespace-nowrap"
                      }
                      // key={index}
                    >
        <td
          className="px-2 my-3 items-center
            whitespace-nowrap
            text-left"
        >
          <input
            onChange={onCheckChange(props.item)}
            type="checkbox"
            className="hidden"
            name="checker"
            id={`checkbox-${props.index}`}
            checked={props.defaultChecked}
            // disabled={
            //   getUser()?.role === "Production" &&
            //   props.item.Allocation.some(
            //     (allocation) => 
            //       allocation.member === getUser()?.email && allocation.isDownloading > 2
            //   )
            // }
          />
          <label
            htmlFor={`checkbox-${props.index}`}
            className="block h-6 w-6 border-2 border-blue-500 rounded-sm relative"
          //   className={`block h-6 w-6 border-2 border-blue-500 rounded-sm relative ${
          //     getUser()?.role === "Production" && 
          //   props.item.Allocation.some(
          //     (allocation) => 
          //     allocation.member === getUser()?.email && allocation.isDownloading > 2
          //   ) ? "opacity-50 cursor-not-allowed" : ""
          // }`}
            style={{ borderRadius: "0.25rem" }}
          >
            {props.checkedRows.length > 0 ? (
                <svg
                className={`h-7 w-8 text-blue-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                  props.defaultChecked ? "block" : "hidden"
                }`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M14.707 6.293a1 1 0 0 1 0 1.414L8.414 14l-4.707-4.707a1 1 0 0 1 1.414-1.414L8 11.586l5.293-5.293a1 1 0 0 1 1.414 0z"
                  // d="M6.293 10.293a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1-1.414 1.414L10 8.414l-2.707 2.707a1 1 0 0 1-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}
          
          </label>
        </td>
        {props.visibleColumnIndices.map((value, i) => {
          if (props.cols[value] === "_id") {
            return null;
          }
          if (props.cols[value] === "Download") {
            return (
              <td
                className="px-5 my-3 flex items-center whitespace-nowrap text-left"
                key={i}
              >
                <select
                  ref={selectedStatus}
                  id={props.item["_id"].replaceAll(" ", "")}
                  className="border-2 border-blue-500 rounded-md outline-none uppercase text-sm"
                >
                  {Object.keys(keyValues[value][1]).map((el, i) => {
                    if (keyValues[value][1][el] !== "None") {
                      return (
                        <option
                          value={keyValues[value][1][el]}
                          key={i + "-inner"}
                        >
                          {el}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          value={keyValues[value][1][el]}
                          disabled={true}
                          key={i + "-inner"}
                        >
                          {el}
                        </option>
                      );
                    }
                  })}
                </select> 
                <div onClick={handleClickDownload} className="cursor-pointer">
                  <CloudDownloadIcon className="ml-3 text-blue-500 hover:text-blue-700 w-10 h-10" />
                </div>
              </td>
            );
          }
          if (props.cols[value] === "instructions") {
            return (
              <td className="py-2">
                <div>{renderInstructionsLink()}</div>
              </td>
            );
          }

          if (props.cols[value] === "Status" && !props.readOnlyStatus) {
            //  console.log(props.item);
            return (
              <td className="px-5" key={i}>
                {props.item[props.cols[value]] === Status.NOT_DOWNLOADED ? (
                  <>
                    <Button
                      onClick={async () => {
                        let inpValue;
                        if (window !== undefined)
                          inpValue = window.prompt(
                            "Please enter the Input Link below",
                            ""
                          );
                        if (inpValue === null || inpValue === undefined || inpValue === "") {
                          return;
                        }
                        try {
                          await axios.post("/files/setInputLink", {
                            id: props.item["_id"],
                            type: props.item["Pubcode"],
                            path: inpValue,
                          });
                          if (window !== undefined) window.location.reload();
                        } catch (err) {
                          alert("An error occurred while setting the input link. Please try again later.");
                        }
                      }}
                      className={`text-xs bg-blue-500 mr-3`}
                    >
                      Add Input
                    </Button>
                  </>
                ) : (
                  <>
                    {props.page === "All" ? (
                    <span className="px-2 border-2 border-blue-500 rounded-md outline-none uppercase text-sm">
                      {props.item[props.cols[value]]}
                    </span>
                  ) : (
                    <span className="px-2 border-2 border-blue-500 rounded-md outline-none uppercase text-sm">
                      {props.page}
                    </span>
                  )}
                  </>
                )}
              </td>
            );
          }

          return (
            <td
              className={`px-5 ${props.needPaddedRows ? "py-4" : ""}`}
              key={i}
            >
              {props.item[props.cols[value]]}
            </td>
          );
        })}
        {props.title === "Files" && props.requiredManager === 4 && ((props.page === "All" && props.item.Status === Status.QACOMP) || props.page === Status.QACOMP) && <Button
                // onClick={handleAllocationActionClick}
                disabled={!props.checkedRows.some(row => row.filename === props.item.filename)}
                className="text-xs bg-green-500"
                onClick={handleSendEmailClick}
              >
                Send Email
              </Button>
              }
        {props.actionCenter === 1 ? (
          <>
            <td>
              {/* {allocationActionBtn ? (
                <Button
                  onClick={handleClickStart}
                  disabled={isStartDisabled}
                  className={`text-xs bg-blue-500 mr-3`}
                >
                  Start
                </Button>
              ) : (
                <Button
                  // disabled={props.item.type === "MANUAL"}
                  onClick={handleClickComplete}
                  // onClick={() => handleClickComplete(props.index)}
                  className={`text-xs bg-green-400`}
                >
                  complete
                </Button>
              )} */}
              <Button
                onClick={handleAllocationActionClick}
                disabled={allocationActionBtn ? isStartDisabled : false}
                className={`text-xs ${
                  allocationActionBtn ? "bg-blue-500" : "bg-green-500"
                }`}
              >
                {allocationActionBtn ? "Start" : "Complete"}
              </Button>
            </td>
            <td>
              {allocatedTime ? formatAllocatedTime(allocatedTime) : "N/A"}
            </td>
          </>
        ) : props.actionCenter === 2 ? (
          <td>
            <Button onClick={() => {}} className={`text-xs bg-blue-500 mr-3`}>
              Show More
            </Button>
          </td>
        ) : (
          <></>
        )}
       </tr>
         {displayImportantNote ? (<ErrorPoup
          isSpecialNote={true}
          isShowAction={true}
          action={() => {
            setDisplayImportantNote(false);
          }}
          buttonText="Ok"
          message={errorMessage}
        />) : (<></>)}
        {isEmailTempOpen && 
        <EmailPopup 
        data={props.data}
        setData={props.setData}
        row={props.checkedRows}
        refreshList={props.refreshList}
        setCheckedRows={(arr) => {
          props.setCheckedRows(arr);
        }}
        onCancel={() => {
          console.log("hello");
          setEmailTempOpen(false);
        }}
        />}
    </>
  );
});

export default RepubRow;
