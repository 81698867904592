import React from "react";
import Cancel from "../../assets/cancel.svg";
import Button from "../Button/button";

const ErrorPoup = (props) => {
  const getCurrentTimeOfDay = () => {
    const currentTime = new Date().getHours();

    if (currentTime >= 4 && currentTime < 12) {
      return "Good Morning";
    } else if (currentTime >= 12 && currentTime < 17) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-20 z-50"></div>
      <div className="fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-50 ">
        {/* <Cancel onClick={()=>props.close()} className="absolute -top-4 w-10 h-10 -right-4 z-40" /> */}
        <div className="w-full space-y-4 px-8 py-10 h-full overflow-x-auto  bg-white rounded-lg">
          {props.isPunchIn ? (
            <>
              <h2 className="text-teal-400 text-3xl font-light text-center">
                {getCurrentTimeOfDay()}
              </h2>
              <p className="text-center">
                {props.message || "Something went wrong"}
              </p>
              <div className="flex justify-center">
                <Button
                  children={props.buttonText || "Retry"}
                  onClick={props.action}
                  className="bg-blue-600 w-full"
                />
              </div>
            </>
          ) : (
            <>
            {props.isSpecialNote ? (
              <>
              <h1 className="text-red-400 text-3xl font-light text-center">
                Important Note!
              </h1>
              <p className="text-center font-semibold">
              {props.message}
            </p>
            </>
            ) : (
              <>
              <h2 className="text-red-400 text-3xl font-light text-center">
                Something went wrong!
              </h2>
                 <p className="text-center">
                 {props.message || "Something went wrong"}
               </p>
             </>
            )}
         </>
        )}
          {props.isShowAction ? (
            <Button
              children={props.buttonText || "Retry"}
              onClick={props.action}
              className="bg-blue-600 w-full"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ErrorPoup;
